import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Select,
  Label,
} from "../../ModalEditStyle";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import Auth from "../../../../services/auth";

export default function ModalRegister({ showModal, setShowModal, data }) {
  const modalRef = useRef();
  const [idCoord] = useState(Auth.id());
  const [unidades, setUnidades] = useState([]);
  const [unidade, setUnidade] = useState("");
  const [cursos, setCursos] = useState([]);
  const [curso, setCurso] = useState("");
  const [idCurso, setIdCurso] = useState(0);
  const [idUnidade, setIdUnidade] = useState(0);
  const [idCentro_custo, setIdCentro_custo] = useState(0);
  const [idModulo, setIdModulo] = useState(0);
  const [centros_custo, setCentros_custo] = useState([]);
  const [centro_custo, setCentro_custo] = useState("");
  const [modulos, setModulos] = useState([]);
  const [modulo, setModulo] = useState("");
  const [id_turma, setId_turma] = useState(0);
  const [data_inicio, setData_inicio] = useState("");
  const [data_fim, setData_fim] = useState("");
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    api
      .get(`/coord/courses`)
      .then((response) => {
        setCursos(response.data.cursos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/costcenter`)
      .then((response) => {
        setCentros_custo(response.data.centros_custo);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/lancamento?idCoord=${idCoord}`)
      .then((response) => {
        setModulos(response.data.lancamento_grade);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/units?idCoord=${idCoord}`)
      .then((response) => {
        setUnidades(response.data.unidades);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [idCoord]);

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    if (data) {
      setUnidade(data.unidade);
      setCurso(data.curso);
      setCentro_custo(data.centro_custo);
      setModulo(data.modulo);
      setId_turma(data.id_turma);
      if (data.data_inicio) {
        setData_inicio(
          data.data_inicio.substring(6, 10) +
            "-" +
            data.data_inicio.substring(3, 5) +
            "-" +
            data.data_inicio.substring(0, 2)
        );
      }
      if (data.data_fim) {
        setData_fim(
          data.data_fim.substring(6, 10) +
            "-" +
            data.data_fim.substring(3, 5) +
            "-" +
            data.data_fim.substring(0, 2)
        );
      }
    }
  }, [data]);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      id: idCoord,
      id_unidade: idUnidade,
      id_centro_custo: idCentro_custo,
      id_curso: idCurso,
      id_modulo: idModulo,
      id_turma: id_turma,
      data_inicio: data_inicio,
      data_fim: data_fim,
    };
    try {
      const response = await api.patch(`/coord/lancamento`, {
        data,
      });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Toast.fire({
        icon: "success",
        title: "Editado com sucesso!",
      });
      setShowModal(!showModal);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      alert(
        error
      ); /*Swal('Atenção!', 'Serviço indisponível no momento', 'error')*/
    }
  }
  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Atualizar Grades</h3>
                <FormContent>
                  <Label>Unidade:</Label>
                  <Select
                    type="name"
                    value={unidade}
                    placeholder="unidade"
                    onChange={(e) => {
                      setUnidade(e.target.value);
                      setIdUnidade(e.target[e.target.options.selectedIndex].id);
                    }}
                    required
                  >
                    <option selected disabled value="">
                      Selecione a unidade
                    </option>
                    {unidades.map((u, i) => (
                      <option id={u.id}>{u.unidade}</option>
                    ))}
                  </Select>
                  <Label>Curso:</Label>
                  <Select
                    value={curso}
                    placeholder="Curso"
                    onChange={(e) => {
                      setCurso(e.target.value);
                      setIdCurso(e.target[e.target.options.selectedIndex].id);
                    }}
                    required
                  >
                    <option selected disabled value="">
                      Selecione o curso
                    </option>
                    {cursos.map((c, i) => (
                      <option id={c.id}>{c.nome}</option>
                    ))}
                  </Select>
                  <Label>Centro de Custo:</Label>
                  <Select
                    type="centro_custo"
                    value={centro_custo}
                    placeholder="centro_custo"
                    onChange={(e) => {
                      setCentro_custo(e.target.value);
                      setIdCentro_custo(
                        e.target[e.target.options.selectedIndex].id
                      );
                    }}
                    required
                  >
                    <option selected disabled value="">
                      {centros_custo.filter((c) => c.ativo === 1).length > 0
                        ? "Selecione o centro de custo"
                        : "Nenhum centro de custo encontado"}
                    </option>
                    {centros_custo.map((c, i) => {
                      return (
                        c.ativo === 1 && (
                          <option id={c.id} key={i}>
                            {c.nome}
                          </option>
                        )
                      );
                    })}
                  </Select>
                  <Label>Módulo:</Label>
                  <Select
                    type="Módulo"
                    value={modulo}
                    placeholder="Módulo"
                    onChange={(e) => {
                      setModulo(e.target.value);
                      setIdModulo(e.target[e.target.options.selectedIndex].id);
                    }}
                    required
                  >
                    <option selected disabled value="">
                      Selecione o módulo
                    </option>
                    {modulos.map((m, i) => (
                      <option id={m.id}>{m.modulo}</option>
                    ))}
                  </Select>
                  <Label>Data Início:</Label>
                  <Input
                    type="date"
                    value={data_inicio}
                    placeholder="Data Início"
                    onChange={(e) => {
                      setData_inicio(e.target.value);
                    }}
                    required
                  />
                  <Label>Data Fim:</Label>
                  <Input
                    type="date"
                    value={data_fim}
                    placeholder="Data Fim"
                    onChange={(e) => {
                      setData_fim(e.target.value);
                    }}
                    required
                  />
                </FormContent>
                <br />
                <Button type="submit">Atualizar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                  // clear();
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
