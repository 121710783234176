import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Select,
  Label,
} from "../../ModalRegisterStyle";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import { isAuthenticatedDev } from "../../../../auth";

export default function ModalRegisterCoord({ showModal, setShowModal }) {
  const modalRef = useRef();
  const [id, setId] = useState("");
  const [matricula, setMatricula] = useState("");
  const [data_inicio, setData_inicio] = useState("");
  const [usuarios, setUsuarios] = useState([]);

  useEffect(() => {
    if (!showModal) {
      return;
    }
    api
      .get(`/rh/users?&dev=${isAuthenticatedDev()}`)
      .then((response) => {
        setUsuarios(response.data.usuarios);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [showModal]);

  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  /**
   * Vai servir para gerar o pdf do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      id,
      matricula,
      data_inicio,
      coord: true,
    };
    // if (new Date(data_inicio) < new Date()) {
    //   return Swal.fire(
    //     "Atenção!",
    //     `O início da coordenação deve ser depois de ${
    //       new Date().toLocaleDateString("pt-br").split("T")[0]
    //     }`,
    //     "warning"
    //   );
    // }
    try {
      const response = await api.post(`rh/register-coord`, { data });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Swal.fire("Sucesso!", response.data.success, "success");
      Toast.fire({
        icon: "success",
        title: "Cadastrado com sucesso!",
      });
      setShowModal(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      alert(error);
    }
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
              // clear();
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Adicionar coordenador(a)</h3>
                <FormContent>
                  <Label>Novo Coordenador:</Label>
                  <Select
                    value={id}
                    placeholder="Perfil"
                    onChange={(e) => {
                      setId(e.target.value);
                    }}
                    required
                  >
                    {usuarios?.map(
                      (u) =>
                        u.perfil === "COORD" && (
                          <option key={u.id} value={u.id}>
                            {u.nome}
                          </option>
                        )
                    )}
                  </Select>
                  <Label>matricula:</Label>
                  <Input
                    type="text"
                    value={matricula}
                    placeholder="matricula"
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                    }
                    onChange={(e) => setMatricula(e.target.value)}
                    inputMode={"numeric"}
                    required
                  />
                  <Label>Início da Coordenação:</Label>
                  <Input
                    type="date"
                    value={
                      data_inicio || new Date().toISOString().split("T")[0]
                    }
                    placeholder="Data Inicio"
                    onChange={(e) => setData_inicio(e.target.value)}
                    required
                  />
                </FormContent>
                <br />
                <Button type="submit">Adicionar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                  // clear();
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
