import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
} from "../../ModalEditStyle";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import Pagination from "../../../../components/Pagination";
import { IoPencilOutline, IoTrashOutline } from "react-icons/io5";
import ModalTrocaHorarios from "../../../../components/Modal/Coord/TrocaHorarios/Horarios.js";

export default function ModalRegister({ showModal, setShowModal, data }) {
  const modalRef = useRef();
  const [data_inicio, setData_inicio] = useState("");
  const [data_fim, setData_fim] = useState("");
  const [id, setId] = useState("");
  const [idUnidade, setIdUnidade] = useState(0);
  const [id_centro_custo, setId_centro_custo] = useState(0);
  const [idCurso, setIdCurso] = useState(0);
  const [idTurma, setIdTurma] = useState(0);

  const [rowTable, setRowTable] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [horarios, setHorarios] = useState([]);
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    setTotalPages(Math.ceil(rowTable.length / 6));
  }, [rowTable]);

  useEffect(() => {
    if (!showModal) return;
    api
      .get(`/coord/lancamentofeitos?idTurma=${idTurma}`)
      .then((response) => {
        setRowTable(response.data.lancamento_grade);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [idTurma,showModal]);

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    if (!showModal) return;
    if (data) {
      setIdUnidade(data.id_unidade);
      setIdCurso(data.id_curso);
      setId_centro_custo(data.id_centro_custo);
      setIdTurma(data.id_turma);
      setId(data.id);
      if (data.data_inicio) {
        setData_inicio(
          data.data_inicio.substring(6, 10) +
            "-" +
            data.data_inicio.substring(3, 5) +
            "-" +
            data.data_inicio.substring(0, 2)
        );
      }
      if (data.data_fim) {
        setData_fim(
          data.data_fim.substring(6, 10) +
            "-" +
            data.data_fim.substring(3, 5) +
            "-" +
            data.data_fim.substring(0, 2)
        );
      }
    }
  }, [data, showModal]);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      id: id,
      id_unidade: idUnidade,
      id_centro_custo: id_centro_custo,
      id_curso: idCurso,
      id_turma: idTurma,
      data_inicio: data_inicio,
      data_fim: data_fim,
    };
    try {
      const response = await api.patch(`/coord/lancamento`, {
        data,
      });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Toast.fire({
        icon: "success",
        title: "Editado com sucesso!",
      });
      setShowModal(!showModal);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      alert(
        error
      ); /*Swal('Atenção!', 'Serviço indisponível no momento', 'error')*/
    }
  }
  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Horários</h3>
                <FormContent>
                  {rowTable.length > 0 ? (
                    <>
                      <table style={{ marginTop: 20 }}>
                        <thead>
                          <tr>
                            <th>Disciplina</th>
                            <th>Professor</th>
                            <th>Dia Semana</th>
                            <th>Horário Início</th>
                            <th>Horário Fim</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {rowTable
                            .slice((page - 1) * 6, page * 6)
                            .map((r, i) => (
                              <tr key={i}>
                                <td>{r.disciplina}</td>
                                <td>{r.colaborador}</td>
                                <td>{r.dia_semana}</td>
                                <td>{r.horario_inicio}</td>
                                <td>{r.horario_fim}</td>
                                <td>
                                  <IoPencilOutline
                                    title="Editar"
                                    style={{
                                      fontSize: "25px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setShowModal(true);
                                      setHorarios(r);
                                    }}
                                  />
                                </td>
                                <td>
                                  <IoTrashOutline
                                    title="Excluir"
                                    style={{
                                      fontSize: "25px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setRowTable(
                                        rowTable.filter(
                                          (row, index) => i !== index
                                        )
                                      );
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <ModalTrocaHorarios
                        showModalEdit={showModalEdit}
                        setShowModalEdit={setShowModalEdit}
                        data={horarios}
                      />
                      <Pagination
                        page={page}
                        setPage={setPage}
                        totalPages={totalPages}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </FormContent>
                <br />
                <Button type="submit">Atualizar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                  // clear();
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
