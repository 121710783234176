import styled from "styled-components";

export const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "hidden",
})`
  position: fixed;
  width: 56px;
  right: 50px;
  bottom: 50px;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
  flex-direction: column;
  gap: 20px;
`;

export const Button = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "show" && prop !== "open",
})`
  color: white;
  width: 56px;
  height: 56px;
  background-color: var(--red-color);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 30px;
  display: ${(props) => (props.show ? "flex" : "none")};
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  transition: opacity 1s, visibility 1s;
  opacity: ${(props) => (props.open ? "1" : "0")};

  :hover {
    transform: scale(115%);
  }
`;

export const MenuButton = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "open",
})`
  cursor: pointer;
  display: grid;
  gap: 5px;
  align-items: center;
  border-radius: 50%;
  padding: 15px;
  width: 56px;
  height: 56px;
  justify-content: center;
  transition: background-color 0.5s;
  background-color: ${(props) => (props.open ? "#f8f8ff" : "var(--red-color)")};
  grid-template-areas:
    ". . ."
    ". . ."
    ". . .";

  :hover {
    transform: scale(115%);
  }

  /** Animação do ícone
  */
  div {
    width: 6px;
    height: 6px;
    border-radius: 1px;
    background-color: ${(props) => (props.open ? "var(--red-color)" : "white")};

    &:nth-child(2) {
      transform-origin: 50% 14px;
      transition: transform 0.5s;
      transform: ${(props) => (props.open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(4) {
      transform-origin: 14px 50%;
      transition: transform 0.5s;
      transform: ${(props) => (props.open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(5) {
      transform-origin: center;
      transition: transform 0.5s;
      transform: ${(props) => (props.open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(6) {
      transform-origin: -8px 50%;
      transition: transform 0.5s;
      transform: ${(props) => (props.open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(8) {
      transform-origin: 50% -8px;
      transition: transform 0.5s;
      transform: ${(props) => (props.open ? "rotate(45deg)" : "rotate(0)")};
    }

    &:nth-child(1) {
      transform-origin: center;
      transition: ${(props) =>
        props.open ? "transform 0.5s" : "transform 0.5s ease 0.5s"};
      transform: ${(props) => (props.open ? "rotate(45deg)" : "rotate(0)")};

      div {
        transform-origin: top left;
        transition: ${(props) =>
          props.open ? "transform 0.5s ease 0.5s" : "transform 0.5s"};
        transform: ${(props) => (props.open ? "scaleX(3.2)" : "scaleX(0)")};
      }
    }

    &:nth-child(3) {
      transform-origin: center;
      transition: ${(props) =>
        props.open ? "transform 0.5s" : "transform 0.5s ease 0.5s"};
      transform: ${(props) => (props.open ? "rotate(-45deg)" : "rotate(0)")};

      div {
        transform-origin: top right;
        transition: ${(props) =>
          props.open ? "transform 0.5s ease 0.5s" : "transform 0.5s"};
        transform: ${(props) => (props.open ? "scaleX(3.2)" : "scaleX(0)")};
      }
    }

    &:nth-child(7) {
      transform-origin: center;
      transition: ${(props) =>
        props.open ? "transform 0.5s" : "transform 0.5s ease 0.5s"};
      transform: ${(props) => (props.open ? "rotate(-45deg)" : "rotate(0)")};

      div {
        transform-origin: top left;
        transition: ${(props) =>
          props.open ? "transform 0.5s ease 0.5s" : "transform 0.5s"};
        transform: ${(props) => (props.open ? "scaleX(3.2)" : "scaleX(0)")};
      }
    }

    &:nth-child(9) {
      transform-origin: center;
      transition: ${(props) =>
        props.open ? "transform 0.5s" : "transform 0.5s ease 0.5s"};
      transform: ${(props) => (props.open ? "rotate(45deg)" : "rotate(0)")};

      div {
        transform-origin: top right;
        transition: ${(props) =>
          props.open ? "transform 0.5s ease 0.5s" : "transform 0.5s"};
        transform: ${(props) => (props.open ? "scaleX(3.2)" : "scaleX(0)")};
      }
    }
  }
`;
