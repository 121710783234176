/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useMemo } from "react";
import { Card, Contanier, Header, Table, Button, Botton, ContainerTable, CloseModalButton } from "./style.js";
import api from "../../../../../services/api.js";
import Swal from "sweetalert2";

export default function ResponseXlsx({ showresults, setShowResults, setShowModalExcel, response, setResponse }) {
  if (!showresults) return null;

  const [activeTab, setActiveTab] = useState(
    response?.resultado?.deslocamento ? "deslocamentos" : response?.resultado?.substituicao ? "substituicoes" : ""
  );
  const [disabled, setDisabled] = useState(false);

  // Acesso seguro aos dados, prevenindo erros com objetos undefined
  const deslocamentosSucsses = useMemo(() => response?.resultado?.deslocamento?.sucesso || [], [response]);
  const substituicaoSucsses = useMemo(() => response?.resultado?.substituicao?.sucesso || [], [response]);

  // Todos os erros de deslocamento em um único array para exibição
  const errosDeslocamentoArray = useMemo(() => {
    const errosObj = { ...response?.resultado?.deslocamento?.erro, ...response?.resultado?.deslocamento?.erro.nao_cadastrados } || {};

    // Coleta todos os erros específicos
    return Object.values(errosObj)
      .filter((item) => Array.isArray(item) || (typeof item === "object" && item !== null))
      .flatMap((item) => {
        if (Array.isArray(item)) return item;
        return Object.values(item).flat();
      })
      .filter(Boolean);
  }, [response]);

  // Todos os erros de substituição em um único array para exibição
  const errosSubstituicaoArray = useMemo(() => {
    const errosObj = response?.resultado?.substituicao?.erro || {};
    return Object.values(errosObj)
      .filter((item) => Array.isArray(item) || (typeof item === "object" && item !== null))
      .flatMap((item) => {
        if (Array.isArray(item)) return item;
        return Object.values(item).flat();
      })
      .filter(Boolean);
  }, [response]);

  // Todos os itens para a visualização atual (deslocamentos ou substituições)
  const currentItems = useMemo(() => {
    if (activeTab === "deslocamentos") {
      return [...(errosDeslocamentoArray || []), ...(deslocamentosSucsses || [])];
    } else {
      return [...(errosSubstituicaoArray || []), ...(substituicaoSucsses || [])];
    }
  }, [activeTab, deslocamentosSucsses, errosDeslocamentoArray, substituicaoSucsses, errosSubstituicaoArray]);

  const currentItemsTotal = useMemo(() => {
    return [
      ...(deslocamentosSucsses || []),
      ...(errosDeslocamentoArray || []),
      ...(substituicaoSucsses || []),
      ...(errosSubstituicaoArray || []),
    ];
  }, [deslocamentosSucsses, errosDeslocamentoArray, substituicaoSucsses, errosSubstituicaoArray]);

  // Verificação se um campo tem erro (para destacar nas linhas da tabela)
  const hasError = (item, fieldName, tipo, i) => {
    const erroObj = tipo === "deslocamentos" ? response?.resultado?.deslocamento?.erro : response?.resultado?.substituicao?.erro;
    if (!erroObj) return false;

    // Verifica nos erros de falta de informação
    const faltaInfo = erroObj?.falta_info?.[fieldName] || [];
    if (faltaInfo?.includes(item[fieldName])) return true;

    // Verifica nos erros de não encontrado
    const fieldError = erroObj?.[`${fieldName}_nao_encontrado`] || [];
    if (fieldError?.includes(item[fieldName])) return true;

    // Verificação especial para cidades - só destaca se o valor atual estiver na lista de erros
    if (
      (fieldName === "cidade_origem" || fieldName === "cidade_destino") &&
      erroObj?.cidades_nao_encontrado &&
      item[fieldName] && // Garante que tem valor
      erroObj?.cidades_nao_encontrado?.includes(item[fieldName]) &&
      item?.erro?.includes("deslocamento cidade origem e/ou destino não encontrado")
    ) {
      // E que está na lista de erros
      return true;
    }

    return false;
  };
  const handleClose = () => {
    setShowResults(false);
    setShowModalExcel(false);
    setResponse([]);
  };

  const styleErro = { color: "rgba(255,0,0,0.7)" };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  // Modificar essa função para criar/submeter os eventos de deslocamentos e substituição
  async function HandleClickSubmit() {
    try {
      setDisabled(true);
      if (currentItemsTotal.some((item) => item.erro)) {
        return await Swal.fire({
          toast: false,
          title: "Erro!",
          text: "Existem itens da planilha com erro!",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "var(--blue-color)",
        });
      }
      // Exibir alerta de loading
      Swal.fire({
        toast: false,
        title: "Aguarde!",
        text: "Os dados da planilha estão sendo enviados!",
        icon: "info",
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const dadosDesl = response?.dadosDesl || [];
      const dadosSubs = response?.dadosSubs || [];
      const dados = [...dadosSubs, ...dadosDesl];
      const success = [];
      const error = [];

      // Usar `for...of` para aguardar cada requisição antes de continuar
      for (const d of dados) {
        try {
          const response = await api.post(`/coord/events`, { data: d });
          success.push(response.data.success);
        } catch (e) {
          error.push(e.response?.data?.error || "Erro desconhecido");
          await Swal.fire({
            toast: false,
            title: "Atenção!",
            text: e.response?.data?.error || "Ocorreu um erro ao processar os dados.",
            icon: "warning",
          });
        }
      }
      // Fechar loading após todas as requisições
      Swal.close();
      if (success.length > 0) {
        await Toast.fire({
          icon: "success",
          title: "Lançamentos eventuais registrados com sucesso!",
        });
        window.location.reload();
      }
    } catch (error) {
      Swal.close();
      console.error(error);
      await Swal.fire({
        toast: false,
        title: "Atenção!",
        text: error.response?.data?.error || "Erro inesperado",
        icon: "warning",
      });
    } finally {
      setDisabled(false);
    }
  }
  return (
    <Contanier>
      <Card>
        <Header>
          <div>
            <h1>{activeTab === "deslocamentos" ? "Deslocamentos" : "Substituições"}</h1>
            {response?.resultado?.deslocamento ? (
              <Button onClick={() => setActiveTab("deslocamentos")} className={activeTab === "deslocamentos" ? "active" : ""}>
                Deslocamentos
              </Button>
            ) : null}
            {response?.resultado?.substituicao ? (
              <Button onClick={() => setActiveTab("substituicoes")} className={activeTab === "substituicoes" ? "active" : ""}>
                Substituições
              </Button>
            ) : null}
          </div>
          <CloseModalButton onClick={handleClose} />
          {/* <Button onClick={handleClose}>Fechar</Button> */}
        </Header>

        {/* Sumário de processamento */}
        <div className="summary-section" style={{ display: "flex", gap: 30, padding: "20px 0 0 0 " }}>
          <p>
            <strong>Total:</strong>{" "}
            {activeTab === "deslocamentos" ? response?.resultado?.deslocamento?.total || 0 : response?.resultado?.substituicao?.total || 0}
          </p>
          <p>
            <strong>Sucesso:</strong>{" "}
            {activeTab === "deslocamentos"
              ? response?.resultado?.deslocamento?.sucesso.length || 0
              : response?.resultado?.substituicao?.sucesso.length || 0}
          </p>
          <p>
            <strong>Falhas:</strong>{" "}
            {activeTab === "deslocamentos"
              ? response?.resultado?.deslocamento?.erro?.nao_cadastrados?.length || 0
              : response?.resultado?.substituicao?.erro?.nao_cadastrados?.length || 0}
          </p>
        </div>

        <hr style={{ width: "100%", margin: "10px" }} />
        <ContainerTable>
          {activeTab === "deslocamentos" && (
            <Table>
              <thead>
                <tr>
                  <th>Posição</th>
                  <th>Unidade</th>
                  <th>Curso</th>
                  <th className="centro_custo">Centro Custo</th>
                  <th>Professor</th>
                  <th className="cidade_origem">Cidade Origem</th>
                  <th className="cidade_oestino">Cidade Destino</th>
                  <th>Dias</th>
                  {currentItems.some((item) => item.erro) && <th>Detalhes erro</th>}
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => {
                  return typeof item === "object" ? (
                    <tr key={`desl-${index}`}>
                      <td className="posicao" title={item.posicao || "Faltou Informação"}>
                        {item.posicao || "Faltou Informação"}
                      </td>
                      <td
                        className="unidade"
                        style={hasError(item, "unidade", "deslocamentos", index) ? styleErro : {}}
                        title={item.unidade || "Faltou Informação"}
                      >
                        {item.unidade || "Faltou Informação"}
                      </td>
                      <td
                        className="curso"
                        style={hasError(item, "curso", "deslocamentos", index) ? styleErro : {}}
                        title={item.curso || "Faltou Informação"}
                      >
                        {item.curso || "Faltou Informação"}
                      </td>
                      <td
                        className="centro_custo"
                        style={hasError(item, "centro_custo", "deslocamentos", index) ? styleErro : {}}
                        title={item.centro_custo || "Faltou Informação"}
                      >
                        {item.centro_custo || "Faltou Informação"}
                      </td>
                      <td
                        className="professor"
                        style={hasError(item, "professor", "deslocamentos", index) ? styleErro : {}}
                        title={item.professor || "Faltou Informação"}
                      >
                        {item.professor || "Faltou Informação"}
                      </td>
                      <td
                        className="cidade_origem"
                        style={hasError(item, "cidade_origem", "deslocamentos", index) ? styleErro : {}}
                        title={item.cidade_origem || "Faltou Informação"}
                      >
                        {item.cidade_origem || "Faltou Informação"}
                      </td>
                      <td
                        className="cidade_destino"
                        style={hasError(item, "cidade_destino", "deslocamentos", index) ? styleErro : {}}
                        title={item.cidade_destino || "Faltou Informação"}
                      >
                        {item.cidade_destino || "Faltou Informação"}
                      </td>
                      <td
                        className="dias_deslocamento"
                        style={hasError(item, "dias_deslocamento", "deslocamentos", index) ? styleErro : {}}
                        title={item.dias_deslocamento || "Faltou Informação"}
                      >
                        {item.dias_deslocamento || "Faltou Informação"}
                      </td>
                      {currentItems.some((item) => item.erro) && (
                        <td className="erro" style={item.erro ? styleErro : {}} title={item.erro || "-"}>
                          {item.erro || "-"}
                        </td>
                      )}
                    </tr>
                  ) : null;
                })}
              </tbody>
            </Table>
          )}

          {activeTab === "substituicoes" && (
            <Table>
              <thead>
                <tr>
                  <th>Posição</th>
                  <th>Unidade</th>
                  <th>Curso</th>
                  <th className="centro_custo">Centro Custo</th>
                  <th>Professor</th>
                  <th className="carga_horaria">Carga Horária</th>
                  <th>Justificativa</th>
                  {currentItems.some((item) => item.erro) && <th>Detalhes erro</th>}
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => {
                  return typeof item === "object" ? (
                    <tr key={`subs-${index}`}>
                      <td className="posicao" title={item.posicao || "Faltou Informação"}>
                        {item.posicao || "Faltou Informação"}
                      </td>
                      <td
                        className="unidade"
                        style={hasError(item, "unidade", "substituicoes", index) ? styleErro : {}}
                        title={item.unidade || "Faltou Informação"}
                      >
                        {item.unidade || "Faltou Informação"}
                      </td>
                      <td
                        className="curso"
                        style={hasError(item, "curso", "substituicoes", index) ? styleErro : {}}
                        title={item.curso || "Faltou Informação"}
                      >
                        {item.curso || "Faltou Informação"}
                      </td>
                      <td
                        className="centro_custo"
                        style={hasError(item, "centro_custo", "substituicoes", index) ? styleErro : {}}
                        title={item.centro_custo || "Faltou Informação"}
                      >
                        {item.centro_custo || "Faltou Informação"}
                      </td>
                      <td
                        className="professor"
                        style={hasError(item, "professor", "substituicoes", index) ? styleErro : {}}
                        title={item.professor || "Faltou Informação"}
                      >
                        {item.professor || "Faltou Informação"}
                      </td>
                      <td
                        className="carga_horaria"
                        style={hasError(item, "carga_horaria", "substituicoes", index) ? styleErro : {}}
                        title={item.carga_horaria || "Faltou Informação"}
                      >
                        {item.carga_horaria || "Faltou Informação"}
                      </td>
                      <td
                        className="justificativa"
                        style={hasError(item, "justificativa", "substituicoes", index) ? styleErro : {}}
                        title={item.justificativa || "Faltou Informação"}
                      >
                        {item.justificativa || "Faltou Informação"}
                      </td>
                      {currentItems.some((item) => item.erro) && (
                        <td className="erro" style={item.erro ? styleErro : {}} title={item.erro || "-"}>
                          {item.erro || "-"}
                        </td>
                      )}
                    </tr>
                  ) : null;
                })}
              </tbody>
            </Table>
          )}
        </ContainerTable>
        <Botton>
          {" "}
          <Button
            itens={currentItemsTotal.some((item) => item.erro)}
            onClick={HandleClickSubmit}
            style={{ padding: "15px 25px" }}
            disabled={disabled}
          >
            Cadastrar
          </Button>
        </Botton>
      </Card>
    </Contanier>
  );
}
