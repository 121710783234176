import React, { useState, useEffect } from "react";
import { Background, Container, Titulo, Hr, Table, Button } from "./style";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import {
  IoPencilOutline,
  IoTrashOutline,
  IoLibraryOutline,
} from "react-icons/io5";
import Auth from "../../../services/auth";
import Pagination from "../../../components/Pagination";
import { useNavigate } from "react-router-dom";
import DisciplinasModal from "../../../components/Modal/Coord/GradesCurriculares";
import { isAuthenticatedDiretor } from "../../../auth";

export default function GradesCurriculares() {
  const navigate = useNavigate();
  const [idCoord] = useState(Auth.id());
  const [gradesCurriculares, setGradesCurriculares] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [titlePage] = useState(1);
  const [disciplinasGrade, setDisciplinasGrade] = useState([]);
  const [idGrade, setIdGrade] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const diretor = isAuthenticatedDiretor();

  useEffect(() => {
    api
      .get(`/coord/grades?idCoord=${idCoord}`)
      .then((response) => {
        setGradesCurriculares(response.data.grades);
        setIdGrade(response.data.grades[0] && response.data.grades[0].id);
      })
      .catch((error) => alert(error));
  }, [idCoord]);

  useEffect(() => {
    api
      .get(`/coord/itensgrade?idGrade=${idGrade}`)
      .then((response) => {
        setDisciplinasGrade(response.data.grade_itens);
      })
      .catch((error) => alert(error));
  }, [idGrade]);

  useEffect(() => {
    setTotalPages(Math.ceil(gradesCurriculares.length / 10));
  }, [gradesCurriculares]);

  useEffect(() => {
    setPage(1);
  }, [titlePage]);

  function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você não poderá reverter essa ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Deletar",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/coord/grades/${id}`);
        if (response.data.error) return alert(response.data.error);
        Toast.fire({
          icon: "success",
          title: "Deletado com sucesso!",
        });
        setGradesCurriculares(
          gradesCurriculares.filter((grade) => grade.id !== id)
        );
      }
    });
  }

  return (
    <Background>
      <Container>
        <Titulo>Controle de Matrizes Curriculares</Titulo>
        <Hr />
        <br />
        <Button
          hidden={diretor}
          onClick={() => {
            navigate("/registergradescurriculares");
          }}
        >
          Nova
        </Button>
        <Table>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Curso</th>
                <th>C.C.</th>
                <th>Descrição</th>
                <th></th>
                <th hidden={diretor}></th>
                <th hidden={diretor}></th>
              </tr>
            </thead>
            <tbody>
              {gradesCurriculares
                .slice((page - 1) * 10, page * 10)
                .map((g, i) => (
                  <tr key={i}>
                    <td data-title="Id">{g.id}</td>
                    <td data-title="Curso" className="td-left">
                      {g.curso}
                    </td>
                    <td data-title="Centro Custo" className="td-left">
                      {g.centro_custo}
                    </td>
                    <td data-title="Descrição" className="td-left">
                      {g.descricao}
                    </td>
                    <td data-title="Disciplinas">
                      <IoLibraryOutline
                        title="Disciplinas"
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={() => {
                          setIdGrade(g.id);
                          setShowModal(true);
                        }}
                      />
                    </td>
                    <td data-title="Editar" hidden={diretor}>
                      <IoPencilOutline
                        title="Editar"
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={() => {
                          navigate(`/editgradescurriculares?id=${g.id}`);
                        }}
                      />
                    </td>
                    <td data-title="Excluir" hidden={diretor}>
                      <IoTrashOutline
                        title="Excluir"
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={async () => {
                          handleDelete(g.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Table>
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
      <DisciplinasModal
        showModal={showModal}
        setShowModal={setShowModal}
        dados={disciplinasGrade}
      />
    </Background>
  );
}
