import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import {
  Background,
  Container,
  InputLabel,
  InputContainer,
  Titulo,
  Hr,
  Table,
  Button,
  ButtonDiv,
} from "./style";
import Auth from "../../../services/auth";
import Pagination from "../../../components/Pagination";
import TitlePagination from "../../../components/TitlePagination";
import { useLocation, useNavigate } from "react-router-dom";
import GoBack from "../../../components/GoBack";
import ModalDisciplinas from "../../../components/Modal/Coord/EditGradesCurriculares";
import { IoTrashOutline, IoAddCircleOutline } from "react-icons/io5";

export default function Fechamento() {
  const search = useLocation().search;
  const [id] = useState(new URLSearchParams(search).get("id"));
  const [idCoord] = useState(Auth.id());
  const [curso, setCurso] = useState("");
  const [cursos, setCursos] = useState([]);
  const [idCurso, setIdCurso] = useState(0);
  const [centroCusto, setCentroCusto] = useState("");
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [idCentroCusto, setIdCentroCusto] = useState(0);
  const [page, setPage] = useState(1);
  const [titlePage, setTitlePage] = useState(1);
  const [totalTitlePages, setTotalTitlePages] = useState(1);
  const [disciplinasGrade, setDisciplinasGrade] = useState([]);
  const [reload, setReload] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [descricao, setDescricao] = useState("");
  const [modulo, setModulo] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    setTitlePage(
      disciplinasGrade.findIndex((o) => o.modulo === modulo) + 1 > 0
        ? disciplinasGrade.findIndex((o) => o.modulo === modulo) + 1
        : 1
    );
  }, [disciplinasGrade, modulo]);

  useEffect(() => {
    api
      .get(`/coord/courses?idCoord=${idCoord}`)
      .then((response) => {
        setCursos(response.data.cursos);
      })
      .catch((error) => alert(error));
    api
      .get(`/coord/costcenter?idCoord=${idCoord}`)
      .then((response) => {
        setCentrosCusto(response.data.centros_custo);
      })
      .catch((error) => alert(error));
    api
      .get(`/coord/grades/${id}?idCoord=${idCoord}`)
      .then((response) => {
        setCurso(response.data.grades[0].curso);
        setIdCurso(response.data.grades[0].id_curso);
        setCentroCusto(response.data.grades[0].centro_custo);
        setIdCentroCusto(response.data.grades[0].id_centro_custo);
        setDescricao(response.data.grades[0].descricao);
      })
      .catch((error) => alert(error));
    api
      .get(`/coord/itensgrade?idGrade=${id}`)
      .then((response) => {
        setDisciplinasGrade(response.data.grade_itens);
      })
      .catch((error) => alert(error));
  }, [id, idCoord, reload]);

  useEffect(() => {
    setTotalTitlePages(Math.ceil(disciplinasGrade.length / 1));
  }, [disciplinasGrade]);

  async function handleSubmit() {
    if (!disciplinasGrade.length) {
      return Swal.fire({
        title: "Atenção",
        text: "Grade necessita de ao menos uma disciplina!",
        icon: "warning",
        confirmButtonColor: "var(--blue-color)",
      });
    }
    let dados = [];
    disciplinasGrade.forEach((d, i) => {
      d.dados.forEach((d2, i) => {
        dados.push({
          id_disciplina: d2.id_disciplina,
          modulo: d.modulo,
        });
      });
    });
    const data = {
      id: id,
      id_curso: idCurso,
      id_centro_custo: idCentroCusto,
      dados: dados,
      descricao: descricao,
    };
    try {
      const response = await api.patch(`/coord/grades`, {
        data,
      });
      if (response.data.error)
        return Swal.fire({
          title: "Atenção",
          text: response.data.error,
          icon: "warning",
          confirmButtonColor: "var(--blue-color)",
        });
      Toast.fire({
        icon: "success",
        title: "Matriz atualizada com sucesso!",
      });
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleDelete(i) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Essa ação irá retirar a disciplina da matriz!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--red-color)",
      confirmButtonText: "Deletar",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setDisciplinasGrade((disciplina) =>
          disciplinasGrade[titlePage - 1].dados.length === 1
            ? [
                ...disciplinasGrade.filter(
                  (d) => d.modulo !== disciplinasGrade[titlePage - 1].modulo
                ),
              ]
            : [
                ...disciplinasGrade.slice(0, titlePage - 1),
                {
                  modulo: disciplina[titlePage - 1].modulo,
                  dados: disciplina[titlePage - 1].dados.filter(
                    (r, index) => index !== i
                  ),
                },
                ...disciplinasGrade.slice(titlePage),
              ]
        );
        setTitlePage(
          disciplinasGrade[titlePage - 1].dados.length === 1
            ? titlePage > 1
              ? titlePage - 1
              : titlePage
            : titlePage
        );
      }
    });
  }

  return (
    <Background>
      <GoBack />
      <Container>
        <Titulo>Edição da Matriz Curricular</Titulo>
        <Hr />
        <InputContainer>
          <InputLabel style={{ gridArea: "p" }}>
            <label>Curso</label>
            <select
              value={curso}
              onChange={(e) => {
                setCurso(e.target.value);
                setIdCurso(e.target[e.target.options.selectedIndex].id);
              }}
              disabled
              required
            >
              <option disabled value="">
                Selecione o curso
              </option>
              {cursos.map((c, i) => (
                <option id={c.id} key={i}>
                  {c.curso}
                </option>
              ))}
            </select>
          </InputLabel>
          <InputLabel style={{ gridArea: "s" }}>
            <label>Centro de custo</label>
            <select
              value={centroCusto}
              onChange={(e) => {
                setCentroCusto(e.target.value);
                setIdCentroCusto(e.target[e.target.options.selectedIndex].id);
              }}
              disabled
              required
            >
              <option disabled value="">
                Selecione o centro de custo
              </option>
              {centrosCusto.map((c, i) => (
                <option id={c.id} key={i}>
                  {c.nome}
                </option>
              ))}
            </select>
          </InputLabel>
          <InputLabel style={{ gridArea: "t" }}>
            <label>Descrição</label>
            <input
              // disabled
              type="text"
              value={descricao}
              maxLength="19"
              onChange={(e) => {
                setDescricao(e.target.value);
              }}
              required
            />
          </InputLabel>
        </InputContainer>
        <Titulo style={{ marginTop: 40 }}>
          Disciplinas
          <IoAddCircleOutline
            title="Adicionar Módulo/Disciplina"
            style={{ fontSize: "30px", cursor: "pointer", marginLeft: "15px" }}
            onClick={() => {
              setShowModal(true);
            }}
          />
        </Titulo>
        <Hr />
        {disciplinasGrade
          .slice((titlePage - 1) * 1, titlePage * 1)
          .map((d, i1) => (
            <React.Fragment key={i1}>
              <TitlePagination
                page={titlePage}
                setPage={setTitlePage}
                totalPages={totalTitlePages}
                title={d.modulo}
              />
              <Table>
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Disciplina</th>
                      <th>Carga Horária</th>
                      <th>Excluir</th>
                    </tr>
                  </thead>
                  <tbody>
                    {d.dados.slice((page - 1) * 10, page * 10).map((h, i2) => (
                      <tr key={i2}>
                        <td>{h.id_disciplina}</td>
                        <td>{h.disciplina}</td>
                        <td>{h.carga_horaria}</td>
                        <td>
                          <IoTrashOutline
                            title="Excluir"
                            style={{
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleDelete(i2);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th colSpan="2">Total</th>
                      <td>
                        <b>
                          {d.dados
                            .map((item) => item.carga_horaria)
                            .reduce((prev, next) => prev + next)}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Table>
              <Pagination
                page={page}
                setPage={setPage}
                totalPages={d.dados ? Math.ceil(d.dados.length / 10) : 1}
              />
            </React.Fragment>
          ))}
        <ButtonDiv>
          <Button
            style={{ backgroundColor: "var(--blue-color)" }}
            onClick={() => {
              handleSubmit();
            }}
          >
            Salvar
          </Button>
          <Button
            style={{ backgroundColor: "var(--red-color)" }}
            onClick={() => {
              Swal.fire({
                title: "Você tem certeza?",
                text: "Essa ação irá limpar a tela!",
                icon: "warning",
                confirmButtonColor: "var(--red-color)",
                showCancelButton: true,
                confirmButtonText: "Deletar",
                cancelButtonColor: "gray",
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.isConfirmed) {
                  setReload(!reload);
                }
              });
            }}
          >
            Limpar
          </Button>
        </ButtonDiv>
      </Container>
      <ModalDisciplinas
        showModal={showModal}
        setShowModal={setShowModal}
        disciplinasGrade={disciplinasGrade}
        setDisciplinasGrade={setDisciplinasGrade}
        defaultModulo={
          disciplinasGrade[titlePage - 1]
            ? disciplinasGrade[titlePage - 1].modulo
            : ""
        }
        setTitlePage={setModulo}
      />
    </Background>
  );
}
