import styled from "styled-components";
import { MdClose } from "react-icons/md";

export const Background = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "show",
})`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.show ? `flex` : `none`)};
  justify-content: center;
  align-items: center;
  z-index: 23;
`;

export const Button = styled.button`
  margin-right: 5px;
  width: 180px;
  height: 45px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;

export const Select = styled.select`
  width: 340px;
  border-radius: 5px;
  height: 30px;
  text-align: center;
  border: 2px solid black;
  /* margin-bottom: 20px; */
`;

export const Label = styled.label`
  grid-area: l;
  /* display: block;
    white-space: nowrap;
    position: absolute;
    bottom: 50%;
    left: 1rem;
    color: black;
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    opacity: 0;
    transform: translate3d(0, 50%, 0) scale(1);
    transform-origin: 0 0;
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1), transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1), z-index 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1); */
`;

export const ModalContent = styled.div`
  max-width: 75%;
  height: 90%;
  margin: 0 auto;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  position: relative;
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;

export const Form = styled.form`
  width: 100%;
  text-align: center;

  h3 {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
`;

export const FormContent = styled.div`
  display: flex;
  width: auto;
  max-width: 750px;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 15px;
`;

export const Input = styled.input`
  border-radius: 5px;
  width: 340px;
  height: 30px;
  /* margin-bottom: 20px; */
  text-align: center;
  border: 2px solid black;
  caret-color: #0093e9;
  grid-area: i;
`;

export const InputLabel = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  width: 30rem;
`;

export const InputLabelCheckbox = styled.div`
  display: grid;
  align-items: center;
  grid-template-areas:
    "c l"
    "c i";

  input {
    width: 300px;
  }
`;

export const CheckBox = styled.div`
  border: 2px solid var(--blue-color);
  width: 19px;
  height: 19px;
  border-radius: 25%;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: c;
`;
