import { IconBack, IconDownload } from "./style";
import { useNavigate } from "react-router-dom";

export default function GoBackPdf() {
  const navigate = useNavigate();

  return (
    <>
      <IconBack title="Voltar" onClick={() => navigate(-1)} />
      <IconDownload title="Baixar" onClick={() => window.print()} />
    </>
  );
}
