import styled from "styled-components";

export const Background = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  padding: 0.5rem calc((90vw - 1200px) / 2);
  background-color: #0093e9;
  background-image: linear-gradient(0deg, #0093e9 0%, #99d4f7 50%, #ffffff 100%);
`;

export const Container = styled.div`
  width: 98%;
  height: fit-content;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  min-width: 289px;

  @media (max-width: 830px) {
    width: 90%;
  }
`;

export const Hr = styled.div`
  background-color: #0093e9;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 0.2rem;
  width: 80%;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    display: flex;
    gap: 20px;
    align-items: center;
  }
`;

export const Titulo = styled.h1`
  color: #0093e9;
  text-align: center;

  @media (max-width: 550px) {
    font-size: 28px;
  }
`;

export const Button = styled.button`
  margin-right: 5px;
  width: fit-content;
  height: fit-content;
  padding: 15px 20px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  text-transform: capitalize;

  &:hover {
    background-color: #1563cf;
  }
`;

export const Input = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  select {
    padding: 3px 7px;
    width: 250px;
    height: 45px;
    border-radius: 4px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 17px;
  }

  input {
    padding: 3px 7px;
    width: 250px;
    height: 45px;
    border-radius: 4px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 17px;
  }
`;

export const Table = styled.div`
  margin-top: 15px;
  /* width: 99%; */
  overflow-x: auto;

  td {
    padding: 0 5px;
  }

  .color {
    display: flex;
    padding: 10px 10px;
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: #6aa84f;
  }
`;


// export const Table = styled.div`
//   margin-top: 15px;
//   overflow-x: auto;

//   table {
//     width: 100%;
//     border-collapse: collapse;
//   }

//   td {
//     max-height: 35px;
//     padding: 2px 5px;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }

//   /* Ajuste de tamanho conforme os slice() */

//   td[data-title="ID"] {
//     max-width: 50px;
//   }

//   td[data-title="Unidade"] {
//     max-width: 110px; /* Slice(0, 10) */
//     /* max-width: 200px; */
//   }

//   td[data-title="Tipo"] {
//     max-width: 145px; /* Slice(0, 18) */
//     /* max-width: 130px; */
//   }

//   td[data-title="Centro Custo"] {
//     max-width: 120px; /* Slice(0, 16) */
//   }

//   td[data-title="Professor"] {
//     max-width: 180px; /* Slice(0, 15) */
//   }

//   td[data-title="Curso"] {
//     max-width: 200px; /* Slice(0, 28) */
//   }

//   td[data-title="Data início"],
//   td[data-title="Data fim"] {
//     max-width: 90px;
//   }

//   td[data-title="Carga Horária"] {
//     max-width: 80px;
//   }

//   /* Tooltip para quando o texto for cortado */
//   /* td[title]:hover {
//     overflow: visible;
//     white-space: normal;
//     position: relative;
//     z-index: 1;
//   } */

//   .color {
//     display: flex;
//     padding: 10px 10px;
//     width: 10px;
//     height: 10px;
//     border-radius: 100px;
//     background-color: #6aa84f;
//   }
// `;
