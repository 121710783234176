import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Select,
  Label,
} from "../../ModalEditStyle";
import api from "../../../../services/api";
import Swal from "sweetalert2";

export default function ModalRegister({ showModal, setShowModal, data }) {
  const modalRef = useRef();
  const [coordenadores, setCoordenadores] = useState([]);
  const [coordenador, setCoordenador] = useState("");
  const [centros_custos, setCentros_custos] = useState([]);
  const [centro_custo, setCentro_custo] = useState("");
  const [id_unidade, setId_unidade] = useState(0);
  const [id_curso, setId_curso] = useState(0);
  const [id_centro_custo, setId_centro_custo] = useState(0);
  const [id_coordenador, setId_coordenador] = useState(0);
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    if (data) {
      setCoordenador(data.coordenadores);
      setId_coordenador(data.id_coordenador);
      setId_unidade(data.id_unidade);
      setId_curso(data.id_curso);
      setCentro_custo(data.centro_custo);
      setId_centro_custo(data.id_centro_custo);
    }
  }, [data]);

  useEffect(() => {
    api
      .get(`/rh/users`)
      .then((response) => {
        setCoordenadores(response.data.usuarios);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/rh/costcenter`)
      .then((response) => {
        setCentros_custos(response.data.centros_custo);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      id_coordenador: id_coordenador,
      id_centro_custo: id_centro_custo,
      id_unidade: id_unidade,
      id_curso: id_curso,
    };
    try {
      const response = await api.patch(`/rh/definicaocoord`, {
        data,
      });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Toast.fire({
        icon: "success",
        title: "Editado com sucesso!",
      });
      setShowModal(!showModal);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      alert(
        error
      ); /*Swal('Atenção!', 'Serviço indisponível no momento', 'error')*/
    }
  }
  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
              // clear();
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Atualizar Coordenador</h3>
                <FormContent>
                  <Label>Coordenador:</Label>
                  <Select
                    type="name"
                    value={coordenador}
                    onChange={(e) => {
                      setCoordenador(e.target.value);
                      setId_coordenador(
                        e.target[e.target.options.selectedIndex].id
                      );
                    }}
                    required
                  >
                    <option disabled value="">
                      Selecione o coordenador
                    </option>
                    {coordenadores.map((c, i) => (
                      <option id={c.id} key={i}>
                        {c.nome}
                      </option>
                    ))}
                  </Select>
                  {/* <Label>Unidade:</Label>
                <Select
                  type="name"
                  value={unidade}
                  onChange={(e) => {
                    setUnidade(e.target.value)
                    setId_unidade(e.target[e.target.options.selectedIndex].id);
                  }}
                >
                    <option selected disabled value=''>Selecione a unidade</option>
                    {unidades.map((u,i) => (
                      <option
                        id={u.id}
                      >{u.nome}</option>
                    ))}
                  </Select>
                <Label>Curso:</Label>
                <Select
                  type="name"
                  value={curso}
                  placeholder="Curso"
                  onChange={(e) => {
                    setCurso(e.target.value)
                    setId_curso(e.target[e.target.options.selectedIndex].id);
                  }}
                >
                    <option selected disabled value=''>Selecione o curso</option>
                    {cursos.map((c,i) => (
                      <option
                        id={c.id}
                      >{c.nome}</option>
                    ))}
                  </Select> */}
                  <Label>Centro Custo:</Label>
                  <Select
                    type="name"
                    value={centro_custo}
                    onChange={(e) => {
                      setCentro_custo(e.target.value);
                      setId_centro_custo(
                        e.target[e.target.options.selectedIndex].id
                      );
                    }}
                    required
                  >
                    <option disabled value="">
                      {centros_custos?.filter((c) => c.ativo === 1).length > 0
                        ? "Selecione o centro de custo"
                        : "Nenhum centro de custo encontado"}
                    </option>
                    {centros_custos?.map((c, i) => {
                      return (
                        c.ativo === 1 && (
                          <option id={c.id} key={i}>
                            {c.nome}
                          </option>
                        )
                      );
                    })}
                  </Select>
                </FormContent>
                <br />
                <Button type="submit">Atualizar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                  // clear();
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
