import React, { useState, useEffect } from "react";
import { Container, MenuButton, Button } from "./style";
import { useNavigate } from "react-router-dom";
import Auth from "../../services/auth";
import config from "../../assets/json/texts.json";
import {
  isAuthenticatedRh,
  isAuthenticatedSecretaria,
  isAuthenticatedCoordenador,
  isAuthenticatedDiretor,
  getDecodedToken,
} from "../../auth";
import { IoDocumentText } from "react-icons/io5";
import { RiLockPasswordLine, RiArrowGoBackLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../services/api";
import routesData from "../../assets/json/routes.json";

const { STATIC_URL_DEV, STATIC_URL_PROD } = config;

export default function Documentation() {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(Auth.auth());
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [name, setName] = useState(
    isAuthenticatedRh()
      ? "rh"
      : isAuthenticatedSecretaria()
      ? "sec"
      : isAuthenticatedCoordenador() && "coord"
  );
  const [showManual, setShowManual] = useState(false);
  const [showReturn, setShowReturn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setHidden(!routesData.routes.includes(location.pathname));
    setShowManual(
      isAuthenticatedRh() ||
        isAuthenticatedSecretaria() ||
        isAuthenticatedCoordenador() ||
        isAuthenticatedDiretor()
    );
    setName(
      isAuthenticatedRh()
        ? "rh"
        : isAuthenticatedSecretaria()
        ? "sec"
        : isAuthenticatedCoordenador()
        ? "coord"
        : isAuthenticatedDiretor() && "dir"
    );
    setAuth(Auth.auth());
    setOpen(false);
    setShowReturn(Auth.accessUser());
  }, [location.pathname]);

  async function openPdf() {
    window.open(
      window.location.href.includes("localhost")
        ? `${STATIC_URL_DEV}/manual${name}?auth=${auth}`
        : `${STATIC_URL_PROD}/manual${name}?auth=${auth}`
    );
  }

  async function changePassword() {
    const { value: password } = await Swal.fire({
      title: "Alteração de Senha",
      input: "password",
      confirmButtonText: "Alterar",
      confirmButtonColor: "var(--blue-color)",
      // inputLabel: "Alterar senha",
      inputPlaceholder: "Insira uma nova senha",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      inputValidator: (value) => {
        if (value.length < 6)
          return "A senha deve possuir pelo menos 6 caracteres!";
      },
    });

    const data = {
      senha: password,
    };

    if (password) {
      const response = await api.patch(`/mudarsenha`, { data });
      if (response.data.error) {
        return Swal.fire({
          title: "Exclusão não permitida!",
          text: response.data.error,
          icon: "warning",
          confirmButtonColor: "var(--blue-color)",
        });
      }
      Swal.fire({
        title: "Sucesso!",
        text: "Senha alterada com sucesso!",
        icon: "success",
        confirmButtonColor: "var(--blue-color)",
      }).then(() => {
        localStorage.removeItem("token");
        navigate("/");
      });
    }
  }

  async function changePasswordByEmail() {
    const data = await Swal.fire({
      title: "Alteração de Senha",
      text: "Insira o e-mail para alterar a senha",
      html: `
        <input id="swal-input-email" class="swal2-input" type="email" placeholder="Digite seu e-mail">
        <input id="swal-input-password" class="swal2-input" type="password" placeholder="Nova senha">
      `,
      focusConfirm: false,
      confirmButtonText: "Alterar",
      confirmButtonColor: "var(--blue-color)",
      preConfirm: () => {
        const email = document.getElementById("swal-input-email").value;
        const password = document.getElementById("swal-input-password").value;

        if (!email) {
          Swal.showValidationMessage("O e-mail é obrigatório!");
          return false;
        }

        if (password.length < 6) {
          Swal.showValidationMessage(
            "A senha deve possuir pelo menos 6 caracteres!"
          );
          return false;
        }

        return { email, password };
      },
    });

    if (data?.value?.password && data?.value?.email) {
      const response = await api.patch(`/mudarsenha`, {
        data: {
          email: data.value.email,
          senha: data.value.password,
        },
      });
      if (response.data.error) {
        return Swal.fire({
          title: "Exclusão não permitida!",
          text: response.data.error,
          icon: "warning",
          confirmButtonColor: "var(--blue-color)",
        });
      }
      Swal.fire({
        title: "Sucesso!",
        text: "Senha alterada com sucesso!",
        icon: "success",
        confirmButtonColor: "var(--blue-color)",
      });
    }
  }

  async function returnAccessUser() {
    const data = { accessUser: false };
    const diretor = isAuthenticatedDiretor();
    try {
      const response = await api.patch(`/${diretor ? "dir" : "dev"}/accessuser`,{data});
      if (response.data.error)
        return Swal.fire({
          title: "Atenção!",
          text: "Não foi possível acessar!",
          icon: "warning",
          confirmButtonColor: "var(--blue-color)",
        });
      localStorage.setItem("token", response.data.token);
      diretor ? navigate("/dir") : navigate("/usuarios");
    } catch (err) {
      Swal.fire({
        title: "Atenção!",
        text: "Não foi possível acessar!",
        icon: "warning",
        confirmButtonColor: "var(--blue-color)",
      });
    }
  }

  return (
    <Container hidden={!!hidden}>
      <Button
        show={!!showReturn}
        open={open}
        onClick={() => returnAccessUser()}
        title={"Voltar para o usuário padrão"}
      >
        <RiArrowGoBackLine />
      </Button>
      <Button
        show={!!showManual}
        open={open}
        onClick={() => openPdf()}
        title={"Manual"}
      >
        <IoDocumentText />
      </Button>
      {showReturn ? (
        <Button
          show={"true"}
          open={open}
          onClick={() => changePasswordByEmail()}
          title={"Alterar Senha"}
        >
          <RiLockPasswordLine />
        </Button>
      ) : (
        <Button
          show={"true"}
          open={open}
          onClick={() => changePassword()}
          title={"Alterar Senha"}
        >
          <RiLockPasswordLine />
        </Button>
      )}
      <MenuButton
        open={open}
        onClick={() => setOpen(!open)}
        title={"Alterar Senha / Manual"}
      >
        <div>
          <div />
        </div>
        <div />
        <div>
          <div />
        </div>
        <div />
        <div />
        <div />
        <div>
          <div />
        </div>
        <div />
        <div>
          <div />
        </div>
      </MenuButton>
    </Container>
  );
}
