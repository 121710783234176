import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import { Background, Container, Titulo, Hr, Button, Table, Row, Input } from "./style";
import { IoPencilOutline, IoTrashOutline } from "react-icons/io5";
import Auth from "../../../services/auth";
import Pagination from "../../../components/Pagination";
import ModalEdit from "../../../components/Modal/Coord/EditLancamentoEventuais";
import { Link } from "react-router-dom";
import { isAuthenticatedDiretor } from "../../../auth";
import ModalRegisterExcel from "../../../components/Modal/Coord/RegisterEventuaisExcel/RegisterExcel";
import { RiFileExcel2Fill } from "react-icons/ri";

export default function Eventuais() {
  const [id] = useState(Auth.id());
  const [page, setPage] = useState(1);
  const [interval, setInterval] = useState(3);
  const [lancamentos_eventuais, setLancamentos_eventuais] = useState([]);
  const [lancamento_eventual, setLancamento_eventual] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [searchImports, setSearchImports] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalRegisterExcel, setShowModalRegisterExcel] = useState(false);
  const diretor = isAuthenticatedDiretor();

  useEffect(() => {
    api
      .get(`/coord/events?nome=${search}&idCoord=${id}&interval=${interval}&searchImports=${searchImports}`)
      .then((response) => {
        setLancamentos_eventuais(response.data.lancamentos_eventuais);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [id, search, showModalEdit, interval, searchImports]);

  useEffect(() => {
    setTotalPages(Math.ceil(lancamentos_eventuais.length / 15));
  }, [lancamentos_eventuais, showModalEdit]);

  function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você não poderá reverter essa ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Deletar",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/coord/events/${id}`);
        if (response.data.error) return Swal.fire("Exclusão não permitida!", response.data.error, "warning");
        Toast.fire({
          icon: "success",
          title: "Deletado com sucesso!",
        });
        setLancamentos_eventuais(lancamentos_eventuais.filter((lancamento) => lancamento.id !== id));
      }
    });
  }

  useEffect(() => {
    setPage(totalPages >= 1 ? (totalPages < page ? page - 1 : page) : page);
  }, [page, setPage, totalPages]);

  return (
    <Background>
      <Container>
        <Titulo>Lançamentos Eventuais</Titulo>
        <Hr />
        <Row>
          <div>
            <Link to="/eventuaiscoord" hidden={diretor}>
              <Button>Novo</Button>
            </Link>
            {interval !== 300 ? (
              <Button
                onClick={() => {
                  setInterval(interval === 150 ? 3 : 150);
                  setPage(1);
                }}
              >
                {interval === 150 ? "Mostrar Menos" : "Mostrar Todos"}
              </Button>
            ) : null}
            <Button
              hidden={diretor}
              onClick={() => {
                setShowModalRegisterExcel(true);
              }}
            >
              Importar Excel
            </Button>
          </div>
          <div>
            {lancamentos_eventuais.some((t) => t.tipo_jornada === "AUTO") ? (
              <Button
                hidden={diretor}
                onClick={() => {
                  setSearchImports(!searchImports);
                  setInterval(interval === 300 ? 3 : 300);
                  setPage(1);
                }}
              >
                Ver {searchImports ? "Todos" : "Importados"}
              </Button>
            ) : null}
            <Input>
              <input
                value={search}
                onChange={(e) => {
                  setInterval(e.target.value.length === 0 ? 3 : 300);
                  setSearch(e.target.value.toUpperCase());
                  setPage(1);
                }}
                type="search"
                placeholder="Pesquisar..."
              />
            </Input>
          </div>
        </Row>
        <Table>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Unidade</th>
                <th>Tipo</th>
                <th>C.C.</th>
                <th>Centro de Custo</th>
                <th>Professor</th>
                <th>Curso</th>
                <th>Data início</th>
                <th>Data fim</th>
                <th>Carga</th>
                <th hidden={diretor}></th>
                <th hidden={diretor}></th>
                {lancamentos_eventuais.slice((page - 1) * 15, page * 15).some((t) => t.auto) ? <th></th> : null}
              </tr>
            </thead>
            <tbody>
              {lancamentos_eventuais.slice((page - 1) * 15, page * 15).map((t, i) => {
                const tee = ["TÉCNICO EM", "TECNÓLOGO EM"];
                // Criando uma expressão regular para substituir qualquer um dos itens do array
                const regex = new RegExp(tee.join("|"), "g");
                const centro = t.centro_custo.replace("CURSO", "");
                const auto = t.auto;

                return (
                  <tr key={i}>
                    <td
                      data-title="ID"
                      style={{
                        padding: "0 4px",
                      }}
                    >
                      {t.id}
                    </td>
                    <td title={t.unidade} data-title="Unidade" className="td-left">
                      {t.unidade.slice(0, 12)}
                      {t.unidade.length > 12 ? "..." : ""}
                    </td>
                    <td data-title="Tipo" title={t.tipo_lancamento} className="td-left">
                      {t.tipo_lancamento.slice(0, 16)}
                      {t.tipo_lancamento.length > 16 ? "..." : ""}
                    </td>
                    <td data-title="Centro Custo" title={t.centro_custo}>
                      {t.id_centro_custo}
                    </td>
                    <td data-title="Centro Custo" title={t.centro_custo} className="td-left">
                      {centro.slice(0, 16).trim()}
                      {centro.length > 16 ? "..." : ""}
                    </td>
                    <td data-title="Professor" title={t.professor + " | " + t.id_professor} className="td-left">
                      {t.professor.slice(0, 15)}
                      {t.professor.length > 15 ? "..." : ""}
                    </td>
                    <td data-title="Curso" className="td-left" title={t.curso}>
                      {t.curso.replace(regex, "").trim()}
                      {t.curso.length > 28 ? "..." : ""}
                    </td>
                    <td data-title="Data início">{t.data_inicio}</td>
                    <td data-title="Data fim">{t.data_fim}</td>
                    <td data-title="Carga Horária">{t.carga_horaria ? t.carga_horaria : t.quantidade_deslocamentos}</td>
                    <td hidden={diretor}>
                      <IoPencilOutline
                        title="Editar"
                        style={{
                          fontSize: "25px",
                          cursor: new Date().getMonth() + 1 === parseInt(t.data_inicio.split("/")[1]) ? "pointer" : "unset",
                          color: new Date().getMonth() + 1 === parseInt(t.data_inicio.split("/")[1]) ? "black" : "gray",
                        }}
                        onClick={() => {
                          if (new Date().getMonth() + 1 === parseInt(t.data_inicio.split("/")[1])) {
                            setShowModalEdit(true);
                            setLancamento_eventual(t);
                          }
                        }}
                      />
                    </td>
                    <td hidden={diretor}>
                      <IoTrashOutline
                        title="Excluir"
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={() => {
                          handleDelete(t.id);
                        }}
                      />
                    </td>
                    {auto ? (
                      <td>
                        <RiFileExcel2Fill color="green" size={25} title="Importado" />
                        {/* {" "}
                        <span className="color" />{" "} */}
                      </td>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Table>
        <ModalEdit showModal={showModalEdit} setShowModal={setShowModalEdit} data={lancamento_eventual} />
        <ModalRegisterExcel showModalExcel={showModalRegisterExcel} setShowModalExcel={setShowModalRegisterExcel} />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
