import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import {
  Background,
  Container,
  Titulo,
  Hr,
  Form,
  InputLabel,
  Inputs,
  ButtonAdd,
  BigButton,
  H4,
} from "./style";
import "./styles.css";
import Auth from "../../../services/auth";
import { Swal, Toast } from "../../../services/SwalTemplates";
import GoBack from "../../../components/GoBack";
import { IoTrashOutline } from "react-icons/io5";

export default function LancamentoTurmas() {
  const [id] = useState(Auth.id());
  const [unidades, setUnidades] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [unidade, setUnidade] = useState("");
  const [turma, setTurma] = useState("");
  const [id_unidade, setId_unidade] = useState(0);
  const [id_curso, setId_curso] = useState(0);
  const [id_centro_custo, setId_centro_custo] = useState(0);
  const [id_turma, setId_turma] = useState(0);
  const [matricula_colaborador, setMatricula_colaborador] = useState(0);
  const [data_inicio, setData_inicio] = useState("");
  const [data_fim, setData_fim] = useState("");
  const [horario_inicio, setHorario_inicio] = useState("");
  const [horario_fim, setHorario_fim] = useState("");
  const [dia_semana, setDia_semana] = useState("");
  const [disciplinas, setDisciplinas] = useState([]);
  const [disciplina, setDisciplina] = useState("");
  const [id_disciplina, setId_disciplina] = useState(0);
  const [colaboradores, setColaboradores] = useState([]);
  const [colaborador, setColaborador] = useState("");
  const [rowTable, setRowTable] = useState([]);

  useEffect(() => {
    api
      .get(`/coord/turmasdisciplinas?idTurma=${id_turma}`)
      .then((response) => {
        setDisciplinas(response.data.disciplinas);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/classesnaolancadas?idCoord=${id}&idTurma=${id_turma}`)
      .then((response) => {
        setData_inicio(
          response.data.turmas.length ? response.data.turmas[0].data_inicio : ""
        );
        setData_fim(
          response.data.turmas.length ? response.data.turmas[0].data_fim : ""
        );
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/lancamentofeitos?idTurma=${id_turma}`)
      .then((response) => {
        setRowTable(response.data.lancamento_grade);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [id, id_turma]);

  useEffect(() => {
    api
      .get(`/coord/classesnaolancadas?idCoord=${id}&idUnidade=${id_unidade}`)
      .then((response) => {
        setTurmas(response.data.turmas);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/contributors?inativo=${false}`)
      .then((response) => {
        setColaboradores(response.data.colaboradores);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [id, id_unidade]);

  useEffect(() => {
    api
      .get(`/coord/units?idCoord=${id}`)
      .then((response) => {
        setUnidades(response.data.unidades);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [id]);

  function addRowTable() {
    setRowTable([
      ...rowTable,
      {
        disciplina: disciplina,
        colaborador: colaborador,
        id_disciplina: id_disciplina,
        matricula_colaborador: matricula_colaborador,
        dia_semana: dia_semana,
        horario_inicio: horario_inicio,
        horario_fim: horario_fim,
      },
    ]);
  }

  function sendObj() {
    if (
      disciplina === "" ||
      colaborador === "" ||
      dia_semana === "" ||
      horario_inicio === "" ||
      horario_fim === ""
    ) {
      return Swal.fire("Atenção!", "Preencha todos os itens!", "warning");
    }
    if (horario_fim <= horario_inicio) {
      return Swal.fire(
        "Atenção!",
        "Horário final deve ser superior ao horário de início!",
        "warning"
      );
    }
    if (rowTable.length !== 0) {
      let f = rowTable.filter((r) => r.dia_semana === dia_semana);
      if (f.length) {
        if (
          f[f.length - 1].horario_fim <= horario_inicio ||
          disciplina.includes("ESTÁGIO") || disciplinas[0].turma.includes("ENFERMAGEM")
        ) {
          addRowTable();
        } else {
          Swal.fire(
            "Atenção!",
            `O horário definido ${horario_inicio} e ${horario_fim} já está alocado`,
            "warning"
          );
        }
      }
      if (f.length === 0) {
        addRowTable();
      }
    } else {
      addRowTable();
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (rowTable.length === 0) {
      return Swal.fire(
        "Atenção!",
        "Adicione ao menos um lançamento à lista!",
        "warning"
      );
    }

    const data = {
      id_unidade: id_unidade,
      id_curso: id_curso,
      id_centro_custo: id_centro_custo,
      id_turma: id_turma,
      data_inicio: data_inicio,
      data_fim: data_fim,
      e_troca: 0,
      dados: rowTable,
    };

    try {
      const response = await api.post(`/coord/lancamento`, {
        data,
      });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Toast.fire({
        icon: "success",
        title: "Cadastrado com sucesso!",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log(error);
      Swal.fire("Atenção!", error, "error");
    }
  }

  return (
    <Background>
      <GoBack />
      <Container>
        <Titulo>Lançamento de Horários das Turmas</Titulo>
        <Hr />
        <Form>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <InputLabel>
                <label
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  Unidade
                </label>
                <select
                  value={unidade}
                  onChange={(e) => {
                    setUnidade(e.target.value);
                    setId_unidade(e.target[e.target.options.selectedIndex].id);
                  }}
                  style={{ width: "700px" }}
                >
                  <option disabled value="">
                    Selecione a unidade
                  </option>
                  {unidades.map((u, i) => (
                    <option key={i} id={u.id}>
                      {u.nome}
                    </option>
                  ))}
                </select>
              </InputLabel>
              <InputLabel>
                <label
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  Turma
                </label>
                <select
                  value={turma}
                  onChange={(e) => {
                    setTurma(e.target.value);
                    setId_turma(
                      e.target[e.target.options.selectedIndex].id.split("|")[0]
                    );
                    setId_curso(
                      e.target[e.target.options.selectedIndex].id.split("|")[2]
                    );
                    setId_centro_custo(
                      e.target[e.target.options.selectedIndex].id.split("|")[3]
                    );
                  }}
                  style={{ width: "700px" }}
                >
                  <option disabled value="">
                    Selecione a turma
                  </option>
                  {turmas.map((t, i) => (
                    <option
                      key={i}
                      id={
                        t.id +
                        "|" +
                        t.id_unidade +
                        "|" +
                        t.id_curso +
                        "|" +
                        t.id_centro_custo
                      }
                    >
                      {t.turma}
                    </option>
                  ))}
                </select>
              </InputLabel>
            </div>
            <br />
            {data_inicio && data_fim ? (
              <div style={{ display: "flex", marginTop: -5 }}>
                <label style={{ marginTop: 15, marginRight: 10 }}>
                  Vigência:
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h5>De:</h5>
                  <p style={{ fontSize: 15 }}>{data_inicio}</p>
                </div>
                <div style={{ marginLeft: 30 }}>
                  <h5>Até:</h5>
                  <p style={{ fontSize: 15 }}>{data_fim}</p>
                </div>
              </div>
            ) : (
              <></>
            )}
            {rowTable.length > 0 ? (
              <table style={{ marginTop: 20 }}>
                <thead>
                  <tr>
                    <th>Disciplina</th>
                    <th>Professor</th>
                    <th style={{ padding: "5px" }}>Dia</th>
                    <th>Início</th>
                    <th>Fim</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {rowTable.map((r, i) => (
                    <tr key={i}>
                      <td>{r.disciplina}</td>
                      <td>{r.colaborador}</td>
                      <td>{r.dia_semana}</td>
                      <td>{r.horario_inicio}</td>
                      <td>{r.horario_fim}</td>
                      <td>
                        <IoTrashOutline
                          title="Excluir"
                          style={{ fontSize: "25px", cursor: "pointer" }}
                          onClick={() => {
                            setRowTable(
                              rowTable.filter((row, index) => i !== index)
                            );
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <></>
            )}
            <Hr />
            <Inputs>
              <H4>Componente Curricular</H4>
              <H4>Professor</H4>
              <H4>Horário das Aulas</H4>
              <InputLabel>
                <select
                  value={disciplina}
                  onChange={(e) => {
                    setDisciplina(e.target.value);
                    setId_disciplina(
                      e.target[e.target.options.selectedIndex].id
                    );
                  }}
                >
                  <option disabled value="">
                    Selecione a disciplina
                  </option>
                  {disciplinas.map((d, i) => (
                    <option key={i} id={d.id_disciplina}>
                      {d.disciplina}
                    </option>
                  ))}
                </select>
              </InputLabel>
              <InputLabel>
                <select
                  value={colaborador}
                  onChange={(e) => {
                    setColaborador(e.target.value);
                    setMatricula_colaborador(
                      e.target[e.target.options.selectedIndex].id
                    );
                  }}
                >
                  <option disabled value="">
                    Selecione o professor
                  </option>
                  {colaboradores.map((p, i) => (
                    <option key={i} id={p.matricula} title={p.matricula}>
                      {p.nome}
                    </option>
                  ))}
                </select>
              </InputLabel>
              <InputLabel>
                <select
                  value={dia_semana}
                  onChange={(e) => {
                    setDia_semana(e.target.value);
                  }}
                  id="dia_semana"
                >
                  <option disabled value="">
                    Dia
                  </option>
                  <option>SEG</option>
                  <option>TER</option>
                  <option>QUA</option>
                  <option>QUI</option>
                  <option>SEX</option>
                  <option>SÁB</option>
                </select>
              </InputLabel>
              <input
                type="time"
                value={horario_inicio}
                onChange={(e) => {
                  setHorario_inicio(e.target.value);
                }}
              />
              <input
                type="time"
                value={horario_fim}
                onChange={(e) => {
                  setHorario_fim(e.target.value);
                }}
              />
              <ButtonAdd type="button" onClick={() => sendObj()}>
                +
              </ButtonAdd>
            </Inputs>
            <BigButton type="submit">Salvar</BigButton>
            <BigButton style={{ width: "120px" }}>Imprimir</BigButton>
            <Link to="/lancamentoturmas" className="link">
              <BigButton style={{ background: "#e8343d" }}>Cancelar</BigButton>
            </Link>
          </form>
        </Form>
      </Container>
    </Background>
  );
}
