import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Select,
  Label,
} from "../../ModalRegisterStyle";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import { isAuthenticatedDev } from "../../../../auth";

export default function ModalRegister({ showModal, setShowModal }) {
  const modalRef = useRef();
  const [nome, setNome] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmSenha, setConfirmSenha] = useState("");
  const [email, setEmail] = useState("");
  const [perfil, setPerfil] = useState("COORD");

  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  /**
   * Vai servir para gerar o pdf do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      nome: nome,
      perfil: perfil,
      email: email,
      senha: senha,
    };
    if (senha !== confirmSenha) {
      return Swal.fire("Atenção!", "As senhas não estão iguais", "warning");
    }
    if (senha.length < 6) {
      return Swal.fire(
        "Atenção!",
        "A senha deve ter pelo menos 6 caracteres",
        "warning"
      );
    }
    try {
      const response = await api.post(`rh/register`, { data });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Swal.fire("Sucesso!", response.data.success, "success");
      Toast.fire({
        icon: "success",
        title: "Cadastrado com sucesso!",
      });
      setShowModal(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      alert(error);
    }
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
              // clear();
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Cadastrar Usuário</h3>
                <FormContent>
                  <Label>Nome:</Label>
                  <Input
                    type="name"
                    value={nome}
                    placeholder="Nome"
                    onChange={(e) => setNome(e.target.value.toUpperCase())}
                    required
                  />
                  <Label>Perfil:</Label>
                  <Select
                    value={perfil}
                    placeholder="Perfil"
                    onChange={(e) => setPerfil(e.target.value)}
                    required
                  >
                    <option value="COORD">Coordenação</option>
                    <option value="RH">RH</option>
                    <option value="SEC">Secretaria</option>
                    <option value="DIR">Diretoria</option>
                    {isAuthenticatedDev() ? (
                      <option value="DEV">Desenvolvedor</option>
                    ) : null}
                  </Select>
                  <Label>Email:</Label>
                  <Input
                    type="email"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <Label>Senha:</Label>
                  <Input
                    type="password"
                    value={senha}
                    placeholder="Senha"
                    onChange={(e) => setSenha(e.target.value)}
                    required
                  />
                  <Label>Confirmar Senha:</Label>
                  <Input
                    type="password"
                    value={confirmSenha}
                    placeholder="Senha"
                    onChange={(e) => setConfirmSenha(e.target.value)}
                    required
                  />
                </FormContent>
                <br />
                <Button type="submit">Cadastrar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                  // clear();
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
