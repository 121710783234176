import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Background, Container, Titulo, Hr, Table, Input } from "./style";
import api from "../../../../services/api";
import { Swal } from "../../../../services/SwalTemplates";
import Pagination from "../../../../components/Pagination";

export default function Fechar() {
  const navigate = useNavigate();
  const [fechamentos, setFechamentos] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    api
      .get(`/rh/listFechamentoRh?coordenador=${search}`)
      .then((response) => {
        setFechamentos(response.data.fechamentos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [navigate, search]);

  useEffect(() => {
    setTotalPages(Math.ceil(fechamentos.length / 12));
  }, [fechamentos]);

  return (
    <Background>
      <Container>
        <Titulo>Fechamentos realizados</Titulo>
        <Hr />
        <Input
          value={search}
          onChange={(e) => {
            setSearch(e.target.value.toUpperCase());
            setPage(1);
          }}
          type="search"
          placeholder="Pesquisar coordenador..."
        />
        <Table>
          <table>
            <thead>
              <tr>
                <th>Coordenador</th>
                <th>Curso</th>
                <th>Data</th>
              </tr>
            </thead>
            <tbody>
              {fechamentos.slice((page - 1) * 12, page * 12).map((f, i) => (
                <tr key={i}>
                  <td data-title="Coordenador">{f.coordenador}</td>
                  <td data-title="Centro Custo">{f.centro_custo}</td>
                  <td data-title="Data">{f.data_fechamento}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
