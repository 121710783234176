import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  DropDiv,
  InputFile,
  RemoveIcon,
  Icon,
  FileContainer,
  FilePreview,
  FileName,
} from "../../ModalRegisterStyle";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import xlsx from "../../../../assets/Images/Iconxlsx.png";
import ResponseXlsx from "./StatusEventuaisXslx";

export default function ModalRegisterExcel({ showModalExcel, setShowModalExcel }) {
  const modalRef = useRef();
  const [file, setFile] = useState(null);
  // const [fileImg, setFileImg] = useState(null);
  const [tipo, setTipo] = useState("");
  const [nomeArquivo, setNomeArquivo] = useState("");
  const [showresults, setShowResults] = useState(false);
  const [response, setResponse] = useState([]);

  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModalExcel ? 1 : 0,
    transform: showModalExcel ? `translateY(0%)` : `translateY(-100%)`,
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModalExcel) setShowModalExcel(!showModalExcel);
    },
    [setShowModalExcel, showModalExcel]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleFileChange = (e) => {
    if (e?.target?.files?.length) {
      const selectedFile = e.target.files[0];
      const allowedFormats = ["xlsx"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      if (allowedFormats.includes(fileExtension)) {
        // setFileImg(URL.createObjectURL(selectedFile));
        setFile(selectedFile);
        setTipo(selectedFile.type);
        setNomeArquivo(selectedFile.name);
      } else {
        Swal.fire({
          title: "Atenção!",
          text: "Formato não permitido!",
          icon: "warning",
        });
      }
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    // setFileImg(null);
    setTipo("");
    setNomeArquivo("");
  };

  async function HandleManualSubmit(e) {
    if (file) {
      const infoFile = {
        tituloDocumento: tipo,
        NomeArquivo: nomeArquivo,
      };
      const formData = new FormData();

      Object.keys(infoFile).forEach((key) => {
        formData.append(key, infoFile[key]);
      });
      formData.append("file", file);

      try {
        const { data } = await api.post(`/coord/events/excel`, formData, {
          headers: { "content-type": "multipart/form-data" },
        });
        // console.log("data", data);
        const descricao = data?.descricao;
        const erro =
          descricao?.deslocamento?.falhas ||
          descricao?.substituicao?.falhas ||
          descricao?.deslocamento?.falta_info ||
          descricao?.substituicao?.falta_info ||
          descricao?.deslocamento?.erro_servidor ||
          descricao?.substituicao?.erro_servidor;

        const baseStyle = "border: 1px solid #000; padding: 5px;";
        // const successStyle = "color: green; font-weight: bold;";
        const errorStyle = "color: red; font-weight: bold;";

        const getStyle = (key, value) => {
          if (["falhas", "falta_info", "erro_servidor"].includes(key) && value > 0) return `${baseStyle} ${errorStyle}`;
          // if (key === "sucesso" && value > 0) return `${baseStyle} ${successStyle}`;
          return baseStyle;
        };

        await Toast.fire({
          icon: "success",
          title: data.mensagem || "Planilha carregada!",
        });

        await Swal.fire({
          title: erro ? "Planilha carregada com erros!" : "Planilha carregada!",
          html: `
            <div style="display: flex; flex-direction: column; align-items: center; font-family: Arial, sans-serif;
              gap: 5px; text-align: center; width: 100%;">
              <table style="width: 100%; border-collapse: collapse; ${baseStyle}">
                <thead>
                  <tr style="background-color: #f0f0f0;">
                    <th style="${baseStyle}"></th>
                    <th style="${baseStyle}">Deslocamentos</th>
                    <th style="${baseStyle}">Substituições</th>
                  </tr>
                </thead>
                <tbody>
                  ${[
                    { label: "Total", key: "total" },
                    { label: "Sucesso", key: "sucesso" },
                    { label: "Falhas", key: "falhas" },
                    { label: "Falta Informações", key: "falta_info" },
                    { label: "Erro no Servidor", key: "erro_servidor" },
                  ].map(({ label, key }) => `
                      <tr>
                        <td style="${baseStyle} text-align: left;">${label}</td>
                        <td style="${getStyle(key, descricao?.deslocamento?.[key])}">
                          ${descricao?.deslocamento?.[key] || 0}
                        </td>
                        <td style="${getStyle(key, descricao?.substituicao?.[key])}">
                          ${descricao?.substituicao?.[key] || 0}
                        </td>
                      </tr>
                    `
                  ).join("")}
                </tbody>
              </table>
              <br />
              <strong>Para detalhes clique em "Visualizar"!</strong>
            </div>
          `,
          icon: erro ? "error" : "success",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          cancelButtonColor: "#ff735c",
          iconColor: erro ? "red" : "#6aa84f",
          confirmButtonColor: "#1563cf",
          confirmButtonText: "Visualizar",
        }).then((result) => {
          if (result.isConfirmed) {
            setResponse(data);
            setShowResults(true);
          } else {
            setShowModalExcel(false);
          }
        });
      } catch (err) {
        console.log("err", err);
        Swal.fire({ title: "ERRO!", text: err.response.data.erro || "Erro ao enviar a planilha", icon: "error" });
        // window.location.reload();
      } finally {
        handleRemoveFile();
      }
    }
  }

  return (
    <>
      {showModalExcel ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setFile("");
              // setFileImg("");
              setTipo("");
              setNomeArquivo("");
              setShowModalExcel(!showModalExcel);
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form>
                <h3>Carregar planilha</h3>
                <FormContent>
                  <DropDiv file={file}>
                    {file ? (
                      <FileContainer>
                        <FilePreview>
                          <img src={xlsx} alt="arquivo.xlsx" />
                          <FileName>{nomeArquivo}</FileName>
                        </FilePreview>
                        <RemoveIcon onClick={handleRemoveFile} />
                      </FileContainer>
                    ) : (
                      <>
                        <InputFile type="file" accept=".xlsx" onChange={handleFileChange} />
                        <Icon />
                      </>
                    )}
                  </DropDiv>
                </FormContent>
                <br />
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    HandleManualSubmit(e);
                  }}
                >
                  Enviar
                </Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModalExcel(!showModalExcel);
                }}
              />
            </ModalContent>
          </animated.div>
          <ResponseXlsx
            showresults={showresults}
            setShowResults={setShowResults}
            setShowModalExcel={setShowModalExcel}
            response={response}
            setResponse={setResponse}
          />
        </Background>
      ) : null}
    </>
  );
}
