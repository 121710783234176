import React, { useEffect, useState } from "react";
import { Background, Page, Subpage, Images, Content, Title, Table, Assinaturas, THead, P } from "./style";
import Prefeitura from "../../../assets/Images/prefeitura.jpg";
import Fiec from "../../../assets/Images/fiec.png";
import PdfButtons from "../../../components/PdfButtons";
import api from "../../../services/api";
import { Swal } from "../../../services/SwalTemplates";

export default function NovoPdfFechamentoRH() {
  const [Pages, setPages] = useState([]);
  const [Dados, setDados] = useState([]);
  const [colunas] = useState([
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21, // 1º passo add coluna nova exemplo: (28,29, + 30,) = 28, 29, 30,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
  ]);
  const [Loading, setLoading] = useState(true);
  const largura = (1158 - 195) / 30; // 2º ajustar o segundo valor depois do - e add +1 ao denominador exemplo: '/ 30 + 1 = / 31'
  const RowsPerPage = 30;
  const RowsFirstPage = 25;

  if (Loading) {
    Swal.fire({
      title: "Aguarde!",
      text: "O relatório está sendo gerado!",
      icon: "info",
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  useEffect(() => {
    api
      .get(`/rh/lerpdfrh?novo=true`)
      .then((response) => {
        if (response.data.error)
          return Swal.fire({
            title: "Atenção!",
            text: response.data.error,
            icon: "warning",
            confirmButtonColor: "var(--blue-color)",
          });
        setDados(response.data.response);
        setLoading(false);
        Swal.close();
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar!",
          icon: "warning",
          confirmButtonColor: "var(--blue-color)",
        });
      });
  }, []);

  useEffect(() => {
    if (Dados.length > RowsFirstPage) {
      setPages([...Array(Math.ceil((Dados.length - RowsFirstPage) / RowsPerPage + 1)).keys()]);
    } else {
      setPages([0]);
    }
  }, [Dados]);

  return (
    <Background>
      <PdfButtons />
      {Pages?.map((p, i) => (
        <Page key={i}>
          <Subpage>
            <Images className={i !== 0 ? "hidden" : ""}>
              <img src={Fiec} alt="Erro" />
              <img src={Prefeitura} alt="Erro" />
            </Images>
            <Content>
              <Title className={i !== 0 ? "hidden" : ""}>Ordem de Pagamento dos Professores</Title>
              <Title className={i !== 0 ? "hidden" : ""}>
                Período de Apuração: &nbsp;
                {Dados[0]?.data_inicio || "00/00/0000"}
                &nbsp; a &nbsp;
                {Dados[0]?.data_fim || "00/00/0000"}
              </Title>
              <THead id="boundary" tamanho={largura}>
                <div className="c1">Horas normais por centro de custo/Acrescimos legais/ Lancamentos eventuais</div>

                <div className="c2">
                  <div style={{ backgroundColor: "#DCDCDC" }} colSpan="1">
                    Horas normais por centro de custo
                  </div>
                  <div style={{ backgroundColor: "#A9A9A9" }} colSpan="3">
                    {" "}
                    {/* 3º ajustar a largura no 'THead' no css */}
                    Acrescimos legais
                  </div>
                  <div style={{ backgroundColor: "#DCDCDC" }} colSpan="8">
                    Lancamentos eventuais
                  </div>
                </div>

                <div className="c3">
                  {" "}
                  {/* 4º add a nova: "div single" */}
                  <div className="single">
                    <p> Téc regulares </p>
                  </div>
                  <div className="single">
                    <p>Técnologos</p>
                  </div>
                  <div className="single">
                    <p>Cursinho</p>
                  </div>
                  <div className="single">
                    <p>Extra Cur</p>
                  </div>
                  {/* <div className="single">
                    <p>PRONATEC</p>
                  </div> */}
                  <div className="single">
                    <p>MEDIOTEC</p>
                  </div>
                  <div className="single">
                    <p>QUALIFCA MAIS</p>
                  </div>
                  <div className="single">
                    <p>EPP2024</p>
                  </div>
                  <div className="single">
                    <p>EPP2025</p>
                  </div>
                  <div className="single">
                    <p>HTPC</p>
                  </div>
                  <div className="single">
                    <p>DSR</p>
                  </div>
                  <div className="single">
                    <p>insalubridade</p>
                  </div>
                  <div className="single">
                    <p>Desloc - EPP2024</p>
                  </div>
                  <div className="single">
                    <p>Desloc - EPP2025</p>
                  </div>
                  <div className="single">
                    <p>extra class / proj</p>
                  </div>
                  <div className="single">
                    <p>Substituição</p>
                  </div>
                  <div className="single">
                    <p>Substituição EPP2024</p>
                  </div>
                  <div className="single">
                    <p>Substituição EPP2025</p>
                  </div>
                  <div className="single">
                    <p>Treinamento</p>
                  </div>
                  <div className="single">
                    <p>Treinamento EPP2024</p>
                  </div>
                  <div className="single">
                    <p>Treinamento EPP2025</p>
                  </div>
                  <div className="single">
                    <p>Falta atraso H/A</p>
                  </div>
                  <div className="single">
                    <p>Falta - EPP2024</p>
                  </div>
                  <div className="single">
                    <p>Falta - EPP2025</p>
                  </div>
                  {/* <div className="single">
                    <p>Falta HTPC reunião</p>
                  </div>
                  <div className="single">
                    <p>Falta HTPC reunião EPP</p>
                  </div> */}
                  <div className="single">
                    <p>AS. COORDENAÇÃO</p>
                  </div>
                  <div className="single">
                    <p>AS. COORD. EPP2024</p>
                  </div>
                  <div className="single">
                    <p>AS. COORD. EPP2025</p>
                  </div>
                  <div className="single">
                    <p>COORDENAÇÃO</p>
                  </div>
                  <div className="single">
                    <p>COORD. EPP2024</p>
                  </div>
                  <div className="single">
                    <p>COORD. EPP2025</p>
                  </div>
                </div>
              </THead>

              <Table>
                <thead>
                  <tr>
                    <th style={{ width: "40px" }}>COD</th>
                    <th style={{ width: "120px" }}>Professor</th>
                    {colunas.map((colunas) => (
                      <th
                        key={colunas}
                        style={{
                          width: `${largura}px`,
                        }}
                      >
                        CH
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {Dados?.slice(
                    i === 0 || i === 1 ? p * RowsFirstPage : p * RowsPerPage - 5,
                    i === 0 ? (p + 1) * RowsFirstPage : (p + 1) * RowsPerPage - 5
                  )?.map((d, i) => (
                    <tr key={i}>
                      <td>{d.matricula}</td>
                      <td className="wrap">{d.colaborador}</td>
                      {colunas?.map((c, i) => (
                        <td key={i}>{d[i + 1]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>

              <P className={i !== Pages.length - 1 ? "hidden" : ""}>
                <b>Observação: </b> As horas normais dos professores efetivos incluem o calculo da 5ª semana do HTPC
              </P>
              <Assinaturas className={i !== Pages.length - 1 ? "hidden" : ""}>
                <div>Direção pedagógica</div>

                <div>Superintendente</div>
              </Assinaturas>
            </Content>
          </Subpage>
        </Page>
      ))}
    </Background>
  );
}
