import styled from "styled-components";

export const Background = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  background-color: #0093e9;
  justify-content: center;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );
`;

export const Container = styled.div`
  margin-top: 60px;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 2rem 20px;
  align-items: center;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;

  @media screen and (max-width: 1050px) {
    width: 90%;
  }
`;

export const Titulo = styled.h1`
  color: var(--blue-color);
  display: flex;
  align-items: center;
`;

export const Hr = styled.div`
  background-color: #0093e9;
  height: 0.19rem;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const Row = styled.div`
  display: grid;
  width: 100%;
  grid-template-areas: "n i t u d";
  grid-template-columns: 2fr 200px auto;
  align-items: center;
  margin: 10px 0;

  @media (max-width: 830px) {
    grid-template-areas:
      "n n u"
      "t i d";
    grid-template-columns: auto 1fr;
  }
`;

export const Nome = styled.div`
  grid-area: n;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 830px) {
    padding-bottom: 10px;
  }
`;

export const Input = styled.input`
  grid-area: i;
  width: 90%;
  text-align: center;
  height: 30px;
  border-radius: 5px;
  padding: 3px 7px;

  @media (max-width: 830px) {
    width: fit-content;
    margin-left: 10px;
  }
`;

export const ToggleButton = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "active",
})`
  grid-area: t;
  background-color: ${(props) => (props.active ? "var(--blue-color)" : "gray")};
  width: 44px;
  height: 25px;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.2s;
`;

export const Circle = styled.div`
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: transform 0.2s;
  transform: ${(props) =>
    props.active ? "translateX(100%)" : "translateX(0)"};
`;

export const Button = styled.button`
  width: 140px;
  height: 45px;
  border-radius: 4px;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  margin: 0 5px;

  &:hover {
    background-color: #1563cf;
  }
`;

export const ButtonDiv = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "changed",
})`
  display: ${(props) => (props.changed ? "flex" : "none")};
  width: 100%;
  margin-top: 35px;
  justify-content: center;
`;
