import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import {
  Background,
  Container,
  Titulo,
  Hr,
  Button,
  Form,
  InputLabel,
} from "./style";
import Auth from "../../../services/auth";
import TitlePagination from "../../../components/TitlePagination";

export default function Fechamento() {
  const navigate = useNavigate();
  const [id] = useState(Auth.id());
  const [nomeCoord] = useState(Auth.nome());
  const [mes, setMes] = useState("");
  const [page, setPage] = useState(1);
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [titlePage, setTitlePage] = useState("");
  const [idUsuario] = useState(Auth.id());
  const [fechamentosTemp, setFechamentosTemp] = useState([]);
  const [regras, setRegras] = useState([]);
  const [totalTitlePages, setTotalTitlePages] = useState(1);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [centroCusto, setCentroCusto] = useState("");
  const [idCentroCusto, setIdCentroCusto] = useState(0);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setPage(1);
  }, [titlePage]);

  useEffect(() => {
    setTotalTitlePages(
      Math.ceil(fechamentosTemp?.length / 1) > 0
        ? Math.ceil(fechamentosTemp?.length / 1)
        : 1
    );
  }, [fechamentosTemp]);

  useEffect(() => {
    api
      .get(`/regras?nome=${"CARGA HORARIA MINIMA MENSAL EFETIVO"}`)
      .then((response) => {
        if (response.data.regras) {
          setRegras(response.data.regras[0]);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/costcenter`)
      .then((response) => {
        setCentrosCusto(response.data.centros_custo);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    let d = new Date();
    setMes(
      `${d.getFullYear()}-${
        d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`
      }`
    );
  }, []);

  useEffect(() => {
    if (mes) {
      setDataInicio(`${mes}-01`);
      setDataFim(
        `${mes}-${new Date(mes.split("-")[0], mes.split("-")[1], 0).getDate()}`
      );
    }
  }, [mes]);

  useEffect(() => {
    setTitlePage(1);
    api
      .get(
        `/coord/fechamentotemp?id_usuario=${idUsuario}&id_centro_custo=${idCentroCusto}&data_inicio=${dataInicio}&data_fim=${dataFim}`
      )
      .then((response) => {
        setFechamentosTemp(response.data.fechamentos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [navigate, idCentroCusto, idUsuario, reload, dataInicio, dataFim]);

  function carga(data) {
    let counter = 0;
    // eslint-disable-next-line array-callback-return
    data?.map((f, i) => {
      counter += f?.carga_horaria;
    });
    return counter;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    // console.log(mes);
    //     if (mes.split("-")[1] < new Date().getMonth() -1 && mes.split("-")[0] <= new Date().getFullYear()) {
    //       Swal.fire({
    //         title: "Atenção!",
    //         text: "Não é permitido emitir um fechamento de um mês anterior: "+mes.split("-")[1]+"/"+mes.split("-")[0],
    //         icon: "warning",
    //       });
    //       return;
    //     }

    if (
      (dataInicio.split("-")[1] === mes.split("-")[1] ||
        dataFim.split("-")[1] === mes.split("-")[1]) &&
      (dataInicio.split("-")[0] === mes.split("-")[0] ||
        dataFim.split("-")[0] === mes.split("-")[0]) &&
      dataInicio <= dataFim &&
      mes !== ""
    ) {
      const data = {
        id_usuario: id,
        ano_mes: mes,
        data_inicio: dataInicio,
        data_fim: dataFim,
        id_centro_custo: idCentroCusto,
      };

      try {
        const response = await api.post(`/coord/fechamentotemp`, {
          data,
        });
        if (response.data.error) return alert(response.data.error);
        Toast.fire({
          icon: "success",
          title: "Fechamento gerado com sucesso!",
        });
        setTimeout(() => {
          setReload(!reload);
        }, 1000);
      } catch (error) {
        alert(error);
      }
    } else {
      Swal.fire({
        title: "Atenção!",
        text: "As datas devem corresponder!",
        icon: "warning",
        confirmButtonColor: "var(--blue-color)",
      });
    }
  }

  return (
    <Background>
      <Container>
        <Titulo>
          <h1>Fechamento</h1>
          <legend>{nomeCoord}</legend>
        </Titulo>
        <Hr />
        <Form>
          <form onSubmit={handleSubmit}>
            <InputLabel style={{ gridArea: "c" }}>
              <label style={{ width: "150px" }}>Centro de Custo</label>
              <select
                value={centroCusto}
                onChange={(e) => {
                  setCentroCusto(e.target.value);
                  setIdCentroCusto(e.target[e.target.options.selectedIndex].id);
                }}
                required
              >
                <option disabled value="">
                  Selecione o centro de custo
                </option>
                {centrosCusto
                  ?.filter((cc) => cc.nome !== "TODOS")
                  ?.map((c, i) => (
                    <option key={i} id={c.id}>
                      {c.nome}
                    </option>
                  ))}
              </select>
            </InputLabel>
            <InputLabel style={{ gridArea: "m" }}>
              <label style={{ width: "50px" }}>Mês</label>
              <input
                type="month"
                value={mes}
                onChange={(e) => {
                  setMes(e?.target?.value);
                }}
              />
            </InputLabel>
            <InputLabel style={{ gridArea: "i" }}>
              <label style={{ width: "150px" }}>Início</label>
              <input
                type="date"
                value={dataInicio}
                disabled
                onChange={(e) => {
                  setDataInicio(e.target.value);
                }}
              />
            </InputLabel>
            <InputLabel style={{ gridArea: "f" }}>
              <label style={{ width: "50px" }}>Fim</label>
              <input
                type="date"
                value={dataFim}
                disabled
                onChange={(e) => {
                  setDataFim(e.target.value);
                }}
              />
            </InputLabel>
            <Button style={{ gridArea: "b" }}>Realizar Fechamento</Button>
            <Button
              onClick={() => {
                if (!centroCusto) {
                  return Swal.fire({
                    title: "Atenção!",
                    text: "Selecione um centro de custo!",
                    icon: "warning",
                    confirmButtonColor: "var(--blue-color)",
                  });
                }
                navigate("/fechamentopdfcoord",{
                  state: {
                    data_inicio: dataInicio,
                    data_fim: dataFim,
                    idCentroCusto: idCentroCusto,
                    centroCusto: centroCusto,
                  },
                });
              }}
              type="button"
              style={{
                gridArea: "p",
                cursor: "pointer",
                opacity: 1,
                pointerEvents: "auto",
              }}
            >
              Gerar Relatório
            </Button>
          </form>
        </Form>

        {fechamentosTemp
          ?.slice((titlePage - 1) * 1, titlePage * 1)
          ?.map((f, i) => (
            <React.Fragment key={i}>
              <TitlePagination
                page={titlePage}
                totalPages={totalTitlePages}
                setPage={setTitlePage}
                title={f?.colaborador}
              />
              <h4
                style={{
                  textAlign: "center",
                  marginBottom: "5px",
                  marginTop: "20px",
                }}
              >
                Carga Horária no Período
              </h4>
              <table>
                <thead>
                  <tr>
                    <th>Unidade</th>
                    <th>Curso</th>
                    <th>Evento</th>
                    <th>Carga Horária</th>
                  </tr>
                </thead>
                <tbody>
                  {f?.dados ? (
                    f?.dados.map((f2, i) => (
                      <tr key={i}>
                        <td
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "200px",
                            paddingLeft: "5px",
                          }}
                          title={f2.unidade}
                          data-title="Unidade"
                        >
                          {f2.unidade}
                        </td>
                        <td data-title="Curso">{f2.curso}</td>
                        <td data-title="Evento">{f2.evento.toUpperCase()}</td>
                        <td data-title="Carga Horária">{f2.carga_horaria}</td>
                      </tr>
                    ))
                  ) : (
                    <tr key={i}>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: "200px",
                          paddingLeft: "5px",
                        }}
                        title={f?.unidade}
                        data-title="Unidade"
                      >
                        {f?.unidade}
                      </td>
                      <td data-title="Curso">{f?.curso}</td>
                      <td data-title="Evento">{f?.evento.toUpperCase()}</td>
                      <td data-title="Carga Horária">{f?.carga_horaria}</td>
                    </tr>
                  )}
                  {f?.dados && page === Math.ceil(f?.dados?.length / 10) ? (
                    <tr>
                      <th
                        colSpan="3"
                        style={{
                          color:
                            carga(f?.dados) < regras.valor &&
                            f?.vinculo === "ESTATUTARIO"
                              ? "var(--red-color)"
                              : "",
                        }}
                      >
                        Total:
                      </th>
                      <td
                        data-title="Total"
                        style={{
                          color:
                            carga(f?.dados) < regras.valor &&
                            f?.vinculo === "ESTATUTARIO"
                              ? "var(--red-color)"
                              : "",
                        }}
                      >
                        {carga(f?.dados)}
                      </td>
                    </tr>
                  ) : !f?.dados ? (
                    <tr>
                      <th
                        colSpan="3"
                        style={{
                          color:
                            f?.carga_horaria < regras.valor &&
                            f?.vinculo === "ESTATUTARIO"
                              ? "var(--red-color)"
                              : "",
                        }}
                      >
                        Total:
                      </th>
                      <td
                        data-title="Total"
                        style={{
                          color:
                            f?.carga_horaria < regras.valor &&
                            f?.vinculo === "ESTATUTARIO"
                              ? "var(--red-color)"
                              : "",
                        }}
                      >
                        {f?.carga_horaria}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              {f?.deslocamentos?.length ? (
                <>
                  <h4
                    style={{
                      textAlign: "center",
                      marginBottom: "5px",
                      marginTop: "20px",
                    }}
                  >
                    Deslocamentos
                  </h4>
                  <table>
                    <thead>
                      <tr>
                        <th>Unidade</th>
                        <th>Curso</th>
                        <th>Evento</th>
                        <th>Origem</th>
                        <th>Destino</th>
                        <th>Qtde</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {f?.deslocamentos?.map((f2, i) => (
                        <tr key={i}>
                          <td
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              maxWidth: "100px",
                              paddingLeft: "5px",
                            }}
                            title={f2.unidade}
                            data-title="Unidade"
                          >
                            {f2.unidade}
                          </td>
                          <td data-title="Curso">{f2.curso}</td>
                          <td data-title="Evento">{f2.evento.toUpperCase()}</td>
                          <td data-title="Origem">{f2.cidade_origem}</td>
                          <td data-title="Destino">{f2.cidade_destino}</td>
                          <td data-title="Qtde">{f2.qtde}</td>
                          <td data-title="Carga Horária">
                            R${" "}
                            {f2.valor_deslocamento.toLocaleString("pt-br", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : null}
            </React.Fragment>
          ))}
      </Container>
    </Background>
  );
}
