import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Select,
  Label,
} from "../../ModalEditStyle";
import api from "../../../../services/api";
import Swal from "sweetalert2";

export default function ModalChangeCoord({ showModal, setShowModal, usuario }) {
  const modalRef = useRef();
  const [email, setEmail] = useState("");
  const [perfil, setPerfil] = useState("");
  const [nome, setNome] = useState("");
  const [matricula, setMatricula] = useState("");
  const [data_inicio, setData_inicio] = useState("");
  // const [data_fim, setData_fim] = useState("");
  const [id, setId] = useState("");
  const [colaboradores, setColaboradores] = useState([]);

  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (!showModal) {
        return;
      }
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    if (!showModal) {
      return;
    }
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress, showModal]);

  useEffect(() => {
    if (!showModal) {
      return;
    }
    if (usuario) {
      setNome(usuario.nome);
      setMatricula(usuario.matricula);
      setPerfil(usuario.perfil);
      setEmail(usuario.email);
      setId(usuario.id_usuario);
      setData_inicio(new Date().toISOString().split("T")[0]);
      // setData_fim(usuario.data_fim && usuario.data_fim?.split("T")[0]);
    }
  }, [usuario, showModal]);

  useEffect(() => {
    if (!showModal) {
      return;
    }
    api
      .get(`/rh/contributors?inativo=0`)
      .then((response) => {
        setColaboradores(response.data.colaboradores);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [showModal]);

  async function handleSubmit(e) {
    e.preventDefault();
    await Swal.fire({
      title: "Trocar Coordenador",
      text: "Deseja trocar coordenador? ",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          id,
          nome,
          matricula_anterior: usuario.matricula,
          matricula,
          email,
          perfil,
          data_inicio,
          troca: true,
        };
        try {
          if (new Date(data_inicio) < new Date()) {
            return Swal.fire(
              "Atenção!",
              `O início da coordenação deve ser depois de ${
                new Date().toLocaleDateString("pt-br").split("T")[0]
              }`,
              "warning"
            );
          }
          const response = await api.patch(`/rh/update-coord`, { data });
          if (response.data.error) {
            return Swal.fire("Atenção!", response.data.error, "warning");
          }
          Toast.fire({
            icon: "success",
            title: response.data.success || "Trocado com sucesso!",
          });
          setShowModal(!showModal);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } catch (error) {
          alert(
            error
          ); /*Swal('Atenção!', 'Serviço indisponível no momento', 'error')*/
        }
      }
    });
  }
  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Trocar Coordenação</h3>
                <FormContent>
                  <Label>Coordenador Atual:</Label>
                  <Input
                    type="text"
                    value={usuario?.nome}
                    placeholder="nome"
                    disabled={true}
                  />
                  <Label>Novo Coordenador:</Label>
                  <Select
                    value={nome + "|" + matricula}
                    placeholder="Perfil"
                    onChange={(e) => {
                      console.log(e.target.value);
                      console.log(e.target.value.split("|")[0].toUpperCase());
                      console.log(e.target.value.split("|")[1]);

                      setNome(e.target.value.split("|")[0].toUpperCase());
                      setMatricula(e.target.value.split("|")[1]);
                    }}
                    required
                  >
                    {colaboradores?.map((c) => (
                      <option
                        key={c.matricula}
                        value={c.nome + "|" + c.matricula}
                        matricula={c.matricula}
                        title={c.nome + " | matricula:" + c.matricula}
                      >
                        {c.nome}
                      </option>
                    ))}
                  </Select>
                  <Label>Início da Coordenação:</Label>
                  <Input
                    type="date"
                    value={data_inicio}
                    placeholder="Data inicio"
                    onChange={(e) => setData_inicio(e.target.value)}
                    required
                  />
                </FormContent>
                <br />
                <Button type="submit">Trocar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
