import { MdClose } from "react-icons/md";
import styled from "styled-components";

export const Contanier = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;
export const Card = styled.div`
  width: fit-content;
  padding: 0 20px 20px 20px;
  /* min-width: 40vw; */
  /* height: 76vh; */
  max-height: 90%;
  z-index: 99;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.5);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  padding-bottom: 0px;
  /* div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  } */
`;
export const Botton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 20px;
  padding-bottom: 0px;
`;

export const Button = styled.button`
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  padding: 5px 10px;
  margin: 0 10px 0 0;
  ${({ itens }) =>
    itens === true
      ? `
      opacity: 1;
      cursor: not-allowed;
      background-color: #1563cf80;
      `
      : `&:hover {
      background-color: #1563cf;
    }`}
`;

export const ContainerTable = styled.div`
  width: 100%;
  height: 500px; // Defina uma altura fixa para permitir a rolagem
  overflow-y: auto; // Permite rolagem apenas no corpo da tabela
  overflow-x: auto; // Permite rolagem horizontal se necessário
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-radius: 0.5rem;
  text-align: center;

  &::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background-color: #99d4f7;
    border-radius: 10rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--red-color);
    border-radius: 10rem;
  }
`;

export const Table = styled.table`
  width: 80rem; // Largura fixa
  min-width: 80rem;
  font-size: 0.75em;
  cursor: default;
  border-collapse: collapse; // Evita espaços entre as bordas
  text-align: center;
  align-content: center;

  thead {
    position: sticky;
    top: 0;
    background: white; // Mantém o fundo branco para evitar sobreposição visual
    z-index: 101;
    /* opacity: 0 */
  }

  tbody {
    width: 100%;
    overflow-y: auto;
    max-height: 350px; // Define um tamanho fixo para permitir a rolagem
  }

  th,
  td {
    padding: 5px 10px;
    border: 1px solid #000;
    white-space: nowrap; // Mantém o conteúdo em uma única linha
  }

  th {
    text-align: center;
    text-transform: capitalize;
    font-size: 18px;
    color: #000;
  }

  .erro {
    min-width: 200px;
    max-width: 200px;
    white-space: normal;
  }

  .unidade,
  .justificativa,
  .professor,
  .curso {
    min-width: 180px;
    max-width: 180px;
    white-space: normal;
  }

  .carga_horaria {
    white-space: normal;
    max-width: 80px;
  }
  
  .centro_custo,
  .cidade_origem,
  .cidade_oestino {
    min-width: 100px;
    max-width: 100px;
    white-space: normal;
  }

  tbody tr:hover {
    background-color: #bcbcbc;
  }
`;

export const Theader = styled.thead`
  height: 4rem;
  position: sticky;
  top: 0;
  background-color: #1a2e35;
  color: #ffffff;
  text-align: center;
  font-size: 1rem;
  z-index: 101;
  /* opacity: 0; */
`;
export const Tbody = styled.tbody`
  height: fit-content;
`;

export const Th = styled.th`
  padding: 2px 15px;
  text-align: center;
`;
export const Td = styled.td`
  padding: 2px 25px;
  text-align: center;
`;
export const TdUpload = styled.td`
  display: flex;
  align-items: center;
  padding: 20px 20px;
  text-align: start;
`;

export const Trbody = styled.tr`
  border-bottom: 1px solid #dddddd;
  height: 50px;
  max-height: 20%;
  overflow: scroll;
  &:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  transition: all 0.2s;

  &:hover {
    background-color: grey;
    color: white;
    transform: scale(1.01);
  }
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  width: 50px;
  height: 50px;
  color: #000; /* Cor padrão */

  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:hover {
    color: #1563cf; /* Azul moderno */
    transform: scale(1.2);
  }

  &:active {
    transform: scale(0.9); /* Efeito ao clicar */
  }
`;
