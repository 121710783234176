import React, { useState, useEffect } from "react";
import {
  Container,
  Background,
  Hr,
  Titulo,
  Button,
  Input,
  Row,
  Table,
} from "./style";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import ModalRegister from "../../../components/Modal/Coord/Disciplinas/Register";
import ModalEdit from "../../../components/Modal/Coord/Disciplinas/Edit";
import { IoPencilOutline, IoTrashOutline } from "react-icons/io5";
import { isAuthenticatedDiretor } from "../../../auth";

export default function Disciplinas() {
  const [filtro, setFiltro] = useState("");
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [disciplinas, setDisciplinas] = useState([]);
  const [disciplina, setDisciplina] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const diretor = isAuthenticatedDiretor();

  useEffect(() => {
    api
      .get(`/coord/subjects?nome=${filtro}`)
      .then((response) => {
        setDisciplinas(response.data.disciplinas);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [filtro]);

  useEffect(() => {
    setTotalPages(Math.ceil(disciplinas.length / 15));
  }, [disciplinas]);

  function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você não poderá reverter essa ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Deletar",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/coord/subjects/${id}`);
        if (response.data.error) {
          return Swal.fire(
            "Exclusão não permitida!",
            response.data.error,
            "warning"
          );
        }
        Toast.fire({
          icon: "success",
          title: "Deletado com sucesso!",
        });
        setDisciplinas(
          disciplinas.filter((disciplina) => disciplina.id !== id)
        );
      }
    });
  }

  return (
    <Background>
      <Container>
        <Titulo>Controle de Disciplinas</Titulo>
        <Hr />
        <Row>
          <Button
            hidden={diretor}
            onClick={() => {
              setShowModalRegister(true);
            }}
          >
            Novo
          </Button>
          <Input>
            <input
              type="text"
              placeholder="Pesquisar disciplina..."
              value={filtro}
              onChange={(e) => {
                setFiltro(e.target.value.toUpperCase());
                setPage(1);
              }}
            />
          </Input>
        </Row>
        <Table>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Carga Horária</th>
                <th>Insalubre</th>
                <th hidden={diretor}></th>
                <th hidden={diretor}></th>
              </tr>
            </thead>
            <tbody>
              {disciplinas.slice((page - 1) * 15, page * 15).map((d, i) => (
                <tr key={i}>
                  <td data-title="ID" value={d.id}>
                    {d.id}
                  </td>
                  <td data-title="Nome" value={d.nome} className="td-left">
                    {d.nome.toUpperCase()}
                  </td>
                  <td data-title="Carga Horária" value={d.carga_horaria}>
                    {d.carga_horaria}
                  </td>
                  <td data-title="Insalubre" value={d.insalubre}>
                    {d.insalubre === 1 ? "Sim" : "Não"}
                  </td>
                  <td data-title="Editar" hidden={diretor}>
                    <IoPencilOutline
                      title="Editar"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        setDisciplina(d);
                        setShowModalEdit(true);
                      }}
                    />
                  </td>
                  <td data-title="Excluir" hidden={diretor}>
                    <IoTrashOutline
                      title="Excluir"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        handleDelete(d.id);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
        <ModalRegister
          showModal={showModalRegister}
          setShowModal={setShowModalRegister}
        />
        <ModalEdit
          showModal={showModalEdit}
          setShowModal={setShowModalEdit}
          data={disciplina}
        />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
