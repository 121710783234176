import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Titulo, Hr, Button, Table, Row, Input, Background } from "./style";
import excel from "../../../assets/Images/excel.png";
import { IoPencilOutline, IoTrashOutline, IoSwapVerticalOutline } from "react-icons/io5";
import pdf from "../../../assets/Images/pdf.png";
import Auth from "../../../services/auth";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import ModalRegister from "../../../components/Modal/Coord/TurmasCoord/Register";
import ModalEdit from "../../../components/Modal/Coord/LancamentoTurmas/Edit";
import ModalHorarios from "../../../components/Modal/Coord/LancamentoTurmas/Horarios";
import { Link } from "react-router-dom";
import config from "../../../assets/json/texts.json";
import { isAuthenticatedDiretor } from "../../../auth";

const { STATIC_URL_DEV, STATIC_URL_PROD } = config;

export default function LancamentoTurmas() {
  const [auth] = useState(Auth.auth());
  const navigate = useNavigate();
  const [id] = useState(Auth.id());
  const [turmasGrade, setTurmasGrade] = useState([]);
  const [turmasGradeBK, setTurmasGradeBK] = useState([]);
  const [turmaGrade, setTurmaGrade] = useState([]);
  const [id_unidade] = useState(0);
  const [searchUnidades, setSearchUnidades] = useState("");
  const [searchCidades, setSearchCidades] = useState("");
  const [searchTurmas, setSearchTurmas] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalHorarios, setShowModalHorarios] = useState(false);
  const diretor = isAuthenticatedDiretor();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let filtro = turmasGradeBK.filter((turma) => turma.unidade.includes(searchUnidades));
    if (searchUnidades !== "") setTurmasGrade(filtro);
    else setTurmasGrade(turmasGradeBK);
  }, [searchUnidades]); // adicionar turmasGradeBK resolve a Note

  useEffect(() => {
    let filtro = turmasGradeBK.filter((turma) => turma.cidade.includes(searchCidades));
    if (searchCidades !== "") setTurmasGrade(filtro);
    else setTurmasGrade(turmasGradeBK);
  }, [searchCidades]); // adicionar turmasGradeBK resolve a Note
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    api
      .get(`/coord/lancamento?idCoord=${id}&idUnidade=${id_unidade}&nome=${searchTurmas}`)
      .then((response) => {
        setTurmasGrade(response.data.lancamento_grade);
        setTurmasGradeBK(response.data.lancamento_grade);
      })
      .catch(() => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [id, id_unidade, searchTurmas]);

  useEffect(() => {
    setTotalPages(Math.ceil(turmasGrade.length / 15));
  }, [turmasGrade]);

  function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você não poderá reverter essa ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Deletar",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/coord/lancamento/${id}`);
        if (response.data.error) {
          return Swal.fire("Exclusão não permitida!", response.data.error, "warning");
        }
        Toast.fire({
          icon: "success",
          title: "Deletado com sucesso!",
        });
        setTurmasGrade(turmasGrade.filter((turma) => turma.id !== id));
      }
    });
  }

  function handleExcel(idTurma) {
    Swal.fire({
      title: "Você tem certeza?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "blue",
      confirmButtonText: "Gerar Planilha",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        api
          .post(`/coord/turmasxlsx?idTurma=${idTurma}`)
          .then((response) => {
            if (response.data.error) {
              return Swal.fire("Erro!", response.data.error, "warning");
            }
            window.open(
              window.location.href.includes("localhost")
                ? `${STATIC_URL_DEV}/lancamentoxlsx?auth=${auth}`
                : `${STATIC_URL_PROD}/lancamentoxlsx?auth=${auth}`
            );
            Toast.fire({
              icon: "success",
              title: "Gerado com sucesso!",
            });
          })
          .catch(() => {
            Swal.fire({
              title: "Atenção!",
              text: "Não foi possível consultar os dados!",
              icon: "error",
            });
          });
      }
    });
  }

  return (
    <Background>
      <Container>
        <Titulo>Lançamento de Turmas</Titulo>
        <Hr />
        <Row>
          <Link to="/lanctoturmas" hidden={diretor}>
            <Button>Novo</Button>
          </Link>
          <Input>
            <input
              value={searchUnidades}
              onChange={(e) => {
                setSearchUnidades(e.target.value.toUpperCase());
              }}
              type="searchUnidades"
              placeholder="Unidade..."
            />
          </Input>
          <Input>
            <input
              value={searchCidades}
              onChange={(e) => {
                setSearchCidades(e.target.value.toUpperCase());
              }}
              type="searchCidades"
              placeholder="Cidade..."
            />
          </Input>
          <Input>
            <input
              value={searchTurmas}
              onChange={(e) => {
                setSearchTurmas(e.target.value.toUpperCase());
              }}
              type="searchTurmas"
              placeholder="Turma..."
            />
          </Input>
        </Row>
        <Table>
          <table>
            <thead>
              <tr>
                <th>ID da turma</th>
                <th>Unidade</th>
                <th>Cidade</th>
                <th>C.C.</th>
                <th>Turma</th>
                <th>Data início</th>
                <th>Data fim</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {turmasGrade.slice((page - 1) * 15, page * 15).map((t, i) => (
                <tr key={i}>
                  <td data-title="Id">{t.id_turma}</td>
                  <td title={t.unidade} data-title="Unidade" className="td-left">
                    {t.unidade.slice(0, 15)}
                    {t.unidade.length > 15 ? "..." : ""}
                  </td>
                  <td data-title="Cidade" className="td-left" title={t.cidade}>
                    {t.cidade.slice(0, 10)}
                    {t.cidade.length > 10 ? "..." : ""}
                  </td>
                  <td data-title="Centro de custo" title={t.centro_custo}>
                    {t.id_centro_custo}
                  </td>
                  <td data-title="Turma" title={t.turma} className="td-left">
                    {t.turma.replaceAll("TÉCNICO EM ", "").replaceAll("CURSO TÉCNICO ", "").replaceAll("TECNÓLOGO EM ", "").slice(0, 65)}
                    {t.turma.replaceAll("TÉCNICO EM ", "").replaceAll("CURSO TÉCNICO ", "").replaceAll("TECNÓLOGO EM ", "").length > 65
                      ? "..."
                      : ""}
                  </td>
                  <td data-title="Data início">{t.data_inicio}</td>
                  <td data-title="Data fim">{t.data_fim}</td>
                  <td data-title="Editar" hidden={diretor}>
                    <IoPencilOutline
                      title="Editar"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        setTurmaGrade(t);
                        // setShowModalHorarios(true);
                        navigate("/edicaohorarios", {
                          state: {
                            id: t.id,
                            id_unidade: t.id_unidade,
                            id_curso: t.id_curso,
                            id_centro_custo: t.id_centro_custo,
                            id_turma: t.id_turma,
                            data_inicio: t.data_inicio,
                            data_fim: t.data_fim,
                            turma: t.turma,
                            unidade: t.unidade,
                          },
                        });
                      }}
                    />
                  </td>
                  <td data-title="Excluir" hidden={diretor}>
                    <IoTrashOutline
                      title="Excluir"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        handleDelete(t.id);
                      }}
                    />
                  </td>
                  <td
                    data-title="PDF"
                    onClick={() => {
                      navigate("/pdfgradeturma", {
                        state: { idTurma: t.id_turma },
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={pdf} alt="Erro" />
                  </td>
                  <td>
                    <IoSwapVerticalOutline
                      title="PDF Trocas"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        // Implementar Tela
                        navigate("/professorestrocadospdf", {
                          state: { idLancamento: t.id },
                        });
                      }}
                    />
                  </td>
                  <td
                    data-title="Planilha"
                    onClick={() => {
                      handleExcel(t.id);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={excel} alt="Erro" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
        <ModalRegister showModal={showModalRegister} setShowModal={setShowModalRegister} />
        <ModalEdit
          showModal={showModalEdit}
          setShowModal={setShowModalEdit}
          data={turmaGrade}
          // lancamento={lancamentoTurma}
        />
        <ModalHorarios
          showModal={showModalHorarios}
          setShowModal={setShowModalHorarios}
          data={turmaGrade}
          // lancamento={lancamentoTurma}
        />
      </Container>
    </Background>
  );
}
