import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Select,
  Label,
} from "../../ModalRegisterStyle";
import api from "../../../../services/api";
import { Swal, Toast } from "../../../../services/SwalTemplates";

export default function ModalRegister({ showModal, setShowModal }) {
  const [id, setId] = useState(0);
  const [unidades, setUnidades] = useState([]);
  const [cursos] = useState([]);
  const [curso, setCurso] = useState("");
  const [unidade, setUnidade] = useState("");
  const [centroCusto] = useState("");
  const [centros_custo, setCentros_custo] = useState([]);
  const [id_turma] = useState(0);
  const [id_curso, setId_curso] = useState(0);
  const [id_unidade, setId_unidade] = useState(0);
  const [id_centro_custo, setId_centro_custo] = useState(0);
  const [data_inicio] = useState("");
  const [data_fim] = useState("");
  const [ano_semestre] = useState("");
  const [modulo, setModulo] = useState("");
  const modalRef = useRef();
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  function clear() {
    setId("");
    setUnidade("");
  }

  useEffect(() => {
    api
      .get(`/coord/classes`)
      .then((response) => {
        //    console.log('1111111111111111',response);
        setId(response.data.turmas.id);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/units`)
      .then((response) => {
        setUnidades(response.data.unidades);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/costcenter`)
      .then((response) => {
        setCentros_custo(response.data.centros_custo);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, []);

  /**
   * Vai servir para gerar o pdf do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      id: id,
      unidade: unidade,
      curso: curso,
      id_turma: id_turma,
      id_curso: id_curso,
      id_unidade: id_unidade,
      data_inicio: data_inicio,
      data_fim: data_fim,
      id_centro_custo: id_centro_custo,
      ano_semestre: ano_semestre,
      modulo: modulo,
    };
    try {
      const response = await api.post(`/coord/classes`, { data });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Swal.fire("Sucesso!", response.data.success, "success");
      Toast.fire({
        icon: "success",
        title: "Cadastrado com sucesso!",
      });
      setShowModal(false);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      alert(error);
    }
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
              clear();
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Cadastrar Grade Curricular</h3>
                <FormContent>
                  <Label>Id:</Label>
                  <Input
                    type="number"
                    value={id}
                    placeholder="Id"
                    onChange={(e) => setId(e.target.value)}
                  />
                  <Label>Unidade:</Label>
                  <Select
                    type="name"
                    value={unidade}
                    placeholder="Unidade"
                    onChange={(e) => {
                      setUnidade(e.target.value);
                      setId_unidade(
                        e.target[e.target.options.selectedIndex].id
                      );
                    }}
                  >
                    <option selected disabled value="">
                      Selecione a unidade
                    </option>
                    {unidades.map((u, i) => (
                      <option id={u.id}>{u.nome}</option>
                    ))}
                  </Select>
                  <Label>Curso:</Label>
                  <Select
                    type="name"
                    value={curso}
                    placeholder="Curso"
                    onChange={(e) => {
                      setCurso(e.target.value);
                      setId_curso(e.target[e.target.options.selectedIndex].id);
                    }}
                  >
                    <option selected disabled value="">
                      Selecione o curso
                    </option>
                    {cursos.map((c, i) => (
                      <option id={c.id}>{c.curso}</option>
                    ))}
                  </Select>
                  <Label>Centro de custo:</Label>
                  <Select
                    type="name"
                    value={centroCusto}
                    placeholder="Centro de custo"
                    onChange={(e) => {
                      setUnidade(e.target.value);
                      setId_centro_custo(
                        e.target[e.target.options.selectedIndex].id
                      );
                    }}
                  >
                    <option disabled value="">
                      {centros_custo.filter((c) => c.ativo === 1).length > 0
                        ? "Selecione o centro de custo"
                        : "Nenhum centro de custo encontado"}
                    </option>
                    {centros_custo.map((c, i) => {
                      return (
                        c.ativo === 1 && (
                          <option id={c.id} key={i}>
                            {c.nome}
                          </option>
                        )
                      );
                    })}
                  </Select>
                  <Label>Módulo:</Label>
                  <Input
                    type="text"
                    value={modulo.toUpperCase()}
                    placeholder="Módulo"
                    onChange={(e) => setModulo(e.target.value)}
                  />
                  <Label>Início:</Label>
                  <Input
                    type="date"
                    value={id}
                    placeholder="Id"
                    onChange={(e) => setId(e.target.value)}
                  />
                  <Label>Fim:</Label>
                  <Input
                    type="date"
                    value={id}
                    placeholder="Id"
                    onChange={(e) => setId(e.target.value)}
                  />
                </FormContent>
                <br />
                <Button type="submit">Cadastrar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                  clear();
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
