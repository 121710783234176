import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Select,
  Label,
  InputLabel,
  CheckBox,
  InputLabelCheckbox,
} from "./style";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import Auth from "../../../../services/auth";
import { IoMdCheckmark } from "react-icons/io";

export default function ModalEdit({ showModal, setShowModal, data }) {
  const modalRef = useRef();
  const [idCoord] = useState(Auth.id());
  const [unidades, setUnidades] = useState([]);
  const [unidade, setUnidade] = useState("");
  const [cursos, setCursos] = useState([]);
  const [curso, setCurso] = useState("");
  const [centros_custo, setCentros_custo] = useState([]);
  const [centro_custo, setCentro_custo] = useState("");
  const [carga_horaria, setCarga_horaria] = useState("");
  const [professores, setProfessores] = useState([]);
  const [professor, setProfessor] = useState("");
  const [id_professor, setId_professor] = useState(0);
  const [justificativa, setJustificativa] = useState("");
  const [data_inicio, setData_inicio] = useState("");
  const [data_fim, setData_fim] = useState("");
  const [id, setId] = useState("");
  const [idUnidade, setIdUnidade] = useState(0);
  const [id_centro_custo, setId_centro_custo] = useState(0);
  const [idCurso, setIdCurso] = useState(0);
  const [tipos_lancamentos, setTipos_lancamentos] = useState([]);
  const [tipo_lancamento, setTipo_lancamento] = useState("");
  const [id_tipo_lancamento, setId_tipo_lancamento] = useState(0);
  const [valorIda, setValorIda] = useState(0);
  const [valorVolta, setValorVolta] = useState(0);
  const [origemDestino, setOrigemDestino] = useState(0);
  const [quantidadeDeslocamentos, setQuantidadeDeslocamentos] = useState(0);
  const [deslocamentos, setDeslocamentos] = useState([]);
  const [valorIdaChecked, setValorIdaChecked] = useState(false);
  const [valorVoltaChecked, setValorVoltaChecked] = useState(false);
  const navigate = useNavigate();
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  // Load units and contributors on initial load
  useEffect(() => {
    if (!showModal) return;
    api
      .get(`/coord/units?idCoord=${idCoord}`)
      .then((response) => {
        setUnidades(response.data.unidades);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/contributors?inativo=0`)
      .then((response) => {
        setProfessores(response.data.colaboradores);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [idCoord, showModal]);

  // Load cost centers when unit changes
  useEffect(() => {
    if (!showModal) return;
    if (idUnidade) {
      api
        .get(`/coord/costcenter?idCoord=${idCoord}&idUnidade=${idUnidade}`)
        .then((response) => {
          setCentros_custo(response.data.centros_custo);
        })
        .catch((error) => {
          Swal.fire({
            title: "Atenção!",
            text: "Não foi possível consultar os dados!",
            icon: "error",
          });
        });
    }
  }, [idCoord, idUnidade, showModal]);

  // Load types when cost center and teacher changes
  useEffect(() => {
    if (!showModal) return;
    if (id_centro_custo && id_professor) {
      api
        .get(`/coord/tipos?id_centro_custo=${id_centro_custo}&matricula_colaborador=${id_professor}`)
        .then((response) => {
          setTipos_lancamentos(response.data.tipos_lancamentos);
        })
        .catch((error) => {
          Swal.fire({
            title: "Atenção!",
            text: "Não foi possível consultar os dados!",
            icon: "error",
          });
        });
    }
  }, [id_centro_custo, id_professor, showModal]);

  // Load courses when unit and cost center changes
  useEffect(() => {
    if (!showModal) return;
    if (idUnidade && id_centro_custo) {
      api
        .get(`/coord/courses?idCoord=${idCoord}&idUnidade=${idUnidade}&idCentroCusto=${id_centro_custo}`)
        .then((response) => {
          setCursos(response.data.cursos);
        })
        .catch((error) => {
          Swal.fire({
            title: "Atenção!",
            text: "Não foi possível consultar os dados!",
            icon: "error",
          });
        });
    }
  }, [idCoord, idUnidade, id_centro_custo, showModal]);

  // Load displacements when cost center changes
  useEffect(() => {
    if (!showModal) return;
    if (id_centro_custo) {
      api
        .get(`/coord/deslocamentos?id_centro_custo=${id_centro_custo}`)
        .then((response) => {
          setDeslocamentos(response.data.deslocamentos);
        })
        .catch((error) => {
          Swal.fire({
            title: "Atenção!",
            text: "Não foi possível consultar os dados!",
            icon: "error",
          });
        });
    }
  }, [id_centro_custo, showModal]);

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  // Preencher o formulário com dados quando o modal abre
  useEffect(() => {
    if (data) {
      setId(data.id);
      setIdUnidade(data.id_unidade);
      setUnidade(data.unidade);
      setCurso(data.curso);
      setIdCurso(data.id_curso);
      setCentro_custo(data.centro_custo);
      setId_centro_custo(data.id_centro_custo);
      setCarga_horaria(data.carga_horaria);
      setTipo_lancamento(data.tipo_lancamento);
      setId_tipo_lancamento(data.id_tipo_lancamento);
      setProfessor(data.professor);
      setId_professor(data.id_professor);
      setJustificativa(data.justificativa);
      setValorIda(data.valor_ida || 0);
      setValorVolta(data.valor_volta || 0);
      setValorIdaChecked(data.valor_ida ? true : false);
      setValorVoltaChecked(data.valor_volta ? true : false);
      setQuantidadeDeslocamentos(data.quantidade_deslocamentos || 0);

      // Set data_inicio and data_fim correctly
      if (data.data_inicio) {
        if (data.data_inicio.includes("/")) {
          // Handle date format DD/MM/YYYY
          const parts = data.data_inicio.split("/");
          setData_inicio(`${parts[2]}-${parts[1]}-${parts[0]}`);
        } else if (data.data_inicio.includes("-")) {
          // Already in YYYY-MM-DD format
          setData_inicio(data.data_inicio);
        } else {
          // Handle DD-MM-YYYY format with substring
          setData_inicio(
            data.data_inicio.substring(6, 10) + "-" + data.data_inicio.substring(3, 5) + "-" + data.data_inicio.substring(0, 2)
          );
        }
      }

      if (data.data_fim) {
        if (data.data_fim.includes("/")) {
          // Handle date format DD/MM/YYYY
          const parts = data.data_fim.split("/");
          setData_fim(`${parts[2]}-${parts[1]}-${parts[0]}`);
        } else if (data.data_fim.includes("-")) {
          // Already in YYYY-MM-DD format
          setData_fim(data.data_fim);
        } else {
          // Handle DD-MM-YYYY format with substring
          setData_fim(data.data_fim.substring(6, 10) + "-" + data.data_fim.substring(3, 5) + "-" + data.data_fim.substring(0, 2));
        }
      }
    }
  }, [data]);

  // Definir origem/destino após a deslocamentos está carregada e os dados estão disponíveis
  useEffect(() => {
    if (data && deslocamentos.length > 0) {
      const filteredDeslocamento = deslocamentos.find(
        (d) => d.id_cidade_origem === data.id_cidade_origem && d.id_cidade_destino === data.id_cidade_destino
      );

      if (filteredDeslocamento) {
        setOrigemDestino(filteredDeslocamento.id);
      }
    }
  }, [data, deslocamentos]);

  async function handleSubmit(e) {
    e.preventDefault();

    // Find the selected course's ID
    const selectedCourse = cursos.find((c) => c.curso === curso);
    const finalIdCurso = selectedCourse ? selectedCourse.id : idCurso;

    // Get origin/destination cities from selected displacement
    const selectedDeslocamento = deslocamentos.find((d) => d.id === origemDestino);

    const requestData = {
      id: id,
      id_unidade: idUnidade,
      id_tipo_lancamento: id_tipo_lancamento,
      id_colaborador: id_professor,
      id_curso: finalIdCurso,
      id_centro_custo: id_centro_custo,
      data_inicio: data_inicio,
      data_fim: data_fim,
      carga_horaria: carga_horaria,
      justificativa: justificativa,
      id_cidade_origem: selectedDeslocamento?.id_cidade_origem,
      id_cidade_destino: selectedDeslocamento?.id_cidade_destino,
      valor_ida: valorIdaChecked ? valorIda : 0,
      valor_volta: valorVoltaChecked ? valorVolta : 0,
      tem_ida: valorIdaChecked ? 1 : 0,
      tem_volta: valorVoltaChecked ? 1 : 0,
      qtde: quantidadeDeslocamentos,
    };

    try {
      const response = await api.patch(`/coord/events`, { data: requestData });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Toast.fire({
        icon: "success",
        title: "Editado com sucesso!",
      });
      setShowModal(false);
    } catch (error) {
      Swal.fire({
        title: "Erro!",
        text: "Ocorreu um erro ao atualizar o lançamento.",
        icon: "error",
      });
      console.error(error);
    }
  }

  return (
    <Background
      show={showModal}
      ref={modalRef}
      onClick={(e) => {
        if (modalRef.current === e.target) {
          setShowModal(false);
        }
      }}
    >
      <animated.div style={animation}>
        <ModalContent>
          <br />
          <Form onSubmit={handleSubmit}>
            <h3>Atualizar Lançamento Eventual</h3>
            <FormContent>
              <InputLabel>
                <Label>Unidade:</Label>
                <Select
                  value={unidade}
                  onChange={(e) => {
                    setUnidade(e.target.value);
                    const selectedOption = e.target.options[e.target.selectedIndex];
                    setIdUnidade(selectedOption.id);
                  }}
                  required
                >
                  <option value="" disabled>
                    Selecione uma unidade
                  </option>
                  {unidades.map((u, i) => (
                    <option id={u.id} key={i} value={u.nome}>
                      {u.nome}
                    </option>
                  ))}
                </Select>
              </InputLabel>
              <InputLabel>
                <Label>Centro de Custo:</Label>
                <Select
                  value={centro_custo}
                  onChange={(e) => {
                    setCentro_custo(e.target.value);
                    const selectedOption = e.target.options[e.target.selectedIndex];
                    setId_centro_custo(selectedOption.id);
                  }}
                  required
                >
                  <option selected value="" disabled>
                    {centros_custo.filter((c) => c.ativo === 1).length > 0
                      ? "Selecione o centro de custo"
                      : "Nenhum centro de custo encontrado"}
                  </option>
                  {centros_custo
                    .filter((c) => c.ativo === 1)
                    .map((c, i) => (
                      <option id={c.id} key={i} value={c.nome}>
                        {c.nome}
                      </option>
                    ))}
                </Select>
              </InputLabel>
              <InputLabel>
                <Label>Curso:</Label>
                <Select
                  value={curso}
                  onChange={(e) => {
                    setCurso(e.target.value);
                    const selectedOption = e.target.options[e.target.selectedIndex];
                    setIdCurso(selectedOption.id);
                  }}
                  required
                >
                  <option selected value="" disabled>
                    {cursos.length > 0 ? "Selecione um curso" : "Nenhum curso encontrado"}
                  </option>
                  {cursos.map((c, i) => (
                    <option key={i} id={c.id} value={c.curso}>
                      {c.curso}
                    </option>
                  ))}
                </Select>
              </InputLabel>
              <InputLabel>
                <Label>Professores:</Label>
                <Select
                  value={professor}
                  onChange={(e) => {
                    setProfessor(e.target.value);
                    const selectedOption = e.target.options[e.target.selectedIndex];
                    setId_professor(selectedOption.id);
                  }}
                  required
                >
                  <option selected value="" disabled>
                    Selecione um professor
                  </option>
                  {professores.map((p, i) => (
                    <option id={p.matricula} key={i} value={p.nome} title={p.nome + " | matricula:" + p.matricula}>
                      {p.nome}
                    </option>
                  ))}
                </Select>
              </InputLabel>
              <InputLabel>
                <Label>Tipo Lançamento:</Label>
                <Select
                  value={tipo_lancamento}
                  onChange={(e) => {
                    setTipo_lancamento(e.target.value);
                    const selectedOption = e.target.options[e.target.selectedIndex];
                    setId_tipo_lancamento(selectedOption.id);
                  }}
                  required
                >
                  <option value="" disabled>
                    {tipos_lancamentos.length > 0 && centros_custo.filter((c) => c.ativo === 1).length > 0
                      ? "Selecione um tipo de lançamento"
                      : "Nenhum tipo de lançamento encontrado"}
                  </option>
                  {tipos_lancamentos.length > 0 && centros_custo.filter((c) => c.ativo === 1).length > 0
                    ? tipos_lancamentos.map((t, i) => (
                        <option id={t.id} key={i} value={t.nome}>
                          {t.nome}
                        </option>
                      ))
                    : null}
                </Select>
              </InputLabel>
              <InputLabel
                style={{
                  display: tipo_lancamento?.toLowerCase().includes("deslocamento") ? "" : "none",
                }}
              >
                <Label>Origem / Destino:</Label>
                <Select
                  value={origemDestino}
                  onChange={(e) => {
                    setOrigemDestino(parseInt(e.target.value, 10));
                  }}
                >
                  <option value="0">Selecione origem/destino</option>
                  {deslocamentos.map((d, i) => (
                    <option key={i} value={d.id}>
                      {d.cidade_origem + " / " + d.cidade_destino}
                    </option>
                  ))}
                </Select>
              </InputLabel>
              <InputLabel>
                <Label>Data Início:</Label>
                <Input type="date" value={data_inicio} max={data_fim} onChange={(e) => setData_inicio(e.target.value)} required />
              </InputLabel>
              <InputLabel>
                <Label>Data Fim:</Label>
                <Input type="date" value={data_fim} min={data_inicio} onChange={(e) => setData_fim(e.target.value)} required />
              </InputLabel>
              <InputLabel
                style={{
                  display: tipo_lancamento?.toLowerCase().includes("deslocamento") ? "none" : "",
                }}
              >
                <Label>Carga Horária:</Label>
                <Input
                  type="text"
                  value={carga_horaria}
                  onChange={(e) => setCarga_horaria(e.target.value)}
                  onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
                  inputMode={"numeric"}
                  required={!tipo_lancamento?.toLowerCase().includes("deslocamento")}
                />
              </InputLabel>
              <InputLabel
                style={{
                  display: tipo_lancamento?.toLowerCase().includes("deslocamento") ? "" : "none",
                }}
              >
                <div style={{ display: "flex" }}>
                  <CheckBox
                    onClick={() => {
                      setValorIdaChecked(!valorIdaChecked);
                    }}
                  >
                    <IoMdCheckmark
                      style={{
                        transition: "all 0.2s ease-in-out",
                      }}
                      color={valorIdaChecked ? "var(--blue-color)" : "transparent"}
                    />
                  </CheckBox>
                  <Label>Valor Ida:</Label>
                </div>
                <Input
                  type="text"
                  value={valorIda}
                  onChange={(e) => setValorIda(e.target.value)}
                  onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
                  inputMode={"numeric"}
                  required={valorIdaChecked}
                  disabled={!valorIdaChecked}
                />
              </InputLabel>
              <InputLabel
                style={{
                  display: tipo_lancamento?.toLowerCase().includes("deslocamento") ? "" : "none",
                }}
              >
                <div style={{ display: "flex" }}>
                  <CheckBox
                    onClick={() => {
                      setValorVoltaChecked(!valorVoltaChecked);
                    }}
                  >
                    <IoMdCheckmark
                      style={{
                        transition: "all 0.2s ease-in-out",
                      }}
                      color={valorVoltaChecked ? "var(--blue-color)" : "transparent"}
                    />
                  </CheckBox>
                  <Label>Valor Volta:</Label>
                </div>
                <Input
                  type="text"
                  value={valorVolta}
                  onChange={(e) => setValorVolta(e.target.value)}
                  onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
                  inputMode={"numeric"}
                  required={valorVoltaChecked}
                  disabled={!valorVoltaChecked}
                />
              </InputLabel>
              <InputLabel
                style={{
                  display: tipo_lancamento?.toLowerCase().includes("deslocamento") ? "" : "none",
                }}
              >
                <Label>Quantidade:</Label>
                <Input
                  type="text"
                  value={quantidadeDeslocamentos}
                  onChange={(e) => setQuantidadeDeslocamentos(e.target.value)}
                  onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
                  inputMode={"numeric"}
                  required={tipo_lancamento?.toLowerCase().includes("deslocamento")}
                />
              </InputLabel>
              <InputLabel
                style={{
                  display: tipo_lancamento?.toLowerCase().includes("deslocamento") ? "none" : "",
                }}
              >
                <Label>Justificativa:</Label>
                <Input type="text" value={justificativa} onChange={(e) => setJustificativa(e.target.value)} />
              </InputLabel>
            </FormContent>
            <br />
            <Button type="submit">Atualizar</Button>
          </Form>
          <CloseModalButton
            onClick={() => {
              setShowModal(false);
            }}
          />
        </ModalContent>
      </animated.div>
    </Background>
  );
}
