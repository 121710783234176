import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import { Background, ModalContent, CloseModalButton, Form, FormContent, Button, Input, Label } from "../../ModalEditStyle";
import api from "../../../../services/api";
import Swal from "sweetalert2";

export default function ModalEdit({ showModal, setShowModal, data, setReload }) {
  const modalRef = useRef();
  const [nome, setNome] = useState("");
  const [ativo, setAtivo] = useState(null);
  const [id, setId] = useState("");

  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    if (!showModal) return;
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress, showModal]);

  useEffect(() => {
    if (!showModal) return;
    if (data) {
      setNome(data.nome);
      setId(data.id);
      setAtivo(data.ativo);
    }
  }, [data, showModal]);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = { nome, id, ativo };
    try {
      const response = await api.patch(`/rh/costcenter/${id}`, {
        data,
      });
      if (response.data.error) {
        return Swal.fire("Erro!", response.data.error, "error");
      }
      await Toast.fire({
        icon: "success",
        title: "Editado com sucesso!",
      });
      setReload(true);
      setShowModal(!showModal);
    } catch (error) {
      return Swal.fire("Erro!", error.response.data.error, "error");
    }
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
              // clear();
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Atualizar Centro de custo</h3>
                <FormContent>
                  <Label>Nome:</Label>
                  <Input type="name" value={nome} placeholder="Nome" onChange={(e) => setNome(e.target.value.toUpperCase())} required />
                  <div style={{ display: "flex", gap: 15, fontSize: 18 }}>
                    <div
                      onClick={() => setAtivo(1)}
                      style={{
                        display: "flex",
                        gap: 5,
                        cursor: "pointer",
                        color: ativo === 1 ? "#2b74e0" : "black",
                      }}
                    >
                      <label style={{ cursor: "pointer" }}>Ativo</label>
                      <input
                        style={{ cursor: "pointer" }}
                        type="radio"
                        id="ativo"
                        name="status"
                        value={1}
                        onChange={(e) => setAtivo(Number(e.target.value))}
                        checked={ativo === 1}
                      />
                    </div>
                    <div
                      onClick={() => setAtivo(0)}
                      style={{
                        display: "flex",
                        gap: 5,
                        cursor: "pointer",
                        color: ativo === 0 ? "#2b74e0" : "black",
                      }}
                    >
                      <label style={{ cursor: "pointer" }}>Inativo</label>
                      <input
                        style={{ cursor: "pointer" }}
                        type="radio"
                        id="inativo"
                        name="status"
                        value={0}
                        onChange={(e) => setAtivo(Number(e.target.value))}
                        checked={ativo === 0}
                      />
                    </div>
                  </div>
                </FormContent>
                <br />
                <Button type="submit">Atualizar</Button>
              </Form>
              <CloseModalButton
                onClick={() => {
                  setShowModal(!showModal);
                  // clear();
                }}
              />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
