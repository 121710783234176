import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import {
  Background,
  Container,
  Titulo,
  Hr,
  Form,
  InputLabel,
  BigButton,
  RowCenter,
  Data,
  Left,
  Table,
} from "./style";
import Auth from "../../../services/auth";
import { useNavigate } from "react-router-dom";
import GoBack from "../../../components/GoBack";
import Pagination from "../../../components/Pagination";
import TitlePagination from "../../../components/TitlePagination";
import Modal from "../../../components/Modal/Coord/RegisterGradesCurriculares";
import { IoTrashOutline, IoAddCircleOutline } from "react-icons/io5";

export default function RegisterGradesCurriculares() {
  const [id] = useState(Auth.id());
  const [cursos, setCursos] = useState([]);
  const [centros_custo, setCentros_custo] = useState([]);
  const [centro_custo, setCentro_custo] = useState("");
  const [id_curso, setId_curso] = useState(0);
  const [id_centro_custo, setId_centro_custo] = useState(0);
  const [curso, setCurso] = useState("");
  const [page, setPage] = useState(1);
  const [titlePage, setTitlePage] = useState(1);
  const [totalTitlePages, setTotalTitlePages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [modulo, setModulo] = useState("");
  const [disciplinasGrade, setDisciplinasGrade] = useState([]);
  const [defaultModulo, setDefaultModulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [modulos] = useState([
    "MOD/Série I",
    "MOD/Série II",
    "MOD/Série III",
    "MOD/Série IV",
    "MOD/Série V",
    "MOD/Série VI",
    "MOD/Série VII",
    "MOD/Série VIII",
    "MOD/Série IX",
    "MOD/Série X",
  ]);
  let navigate = useNavigate();

  useEffect(() => {
    api
      .get(`/coord/courses?idCoord=${id}`)
      .then((response) => {
        setCursos(response.data.cursos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/costcenter`)
      .then((response) => {
        setCentros_custo(response.data.centros_custo);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [id]);

  useEffect(() => {
    setTotalTitlePages(Math.ceil(disciplinasGrade.length / 1));
  }, [disciplinasGrade]);

  function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Essa ação irá retirar a disciplina da matriz!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--red-color)",
      confirmButtonText: "Deletar",
      cancelButtonColor: "gray",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setDisciplinasGrade(
          disciplinasGrade[titlePage - 1].dados.length === 1
            ? [
                ...disciplinasGrade.filter(
                  (d) => d.modulo !== disciplinasGrade[titlePage - 1].modulo
                ),
              ]
            : [
                ...disciplinasGrade.slice(0, titlePage - 1),
                {
                  modulo: disciplinasGrade[titlePage - 1].modulo,
                  dados: disciplinasGrade[titlePage - 1].dados.filter(
                    (d) => d.id_disciplina !== id
                  ),
                },
                ...disciplinasGrade.slice(titlePage),
              ]
        );
        setTitlePage(
          disciplinasGrade[titlePage - 1].dados.length === 1
            ? titlePage > 1
              ? titlePage - 1
              : titlePage
            : titlePage
        );
      }
    });
  }

  useEffect(() => {
    setTitlePage(disciplinasGrade.findIndex((o) => o.modulo === modulo) + 1);
  }, [disciplinasGrade, modulo]);

  function clear() {
    setCurso("");
    setCentro_custo("");
    setDisciplinasGrade([]);
    setPage(1);
    setTitlePage(1);
    setDescricao("");
  }

  async function handleSubmit() {
    /**
     * Impede de enviar uma grade com módulos na ordem errada
     */
    // if (disciplinasGrade.filter((d, i) => d.modulo !== modulos[i]).length > 0) {
    //   return Swal.fire({
    //     title: "Atenção",
    //     text: "Deve-se seguir a ordem dos módulos!",
    //     icon: "warning",
    //     confirmButtonColor: "var(--blue-color)",
    //   });
    // }

    let dados = [];
    disciplinasGrade?.map((d, i) =>
      d?.dados?.forEach((d2, i) => {
        dados?.push({
          id_disciplina: d2?.id_disciplina,
          modulo: d?.modulo,
        });
      })
    );
    const data = {
      id_curso: id_curso,
      id_centro_custo: id_centro_custo,
      descricao: descricao,
      dados: dados,
    };
    try {
      const response = await api.post(`/coord/grades`, {
        data,
      });
      if (response.data.error)
        return Swal.fire({
          title: "Atenção",
          text: response.data.error,
          icon: "warning",
          confirmButtonColor: "var(--blue-color)",
        });
      Toast.fire({
        icon: "success",
        title: "Cadastro de matriz gerado com sucesso!",
      });
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Background>
      <GoBack />
      <Container>
        <Titulo>Cadastro da Matriz Curricular</Titulo>
        <Hr />
        <Form>
          <Data>
            <Left>
              <input type="hidden" name="action" value="dados" />
              <InputLabel>
                <label>Curso</label>
                <select
                  value={curso}
                  onChange={(e) => {
                    setCurso(e.target.value);
                    setId_curso(e.target[e.target.options.selectedIndex].id);
                  }}
                  required
                >
                  <option disabled value="">
                    Selecione o curso
                  </option>
                  {cursos.map((c, i) => (
                    <option id={c.id} key={i}>
                      {c.curso}
                    </option>
                  ))}
                </select>
              </InputLabel>
              <InputLabel>
                <label>Centro de custo</label>
                <select
                  value={centro_custo}
                  onChange={(e) => {
                    setCentro_custo(e.target.value);
                    setId_centro_custo(
                      e.target[e.target.options.selectedIndex].id
                    );
                  }}
                  required
                >
                  <option selected disabled value="">
                    {centros_custo?.filter((c) => c.ativo === 1).length > 0
                      ? "Selecione o centro de custo"
                      : "Nenhum centro de custo encontado"}
                  </option>
                  {centros_custo?.map((c, i) => {
                    return (
                      c.ativo === 1 && (
                        <option id={c.id} key={i}>
                          {c.nome}
                        </option>
                      )
                    );
                  })}
                </select>
              </InputLabel>
              <InputLabel>
                <label>Descrição</label>
                <input
                  placeholder="Insira uma descrição"
                  type="text"
                  maxLength={30}
                  value={descricao}
                  onChange={(e) => {
                    setDescricao(e.target.value);
                  }}
                  required
                />
              </InputLabel>
              <br />
              <RowCenter>
                <BigButton
                  type="submit"
                  onClick={() => {
                    if (curso && centro_custo && descricao) {
                      setShowModal(true);
                    } else {
                      Swal.fire({
                        title: "Atenção!",
                        text: "Preencha todos os itens!",
                        icon: "warning",
                        confirmButtonColor: "var(--blue-color)",
                      });
                    }
                  }}
                >
                  Selecionar disciplinas
                </BigButton>
              </RowCenter>
            </Left>
          </Data>
        </Form>
        {disciplinasGrade
          .slice((titlePage - 1) * 1, titlePage * 1)
          .map((d, i) => (
            <React.Fragment key={i}>
              <Titulo style={{ marginTop: 40 }}>
                Disciplinas
                <IoAddCircleOutline
                  title="Adicionar Disciplina"
                  style={{
                    fontSize: "30px",
                    cursor: "pointer",
                    marginLeft: "15px",
                  }}
                  onClick={() => {
                    setDefaultModulo(modulos[titlePage - 1]);
                    setShowModal(true);
                  }}
                />
              </Titulo>
              <Hr />
              <TitlePagination
                page={titlePage}
                setPage={setTitlePage}
                totalPages={totalTitlePages}
                title={d.modulo}
              />
              <Table>
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Disciplina</th>
                      <th>Carga Horária</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {d.dados.slice((page - 1) * 10, page * 10).map((h, i) => (
                      <tr key={i}>
                        <td>{h.id_disciplina}</td>
                        <td>{h.disciplina}</td>
                        <td>{h.carga_horaria}</td>
                        <td>
                          <IoTrashOutline
                            title="Excluir"
                            style={{ fontSize: "25px", cursor: "pointer" }}
                            onClick={async () => {
                              handleDelete(h.id_disciplina);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th colSpan="2">Total</th>
                      <td>
                        <b>
                          {d.dados
                            .map((item) => item.carga_horaria)
                            .reduce((prev, next) => prev + next)}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Table>
              <Pagination
                page={page}
                setPage={setPage}
                totalPages={d.dados ? Math.ceil(d.dados.length / 10) : 1}
              />
            </React.Fragment>
          ))}
        <RowCenter hidden={disciplinasGrade.length === 0}>
          <BigButton
            type="submit"
            onClick={() => {
              handleSubmit();
            }}
          >
            Salvar
          </BigButton>
          <BigButton
            type="submit"
            style={{ backgroundColor: "var(--red-color)" }}
            onClick={() => {
              Swal.fire({
                title: "Você tem certeza?",
                text: "Essa ação irá retirar a disciplina da matriz!",
                icon: "warning",
                confirmButtonColor: "var(--red-color)",
                showCancelButton: true,
                confirmButtonText: "Deletar",
                cancelButtonColor: "gray",
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.isConfirmed) {
                  clear();
                }
              });
            }}
          >
            Cancelar
          </BigButton>
        </RowCenter>
      </Container>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        outputData={disciplinasGrade}
        setOutputData={setDisciplinasGrade}
        setTitlePage={setModulo}
        defaultModulo={defaultModulo}
        setDefaultModulo={setDefaultModulo}
      />
    </Background>
  );
}
