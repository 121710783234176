import { createGlobalStyle, css } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root{
        /* Cores globais, para usar chame assim: background-color: var(--red-color) */
        --red-color: #e8343d;
        --blue-color: #2B74E0;
    }
    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
        
        button{
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          -khtml-user-select: none;
        }
        /* font-weight: bolder; */

        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background-color: #99d4f7;
        }
          &::-webkit-scrollbar-thumb {
          background-color: var(--red-color);
          border-radius: 1rem;
        }
    }
    body{
        /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
        font-family: Arial, sans-serif;
    }
    /* CSS DAS TABELAS */
    table {
    color: black;
    font-size: 15px;
    border: 1px solid whitesmoke;
    width: 100%;
    text-align: center;
    border-collapse: collapse;

    caption{
      font-weight: bold;
      font-size: 22px;
      margin-bottom: 10px;
    }

    thead {
      font-weight: bolder;
      height: 35px;
      font-weight: bolder;
      background: whitesmoke;
      border: 1px solid;

      @media screen and (max-width: 900px) {
        display: none;
      }
    }

    td.td-left {
      @media (min-width: 900px) {
        text-align: left;
        padding-left: 5px;
      } 
    }

    tr {
      @media screen and (max-width: 900px) {
        border-bottom: 18px solid white;
      }
    }

    tr:nth-child(even) {
      background: #f3f3f3;

      @media print {
        background: #f3f3f3 !important;
        -webkit-print-color-adjust: exact; 
      }
    }

    td {
      border: 1px solid black;
      height: 35px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: 900px) {
        font-weight: lighter;
        display: block;
        width: 100%;
        text-align: right;
        padding-right: 3%;
        padding-top: 1%;
        position: relative;

        &:before {
          content: attr(data-title);
          position: absolute;
          left: 0;
          width: fit-content;
          height: 100%;
          padding-left: 15px;
          font-size: 15px;
          font-weight: bold;
          text-transform: uppercase;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

export default GlobalStyle;

export const Tooltip = css`
  &::before,
  &::after {
    position: absolute;
    top: -0.25rem;
    left: 50%;
    transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--tooltip-scale));
    transition: 150ms transform;
    transform-origin: bottom center;
  }
  &::before {
    --translate-y: calc(-100% - var(--tooltip-arrow-size));
    content: attr(data-tooltip);
    font-size: 10px;
    color: #fff;
    padding: 5px;
    border-radius: var(--border-radius);
    text-align: center;
    width: max-content;
    max-width: 100%;
    background: var(--tooltip-color);
  }
  &::after {
    --translate-y: calc(-1 * var(--tooltip-arrow-size));
    content: "";
    border: var(--tooltip-arrow-size) solid transparent;
    border-top-color: var(--tooltip-color);
    transform-origin: top center;
  }
  &:hover::before,
  &:hover::after {
    --tooltip-scale: 1;
  }
`;
