import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import {
  Background,
  Container,
  Titulo,
  Hr,
  Button,
  Form,
  InputLabel,
} from "../Fechamento/style.js";
import Auth from "../../../services/auth";
import { Swal, Toast } from "../../../services/SwalTemplates";

export default function Fechar() {
  const [nomeCoord] = useState(Auth.nome());
  const [unidades, setUnidades] = useState([]);
  const [unidade, setUnidade] = useState(0);
  const [centros_custo, setCentros_custo] = useState([]);
  const [centro_custo, setCentro_custo] = useState(0);
  const [professores, setProfessores] = useState([]);
  const [professor, setProfessor] = useState(0);
  const [tipos_lancamentos, setTipo_lancamentos] = useState([]);
  const [tipo_lancamento, setTipo_lancamento] = useState(0);
  const [data_inicio, setData_inicio] = useState("");
  const [data_fim, setData_fim] = useState("");
  const [carga_horaria, setCarga_horaria] = useState("");
  const [justificativa, setJustificativa] = useState("");

  useEffect(() => {
    api
      .get(`/coord/units`)
      .then((response) => {
        setUnidades(response.data.unidades);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/costcenter`)
      .then((response) => {
        setCentros_custo(response.data.centros_custo);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/contributors?inativo=${false}`)
      .then((response) => {
        setProfessores(response.data.colaboradores);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/releases`)
      .then((response) => {
        setTipo_lancamentos(response.data.tipos_lancamentos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    const data = {
      id_unidade: unidade,
      id_tipo_lancamento: tipo_lancamento,
      id_centro_custo: centro_custo,
      id_colaborador: professor,
      data_inicio: data_inicio,
      data_fim: data_fim,
      carga_horaria: carga_horaria,
      justificativa: justificativa,
    };

    try {
      const response = await api.post(`/coord/events`, { data });
      if (response.data.error) return alert(response.data.error);
      Toast.fire({
        icon: "success",
        title: "Fechamento gerado com sucesso!",
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      alert(error);
    }
  }

  return (
    <Background>
      <Container>
        <Titulo>
          <h1>Lançamentos Eventuais</h1>
          <legend>{nomeCoord}</legend>
        </Titulo>
        <Hr />
        <Form>
          <form onSubmit={handleSubmit}>
            <InputLabel>
              <label>Unidade</label>
              <select
                value={unidade}
                onChange={(e) => setUnidade(e.target.value)}
                required
              >
                <option selected disabled value={0}>
                  Selecione a unidade
                </option>
                {unidades.map((u, i) => (
                  <option key={i} value={u.id}>
                    {u.nome}
                  </option>
                ))}
              </select>
            </InputLabel>
            <InputLabel>
              <label>Tipo de lançamento</label>
              <select
                value={tipo_lancamento}
                onChange={(e) => setTipo_lancamento(e.target.value)}
                required
              >
                <option selected disabled value={0}>
                  Selecione o lançamento
                </option>
                {tipos_lancamentos.map((t, i) => (
                  <option key={i} value={t.id}>
                    {t.nome}
                  </option>
                ))}
              </select>
            </InputLabel>
            <InputLabel>
              <label>Centro custo</label>
              <select
                value={centro_custo}
                onChange={(e) => setCentro_custo(e.target.value)}
                required
              >
                <option selected disabled value={0}>
                  {centros_custo?.filter((c) => c.ativo === 1).length > 0
                    ? "Selecione o centro de custo"
                    : "Nenhum centro de custo encontado"}
                </option>
                {centros_custo?.map((c, i) => {
                  return (
                    c.ativo === 1 && (
                      <option id={c.id} key={i}>
                        {c.nome}
                      </option>
                    )
                  );
                })}
              </select>
            </InputLabel>
            <InputLabel>
              <label>Professor</label>
              <select
                value={professor}
                onChange={(e) => setProfessor(e.target.value)}
                required
              >
                <option selected disabled value={0}>
                  Selecione o professor
                </option>
                {professores.map((p, i) => (
                  <option key={i} value={p.matricula} title={p.matricula}>
                    {p.nome}
                  </option>
                ))}
              </select>
            </InputLabel>
            <br />
            <label>Início</label>
            <input
              type="date"
              name="date"
              id=""
              value={data_inicio}
              onChange={(e) => setData_inicio(e.target.value)}
            />
            <label>Fim</label>
            <input
              type="date"
              name="date"
              id=""
              value={data_fim}
              onChange={(e) => setData_fim(e.target.value)}
            />
            <br />
            <br />
            <InputLabel>
              <label>Carga horária total</label>
              <input
                type="number"
                name="number"
                id=""
                value={carga_horaria}
                onChange={(e) => setCarga_horaria(e.target.value)}
              />
            </InputLabel>
            <InputLabel>
              <label>Justificativa</label>
              <input
                type="textarea"
                name="textarea"
                value={justificativa}
                onChange={(e) => setJustificativa(e.target.value)}
                id=""
              />
            </InputLabel>
            <br />
            <Button>Salvar</Button>
            <Button>Cancelar</Button>
          </form>
        </Form>
      </Container>
    </Background>
  );
}
