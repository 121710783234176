import React, { useState, useEffect } from "react";
import { Container, Background, Hr, Titulo, Button, Input, Row, Table } from "./style";
import { IoPencilOutline, IoTrashOutline } from "react-icons/io5";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import ModalRegister from "../../../components/Modal/Rh/Colaboradores/Register.js";
import ModalEdit from "../../../components/Modal/Rh/Colaboradores/Edit.js";

export default function Colaboradores() {
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [colaboradores, setColaboradores] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchMatricula, setSearchMatricula] = useState("");
  const [searchVinculo, setSearchVinculo] = useState("");
  const [colaborador, setColaborador] = useState("");
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    api
      .get(`/rh/contributors?nome=${search}&vinculo=${searchVinculo}&matricula=${searchMatricula}`)
      .then((response) => {
        setColaboradores(response.data.colaboradores);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [search, searchVinculo, searchMatricula]);

  useEffect(() => {
    setTotalPages(Math.ceil(colaboradores.length / 15));
  }, [colaboradores]);

  async function handleDelete(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "O colaborador será excluído permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Excluir",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await api.delete(`/rh/contributors/${id}`);
        if (response.data.error) {
          return Swal.fire("Exclusão não permitida!", response.data.error, "warning");
        }
        Toast.fire({
          icon: "success",
          title: "Excluído com sucesso!",
        });
        setColaboradores(colaboradores.filter((colaborador) => colaborador.matricula !== id));
      }
    });
  }

  return (
    <Background>
      <Container>
        <Titulo>Controle de Colaboradores</Titulo>
        <Hr />
        <Row>
          <Button
            onClick={() => {
              setShowModalRegister(true);
            }}
          >
            Novo
          </Button>
          <Input>
            <input
              value={searchMatricula}
              onChange={(e) => {
                setSearchMatricula(e.target.value);
                setPage(1);
              }}
              type="search"
              onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
              placeholder="Pesquisar matricula..."
            />
          </Input>
          <Input>
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value.toUpperCase());
                setPage(1);
              }}
              type="search"
              placeholder="Pesquisar colaborador..."
            />
          </Input>
          <Input>
            <input
              value={searchVinculo}
              onChange={(e) => {
                setSearchVinculo(e.target.value.toUpperCase());
                setPage(1);
              }}
              type="search"
              placeholder="Pesquisar vinculo..."
            />
          </Input>
        </Row>
        <Table>
          <table>
            <thead>
              <tr>
                <th>Matrícula</th>
                <th>Nome</th>
                <th>Vínculo</th>
                <th>Apelido</th>
                <th>Status</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {colaboradores.slice((page - 1) * 15, page * 15).map((c, i) => (
                <tr key={i} style={{ opacity: c.inativo === 1 && 0.2 }}>
                  <td data-title="Matrícula" value={c.matricula}>
                    {c.matricula}
                  </td>
                  <td data-title="Nome" value={c.nome} className="td-left">
                    {c.nome}
                  </td>
                  <td data-title="Vínculo" value={c.vinculo} className="td-left">
                    {c.vinculo}
                  </td>
                  <td data-title="apelido" value={c.apelido} className="td-left">
                    {c.apelido}
                  </td>
                  <td data-title="Ativo">
                    {c.inativo === 0 ? "Ativo" : "Inativo"}
                  </td>
                  <td data-title="Editar">
                    <IoPencilOutline
                      title="Editar"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={() => {
                        setColaborador(c);
                        setShowModalEdit(true);
                      }}
                    />
                  </td>
                  <td data-title="Excluir">
                    <IoTrashOutline
                      title="Excluir"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={async () => {
                        await handleDelete(c.matricula);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table>
        <ModalRegister showModal={showModalRegister} setShowModal={setShowModalRegister} />
        <ModalEdit showModal={showModalEdit} setShowModal={setShowModalEdit} data={colaborador} />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
