import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  isAuthenticatedAdmin,
  isAuthenticatedCoordenador,
  isAuthenticatedRh,
  isAuthenticatedSecretaria,
  isAuthenticatedDev,
  isAuthenticatedDiretor,
} from "../src/auth";
import Navbar from "./components/Navbar";
import FloatingMenu from "./components/FloatingMenu";

// Rotas públicas
import Index from "./pages/Index";

// Rotas privadas de diretores
import CoordenadoresDir from "./pages/Diretor/Coordenadores/index.js";

// Rotas privadas de coordenador
import Coord from "./pages/Coordenador/Home";
import LancamentoTurmas from "./pages/Coordenador/LancamentoTurmas/index";
import LancamentoTurma from "./pages/Coordenador/RegisterLancamentoTurmas/";
import TurmasGrade from "./pages/Coordenador/LancamentoGradeTurmas/index";
import TurmasCoord from "./pages/Coordenador/RegisterLancamentoGradeTurmas";
import Eventuais from "./pages/Coordenador/Eventuais/index";
import RegisterEventuais from "./pages/Coordenador/RegisterEventuais/";
import FecharCoord from "./pages/Coordenador/Fechamento";
import LancarCoord from "./pages/Coordenador/RegisterCursos";
import PdfGradeTurma from "./pages/Coordenador/PdfGradeTurma";
import PdfFechamentoCoord from "./pages/Coordenador/PdfFechamentoProfessores";
// Tela que foi substituída pela outra TrocaProfessor, e não sabemos para que serve essa
// import TrocaProfessor from "./pages/Coordenador/TrocaProfessor";
import TrocaProfessorDetalhe from "./pages/Coordenador/TrocaProfessorDetalhe";
import RegisterGrades from "./pages/Coordenador/RegisterGrades";
import ListTurmas from "./pages/Coordenador/Turmas";
import ListGrades from "./pages/Coordenador/Grades";
import GradesCurriculares from "./pages/Coordenador/GradesCurriculares";
import RegisterGradesCurriculares from "./pages/Coordenador/RegisterGradesCurriculares/index";
import EditGradesCurriculares from "./pages/Coordenador/EditGradesCurriculares/index";
import RegisterCursos from "./pages/Coordenador/RegisterCursos";
import EdicaoHorarios from "./pages/Coordenador/EdicaoHorarios";
import ConsultaCoord from "./pages/Coordenador/Consulta/";
import PdfProfessoresTrocados from "./pages/Coordenador/PdfProfessoresTrocados/index";

// Rotas privadas de rh
import Rh from "./pages/Rh/Home";
import RegrasRh from "./pages/Rh/Regras";
import FecharRhProfessores from "./pages/Rh/Fechamento/Professores";
import FecharRhCoordenadores from "./pages/Rh/Fechamento/Coordenadores";
import Usuarios from "./pages/Rh/Usuarios/index";
import DefinicaoCoord from "./pages/Rh/DefineCoord/";
import Deslocamentos from "./pages/Rh/Deslocamentos/";
import RegisterCusto from "./pages/Rh/CentrosCusto";
import Colaboradores from "./pages/Rh/Colaboradores/";
import Lancamentos from "./pages/Rh/Lancamentos/";
import Consulta from "./pages/Rh/Consulta";
import Eventos from "./pages/Rh/Eventos";
import FechamentoPDF from "./pages/Rh/PdfFechamento";
import NovoPdfFechamentoRH from "./pages/Rh/NovoPdfFechamento/index.js";
import RhTurmas from "./pages/Rh/Turmas";
import Coordenadores from "./pages/Rh/Coordenadores/index.js";

// Rotas privadas de secretaria
import Sec from "./pages/Secretaria/Home";
import Cursos from "./pages/Secretaria/Cursos";
import Disciplinas from "./pages/Coordenador/Disciplinas/index.js";
import Turnos from "./pages/Secretaria/Turno/index.js";
import Unidades from "./pages/Secretaria/Unidades/";
import ExportarSec from "./pages/Secretaria/Exportar";

// Rotas privadas do dev
import Logs from "./pages/Dev/Logs";
import HomeDev from "./pages/Dev/Home";
import RegrasDev from "./pages/Dev/Regras";

// PageNotFound
import PageNotFound from "./pages/PageNotFound";

const ProtectedRoute = ({ element, allowedRoles }) => {
  const isAuthenticated =
    (allowedRoles.includes("admin") && isAuthenticatedAdmin()) ||
    (allowedRoles.includes("coordenador") && isAuthenticatedCoordenador()) ||
    (allowedRoles.includes("rh") && isAuthenticatedRh()) ||
    (allowedRoles.includes("secretaria") && isAuthenticatedSecretaria()) ||
    (allowedRoles.includes("dev") && isAuthenticatedDev()) ||
    (allowedRoles.includes("diretor") && isAuthenticatedDiretor());

  return isAuthenticated ? element : <Navigate to="/" replace />;
};

export default function RoutesConfig() {
  // const PublicRoute = ({ component: Component, ...rest }) => (
  //   <Route {...rest} render={(props) => <Component {...props} />} />
  // );

  // const PrivateRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     render={(props) =>
  //       isAuthenticatedCoordenador() ||
  //       isAuthenticatedAdmin() ||
  //       isAuthenticatedRh() ||
  //       isAuthenticatedDev() ||
  //       isAuthenticatedSecretaria() ? (
  //         <Component {...props} />
  //       ) : (
  //         <Navigate to={{ pathname: "/", state: { from: props.location } }} />
  //       )
  //     }
  //   />
  // );

  // const AdminRoute = ({ component: Component, ...rest }) => (
  //     <Route {...rest} render={props => (
  //         isAuthenticatedAdmin() ? (
  //             <Component {...props} />
  //         ) : (
  //             <Navigate to={{ pathname: '/', state: { from: props.location } }} />
  //         )
  //     )} />
  // )

  // const DevRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     render={(props) =>
  //       isAuthenticatedDev() ? (
  //         <Component {...props} />
  //       ) : (
  //         <Navigate to={{ pathname: "/", state: { from: props.location } }} />
  //       )
  //     }
  //   />
  // );

  // const CoordRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     render={(props) =>
  //       isAuthenticatedCoordenador() ||
  //       isAuthenticatedAdmin() ||
  //       isAuthenticatedDev() ||
  //       isAuthenticatedSecretaria() ||
  //       isAuthenticatedRh() ? (
  //         <Component {...props} />
  //       ) : (
  //         <Navigate to={{ pathname: "/", state: { from: props.location } }} />
  //       )
  //     }
  //   />
  // );

  // const RhRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     render={(props) =>
  //       isAuthenticatedRh() ||
  //       isAuthenticatedAdmin() ||
  //       isAuthenticatedDev() ? (
  //         <Component {...props} />
  //       ) : (
  //         <Navigate to={{ pathname: "/", state: { from: props.location } }} />
  //       )
  //     }
  //   />
  // );

  // const SecRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     render={(props) =>
  //       isAuthenticatedSecretaria() ||
  //       isAuthenticatedAdmin() ||
  //       isAuthenticatedDev() ||
  //       isAuthenticatedRh() ? (
  //         <Component {...props} />
  //       ) : (
  //         <Navigate to={{ pathname: "/", state: { from: props.location } }} />
  //       )
  //     }
  //   />
  // );

  // const DirRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     render={(props) =>
  //       isAuthenticatedDiretor() ||
  //       isAuthenticatedCoordenador() ||
  //       isAuthenticatedAdmin() ||
  //       isAuthenticatedDev() ||
  //       isAuthenticatedRh() ? (
  //         <Component {...props} />
  //       ) : (
  //         <Navigate to={{ pathname: "/", state: { from: props.location } }} />
  //       )
  //     }
  //   />
  // );

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Index />} />

        <Route
          path="/coord"
          element={
            <ProtectedRoute
              element={<Coord />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/gradeturmas"
          element={
            <ProtectedRoute
              element={<TurmasGrade />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/lancamentoturmas"
          element={
            <ProtectedRoute
              element={<LancamentoTurmas />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/edicaohorarios"
          element={
            <ProtectedRoute
              element={<EdicaoHorarios />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/pdfgradeturma"
          element={
            <ProtectedRoute
              element={<PdfGradeTurma />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/lancamentoturma"
          element={
            <ProtectedRoute
              element={<LancamentoTurma />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/turmascoord"
          element={
            <ProtectedRoute
              element={<TurmasCoord />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/lanctoturmas"
          element={
            <ProtectedRoute
              element={<RegisterCursos />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/trocaprofessordetalhe"
          element={
            <ProtectedRoute
              element={<TrocaProfessorDetalhe />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/eventuais"
          element={
            <ProtectedRoute
              element={<Eventuais />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/eventuaiscoord"
          element={
            <ProtectedRoute
              element={<RegisterEventuais />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/fechamentocoord"
          element={
            <ProtectedRoute
              element={<FecharCoord />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/lancamentocursos"
          element={
            <ProtectedRoute
              element={<LancarCoord />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/registergrades"
          element={
            <ProtectedRoute
              element={<RegisterGrades />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/turmas"
          element={
            <ProtectedRoute
              element={<ListTurmas />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/grades"
          element={
            <ProtectedRoute
              element={<ListGrades />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/gradescurriculares"
          element={
            <ProtectedRoute
              element={<GradesCurriculares />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/registergradescurriculares"
          element={
            <ProtectedRoute
              element={<RegisterGradesCurriculares />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/editgradescurriculares"
          element={
            <ProtectedRoute
              element={<EditGradesCurriculares />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/disciplinas"
          element={
            <ProtectedRoute
              element={<Disciplinas />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/consultacoord"
          element={
            <ProtectedRoute
              element={<ConsultaCoord />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/fechamentopdfcoord"
          element={
            <ProtectedRoute
              element={<PdfFechamentoCoord />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />
        <Route
          path="/professorestrocadospdf"
          element={
            <ProtectedRoute
              element={<PdfProfessoresTrocados />}
              allowedRoles={["coordenador", "admin", "dev", "secretaria", "rh"]}
            />
          }
        />

        <Route
          path="/aulaspordia"
          element={
            <ProtectedRoute
              element={<Rh />}
              allowedRoles={["rh", "admin", "dev"]}
            />
          }
        />
        <Route
          path="/rh"
          element={
            <ProtectedRoute
              element={<FecharRhCoordenadores />}
              allowedRoles={["rh", "admin", "dev"]}
            />
          }
        />
        <Route
          path="/regrasrh"
          element={
            <ProtectedRoute
              element={<RegrasRh />}
              allowedRoles={["rh", "admin ", "dev"]}
            />
          }
        />
        <Route
          path="/fechamentorhprof"
          element={
            <ProtectedRoute
              element={<FecharRhProfessores />}
              allowedRoles={["rh", "admin", "dev"]}
            />
          }
        />
        <Route
          path="/usuarios"
          element={
            <ProtectedRoute
              element={<Usuarios />}
              allowedRoles={["rh", "admin ", "dev"]}
            />
          }
        />
        <Route
          path="/definicaocoord"
          element={
            <ProtectedRoute
              element={<DefinicaoCoord />}
              allowedRoles={["rh", "admin", "dev"]}
            />
          }
        />
        <Route
          path="/coordenadores"
          element={
            <ProtectedRoute
              element={<Coordenadores />}
              allowedRoles={["rh", "admin", "dev"]}
            />
          }
        />
        <Route
          path="/colaboradores"
          element={
            <ProtectedRoute
              element={<Colaboradores />}
              allowedRoles={["rh", "admin", "dev"]}
            />
          }
        />
        <Route
          path="/registercusto"
          element={
            <ProtectedRoute
              element={<RegisterCusto />}
              allowedRoles={["rh", "admin", "dev"]}
            />
          }
        />
        <Route
          path="/deslocamentos"
          element={
            <ProtectedRoute
              element={<Deslocamentos />}
              allowedRoles={["rh", "admin", "dev"]}
            />
          }
        />
        <Route
          path="/lancamentos"
          element={
            <ProtectedRoute
              element={<Lancamentos />}
              allowedRoles={["rh", "admin", "dev"]}
            />
          }
        />
        <Route
          path="/consulta"
          element={
            <ProtectedRoute
              element={<Consulta />}
              allowedRoles={["rh", "admin ", "dev"]}
            />
          }
        />
        <Route
          path="/eventos"
          element={
            <ProtectedRoute
              element={<Eventos />}
              allowedRoles={["rh", "admin", "dev"]}
            />
          }
        />
        <Route
          path="/fechamentopdfRH"
          element={
            <ProtectedRoute
              element={<FechamentoPDF />}
              allowedRoles={["rh", "admin", "dev"]}
            />
          }
        />
        <Route
          path="/fechamentopdfRH2"
          element={
            <ProtectedRoute
              element={<NovoPdfFechamentoRH />}
              allowedRoles={["rh", "admin", "dev"]}
            />
          }
        />
        <Route
          path="/rhturmas"
          element={
            <ProtectedRoute
              element={<RhTurmas />}
              allowedRoles={["rh", "admin ", "dev"]}
            />
          }
        />

        <Route
          path="/sec"
          element={
            <ProtectedRoute
              element={<Sec />}
              allowedRoles={["secretaria", "admin", "dev", "rh"]}
            />
          }
        />
        <Route
          path="/turnos"
          element={
            <ProtectedRoute
              element={<Turnos />}
              allowedRoles={["secretaria", "admin", "dev", "rh"]}
            />
          }
        />
        <Route
          path="/unidades"
          element={
            <ProtectedRoute
              element={<Unidades />}
              allowedRoles={["secretaria", "admin", "dev", "rh"]}
            />
          }
        />
        <Route
          path="/exportarsec"
          element={
            <ProtectedRoute
              element={<ExportarSec />}
              allowedRoles={["secretaria", "admin", "dev", "rh"]}
            />
          }
        />
        <Route
          path="/cursos"
          element={
            <ProtectedRoute
              element={<Cursos />}
              allowedRoles={["secretaria", "admin", "dev", "rh"]}
            />
          }
        />
        <Route
          path="/consultasec"
          element={
            <ProtectedRoute
              element={<Consulta />}
              allowedRoles={["secretaria", "admin", "dev", "rh"]}
            />
          }
        />

        <Route
          path="/dir"
          element={
            <ProtectedRoute
              element={<CoordenadoresDir />}
              allowedRoles={["diretor"]}
            />
          }
        />

        <Route
          path="/logs"
          element={<ProtectedRoute element={<Logs />} allowedRoles={["dev"]} />}
        />
        <Route
          path="/homedev"
          element={
            <ProtectedRoute element={<HomeDev />} allowedRoles={["dev"]} />
          }
        />
        <Route
          path="/regrasdev"
          element={
            <ProtectedRoute element={<RegrasDev />} allowedRoles={["dev"]} />
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <FloatingMenu />
    </BrowserRouter>
  );
}
