import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 1rem 0;

  button {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;
    border: 1px solid transparent;
    padding: 0.625rem 1.25rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    text-transform: uppercase;
    will-change: transform;
    letter-spacing: 0.025em;
    font-size: 0.875rem;
    color: #fff;
    background-color: var(--blue-color);
    border-color: #3664f4;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    transition: all 0.3s linear;
    &:hover {
      filter: brightness(90%);
      box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
      transform: translateY(-1px);
    }
    &:disabled {
      opacity: 50%;
      cursor: not-allowed;
    }
    svg {
      vertical-align: middle;
      margin-left: 0.1rem;
    }
  }

  small {
    opacity: 50%;
  }

  div {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
  }

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;

  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;
