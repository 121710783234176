import styled from "styled-components";
import { MdClose } from "react-icons/md";

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 23;
`;

export const Button = styled.button`
  margin-right: 5px;
  width: 180px;
  height: 45px;
  border-radius: 4px;
  background: #2b74e0;
  color: #fff;
  border: 2px;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  text-decoration: none;
  text-align: center;
  transition: linear 0.2s;
  &:hover {
    background-color: #1563cf;
  }
`;

export const Select = styled.select`
  border-radius: 5px;
  height: 30px;
  text-align: center;
  border: 2px solid black;
  margin-bottom: 20px;
`;

export const Label = styled.label``;

export const ModalContent = styled.div`
  max-width: 100%;
  height: 90%;
  margin: 0 auto;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  position: relative;
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;

export const Form = styled.form`
  width: 100%;
  text-align: center;

  h3 {
    margin-bottom: 2rem;
    text-transform: uppercase;
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;

  span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;

export const Input = styled.input`
  border-radius: 5px;
  width: 300px;
  height: 30px;
  margin-bottom: 20px;
  text-align: center;
  border: 2px solid black;
  caret-color: #0093e9;
`;

export const ToggleButton = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "active",
})`
  background-color: ${(props) => (props.active ? "var(--blue-color)" : "gray")};
  width: 44px;
  height: 25px;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.2s;
`;

export const Circle = styled.div`
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: transform 0.2s;
  transform: ${(props) =>
    props.active ? "translateX(100%)" : "translateX(0)"};
`;
