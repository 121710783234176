import React, { useState, useEffect } from "react";
import { Container, Background, Hr, Titulo, Button, Input, Row, Table } from "./style";
import api from "../../../services/api";
import { Swal, Toast } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import Auth from "../../../services/auth";
import config from "../../../assets/json/texts.json";

const { STATIC_URL_DEV, STATIC_URL_PROD } = config;

export default function Usuarios() {
  const [auth] = useState(Auth.auth());
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [professores, setProfessores] = useState([]);
  const [professor, setProfessor] = useState("");
  const [matricula, setMatricula] = useState(0);
  const [consulta, setConsulta] = useState([]);

  useEffect(() => {
    api
      .get(`/rh/contributors?inativo=${false}`)
      .then((response) => {
        setProfessores(response.data.colaboradores);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    api
      .get(`/rh/turmacolaborador?matricula=${matricula}`)
      .then((response) => {
        setConsulta(response.data.turma_colaborador[0]);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [matricula]);

  useEffect(() => {
    setTotalPages(Math.ceil(consulta.length / 15));
  }, [consulta]);

  async function handleExcel(matricula) {
    const response = await api.post(`/rh/contributorsxlsx?matricula=${matricula}`);
    if (response.data.error) {
      return Swal.fire("Criação não permitida!", response.data.error, "warning");
    }
    Toast.fire({
      icon: "success",
      title: "Gerado com sucesso!",
    });
    window.open(
      window.location.href.includes("localhost")
        ? `${STATIC_URL_DEV}/colaboradoreslancamento?auth=${auth}`
        : `${STATIC_URL_PROD}/colaboradoreslancamento?auth=${auth}`
    );
    // setCustos(custos.filter((custo) => custo.id !== id));
  }

  const cargas = consulta?.map((c) => c.carga) || 0;
  const totalhrs = cargas?.reduce((acc, curr) => acc + curr, 0);

  return (
    <Background>
      <Container>
        <Titulo>Consulta de Horários</Titulo>
        <Hr />
        <Row>
          <Input>
            <div style={{ display: "flex" }}>
              <select
                value={professor}
                onChange={(e) => {
                  setProfessor(e.target.value.toUpperCase());
                  setMatricula(e.target[e.target.options.selectedIndex].id);
                  setPage(1);
                }}
              >
                <option disabled value="">
                  Selecione o professor
                </option>
                {professores.map((p, i) => (
                  <option key={i} id={p.matricula} title={p.matricula}>
                    {p.nome}
                  </option>
                ))}
              </select>
              <Button
                onClick={() => {
                  handleExcel(matricula);
                }}
                style={{
                  cursor: "pointer",
                  opacity: 1,
                  pointerEvents: "auto",
                }}
              >
                Gerar planilha
              </Button>
            </div>
          </Input>
        </Row>
        <Table>
          <table style={{ border: 0 }}>
            <thead>
              <tr>
                <th>Dia Semana</th>
                <th>Unidade</th>
                {/* <th>Id Turma</th> */}
                <th>Turma</th>
                <th>Horário Início</th>
                <th>Horário Término</th>
                <th>Carga Horária</th>
              </tr>
            </thead>
            <tbody>
              {consulta.slice((page - 1) * 15, page * 15).map((c, i) => {
                return (
                  <tr key={i}>
                    <td data-title="Dia da semana" value={c.nome}>
                      {c.dia_semana}
                    </td>
                    <td data-title="Unidade" value={c.nome} title={c.id_unidade}>
                      {c.unidade}
                    </td>
                    {/* <td data-title="id_turma" value={c.id_turma} className="td-left" title={c.id_turma}>
                      {c.id_turma}
                    </td> */}
                    <td data-title="Turma" value={c.turma} className="td-left" title={c.id_turma}>
                      {c.turma
                        .replaceAll("TÉCNICO EM ", "")
                        .replaceAll("CURSO TÉCNICO ", "")
                        .replaceAll("TECNÓLOGO EM ", "")}
                    </td>
                    <td data-title="Horário Início" value={c.nome}>
                      {c.horario_inicio}
                    </td>
                    <td data-title="Horário Fim" value={c.nome}>
                      {c.horario_fim}
                    </td>
                    <td data-title="Carga Horária" value={c.nome}>
                      {c.carga}
                    </td>
                    {/* <td data-title="PDF">
                      <ion-icon
                        name="pencil-outline"
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={() => {
                        }}
                      />
                    </td> */}
                  </tr>
                );
              })}
              {consulta.length > 0 && (
                <tr>
                  <td style={{ border: 0, backgroundColor: "#fff", padding: 0 }} colSpan={4}></td>
                  <td>
                    <strong>Carga Horária Total:</strong>
                  </td>
                  <td>{totalhrs}</td>
                </tr>
              )}
            </tbody>
          </table>
        </Table>
        {/* <p>Carga horária: {carga_horaria}</p> */}
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
