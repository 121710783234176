import React, { useEffect } from "react";
import { Container } from "./styles";

export default function Pagination({ page, setPage, totalPages }) {
  useEffect(() => {
    setPage(totalPages >= 1 ? (totalPages < page ? page - 1 : page) : page);
  }, [page, setPage, totalPages]);

  return (
    <Container>
      <div>
        {page <= 1 ? (
          <button disabled>Anterior</button>
        ) : (
          <button type="button" onClick={() => setPage(page - 1)}>
            Anterior
          </button>
        )}
        {page > 5 && totalPages > 10 ? (
          <button type="button" onClick={() => setPage(page - 5)}>
            5 Anteriores
          </button>
        ) : (
          totalPages > 10 && <button disabled>5 Anteriores</button>
        )}
      </div>
      <small>
        Página {page} / {totalPages}
      </small>
      <div>
        {page < totalPages && totalPages > 10 && page + 5 <= totalPages ? (
          <button type="button" onClick={() => setPage(page + 5)}>
            Próximos 5
          </button>
        ) : (
          totalPages > 10 && <button disabled>Próximos 5</button>
        )}
        {page < totalPages ? (
          <button type="button" onClick={() => setPage(page + 1)}>
            Próximo
          </button>
        ) : (
          <button disabled>Próximo</button>
        )}
      </div>
    </Container>
  );
}
