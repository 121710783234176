import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import {
  Container,
  Background,
  Hr,
  Titulo,
  Input,
  Row,
  Table,
  Button,
} from "./style";
import api from "../../../services/api";
import { Swal } from "../../../services/SwalTemplates";
import Pagination from "../../../components/Pagination";
import { isAuthenticatedDev } from "../../../auth";
import { IoSwapVerticalOutline } from "react-icons/io5";
import ModalRegisterCoord from "../../../components/Modal/Rh/Coordenadores/Register";
import ModalChangeCoord from "../../../components/Modal/Rh/Coordenadores/Change";
import { RiArrowGoBackLine } from "react-icons/ri";
import { replace } from "formik";

export default function Coordenadores() {
  // const navigate = useNavigate();
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [showModalChangeCoord, setShowModalChangeCoord] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [usuario, setUsuario] = useState("");
  const [search, setSearch] = useState("");

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    api
      .get(`/rh/users?nome=${search}&coord=true`)
      .then((response) => {
        setUsuarios(response.data.usuarios);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [search]);

  useEffect(() => {
    setTotalPages(Math.ceil(usuarios.length / 15));
  }, [usuarios]);

  async function handleVoltarCoord(u, usuario2) {
    await Swal.fire({
      title: "Deseja voltar o(a) coordenador(a) anterior?",
      html: `
    <p>Isso fará com que <strong>${u.nome}</strong> volte a ser coordenador(a) e
    <strong>${usuario2.nome}</strong> não será mais coordenador(a)!</p>
    `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const data = {
            id: u.id,
            id2: usuario2.id,
            id_usuario: u.id_usuario,
            nome: u.nome,
            troca: true,
            voltar: true,
          };
          const response = await api.patch(`/rh/update-coord`, { data });
          Toast.fire({
            icon: "success",
            title: response.data.success || "Coordenador voltado com sucesso!",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } catch (error) {
          console.log(error);
          Swal.fire({
            title: "Atenção!",
            text: "Não foi possível excluir o coordenador!",
            icon: "error",
          });
        }
      }
    });
  }

  return (
    <Background>
      <Container>
        <Titulo>Controle de Coordenações</Titulo>
        <Hr />
        <Row>
          <Button
            onClick={() => {
              setShowModalRegister(true);
            }}
          >
            Novo
          </Button>
          <Input>
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value.toUpperCase());
                setPage(1);
              }}
              type="search"
              placeholder="Pesquisar nome..."
            />
          </Input>
        </Row>
        <Table>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>E-mail</th>
                <th>data inicio</th>
                <th>data fim</th>
                <th>Trocar</th>
                <th>Voltar</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.slice((page - 1) * 15, page * 15).map((u, i) => {
                const data_inicio = u?.data_inicio
                  ? u?.data_inicio?.substring(8, 10) +
                    "-" +
                    u?.data_inicio?.substring(5, 7) +
                    "-" +
                    u?.data_inicio?.substring(0, 4)
                  : "";

                const data_fim = u?.data_fim
                  ? u?.data_fim?.substring(8, 10) +
                    "/" +
                    u?.data_fim?.substring(5, 7) +
                    "/" +
                    u?.data_fim?.substring(0, 4)
                  : null;

                return (
                  <tr
                    key={i}
                    style={{
                      color: data_fim !== null && "#bcbcbc",
                      cursor: data_fim !== null && "not-allowed",
                    }}
                  >
                    <td data-title="ID" className="td-left">
                      {u.id_usuario}
                    </td>
                    <td data-title="Nome" value={u.nome} className="td-left">
                      {u.nome}
                    </td>
                    <td data-title="E-mail" value={u.email} className="td-left">
                      {u.email}
                    </td>
                    <td
                      data-title="Perfil"
                      value={u.perfil}
                      className="td-left"
                    >
                      {data_inicio}
                    </td>
                    <td
                      data-title="Perfil"
                      value={u.perfil}
                      className="td-left"
                    >
                      {new Date(u?.data_inicio) > new Date()
                        ? "Não Iniciado"
                        : data_fim || "Em Atividade"}
                    </td>
                    <td data-title="Trocar Coordenador" className="center">
                      <IoSwapVerticalOutline
                        title="Trocar Coordenador"
                        style={{
                          fontSize: "25px",
                          cursor: data_fim !== null ? "" : "pointer",
                        }}
                        onClick={async () => {
                          if (data_fim === null) {
                            setUsuario(u);
                            return setShowModalChangeCoord(true);
                          }
                        }}
                      />
                    </td>
                    <td
                      data-title="Voltar Coordenador"
                      className="center"
                      style={{
                        fontSize: "25px",
                        color: data_fim === null ? "#bcbcbc" : "#000",
                        cursor: data_fim === null ? "not-allowed" : "pointer",
                      }}
                    >
                      <RiArrowGoBackLine
                        title="Voltar Coordenador"
                        onClick={async () => {
                          if (data_fim !== null) {
                            // Filtrar usuários com o mesmo id_usuario
                            const usuariosComMesmoId = usuarios.filter(
                              (usuario) => usuario.id_usuario === u.id_usuario
                            );
                            const usuario2 = usuariosComMesmoId.filter(
                              (usuario) => u.id !== usuario.id && usuario.id
                            );
                            return handleVoltarCoord(u, usuario2[0]);
                          }
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Table>
        <ModalRegisterCoord
          showModal={showModalRegister}
          setShowModal={setShowModalRegister}
        />
        <ModalChangeCoord
          showModal={showModalChangeCoord}
          setShowModal={setShowModalChangeCoord}
          usuario={usuario}
        />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Container>
    </Background>
  );
}
