import { jwtDecode } from "jwt-decode";

export const getDecodedToken = () => {
  const token = localStorage.getItem("token");
  if (!token) return null;

  try {
    const decoded = jwtDecode(token);
    return decoded;
  } catch (error) {
    localStorage.clear();
    return null;
  }
};

export const isAuthenticated = () => {
  const decoded = getDecodedToken();
  return !!decoded;
};

export const isAuthenticatedCoordenador = () => {
  const decoded = getDecodedToken();
  return decoded?.perfil === "COORD";
};

export const isAuthenticatedRh = () => {
  const decoded = getDecodedToken();
  return decoded?.perfil === "RH";
};

export const isAuthenticatedSecretaria = () => {
  const decoded = getDecodedToken();
  return decoded?.perfil === "SEC";
};

export const isAuthenticatedAdmin = () => {
  const decoded = getDecodedToken();
  return decoded?.perfil === "ADM";
};

export const isAuthenticatedDev = () => {
  const decoded = getDecodedToken();
  return decoded?.perfil === "DEV";
};

export const isAuthenticatedDiretor = () => {
  const decoded = getDecodedToken();
  return decoded?.perfil === "DIR" || decoded?.perfil2 === "DIR";
};
