import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Background,
  Page,
  Subpage,
  Images,
  Content,
  Title,
  Table,
  Breaker,
  Assinaturas,
} from "./style";
import Prefeitura from "../../../assets/Images/prefeitura.jpg";
import Fiec from "../../../assets/Images/fiec.png";
import PdfButtons from "../../../components/PdfButtons";
import api from "../../../services/api";
import Auth from "../../../services/auth";
import { useNavigate } from "react-router-dom";
import { Swal } from "../../../services/SwalTemplates";

export default function PdfFechamentoCoord() {
  const [IdCoord] = useState(Auth.id());
  const [fechamentos, setFechamentos] = useState([]);
  const [coordResponsavel, setCoordResponsavel] = useState("");
  const [data_inicio, setData_inicio] = useState("");
  const [data_fim, setData_fim] = useState("");
  const [idCentroCusto, setIdcentroCusto] = useState(0);
  const [CentroCusto, setCentroCusto] = useState("");
  const [RepeatPages, setRepeatPages] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef();

  useEffect(() => {
    if (data_inicio !== "") {
      Swal.fire({
        title: "Aguarde!",
        text: "O relatório está sendo gerado!",
        icon: "info",
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      api
        .get(
          `/coord/fechamentopdfcoord?idUser=${IdCoord}&idCentroCusto=${idCentroCusto}&data_inicio=${new Date(
            data_inicio
          )?.toLocaleDateString("en-ZA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}&data_fim=${new Date(data_fim)?.toLocaleDateString("en-ZA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}`
        )
        .then((fechamentos) => {
          setFechamentos(fechamentos.data.fechamentos);
          setCoordResponsavel(fechamentos?.data?.nome_coord_responsavel_pdf);
          Swal.close();
        })
        .catch(async (error) => {
          Swal.close();
          const result_1 = await Swal.fire({
            title: "Atenção!",
            text: error?.response?.data?.error || "Não foi possível consultar!",
            icon: "warning",
            confirmButtonColor: "var(--blue-color)",
          });
          if (result_1.isConfirmed) {
            navigate(-1);
          }
        });
    }
  }, [IdCoord, data_fim, data_inicio, idCentroCusto, navigate]);

  useEffect(() => {
    if (ref.current.clientHeight > 1045) {
      setRepeatPages(Math.ceil((ref.current.clientHeight - 1045) / 1000) - 1);
    }
  }, [ref, fechamentos]);

  useEffect(() => {
    setIdcentroCusto(location?.state?.idCentroCusto);
    setCentroCusto(location?.state?.centroCusto);
  }, [location?.state]);

  useEffect(() => {    
    let dataInicio = new Date(location?.state?.data_inicio);
    let dataFim = new Date(location?.state?.data_fim);
    dataInicio.setDate(dataInicio.getDate() + 1);
    dataFim.setDate(dataFim.getDate() + 1);
    setData_inicio(dataInicio);
    setData_fim(dataFim);
  }, [location?.state?.data_fim, location?.state?.data_inicio]);

  return (
    <Background>
      <PdfButtons />
      <Page>
        <Subpage>
          <Breaker ref={ref}>
            <Images>
              <img src={Fiec} alt="Erro" />
              <img src={Prefeitura} alt="Erro" />
            </Images>
            <Content>
              <Title>Apuração de Carga Horária - {CentroCusto}</Title>

              <Title>
                Período de Apuração: &nbsp;
                {new Date(data_inicio).toLocaleDateString("pt-br", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
                &nbsp; a &nbsp;
                {new Date(data_fim).toLocaleDateString("pt-br", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}{" "}
              </Title>
              {fechamentos?.map((f, i) => (
                <React.Fragment key={i}>
                  <p>{f.colaborador}</p>
                  <Table style={{ tableLayout: "fixed" }}>
                    <thead>
                      <tr>
                        <th width="15%">EVENTO</th>
                        <th width="15%">CIDADE</th>
                        <th width="35%">ESCOLA</th>
                        <th width="25%">CURSO </th>
                        <th width="5%">AULAS</th>
                        <th width="5%">TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {f?.dados?.map((d, i) => (
                        <tr key={i}>
                          <td>{d.evento}</td>
                          <td>{d.cidade}</td>
                          <td>{d.unidade}</td>
                          <td>{d.curso}</td>
                          <td>{d.carga_horaria}</td>
                          <td>{d.carga_horaria2}</td>
                        </tr>
                      ))}
                      <tr>
                        <td style={{ borderRight: "none" }} colSpan={3}></td>
                        <td
                          style={{
                            borderLeft: "none",
                            borderRight: "none",
                            textAlign: "right",
                          }}
                        >
                          Total
                        </td>
                        <td style={{ borderLeft: "none" }}>
                          {/* {f.dados
                            .map((c, i) => c.carga_horaria)
                            .reduce(
                              (previousValue, currentValue) =>
                                previousValue + currentValue,
                              0
                            )} */}
                        </td>
                        <td>
                          {f.dados
                            .map((c, i) => c.carga_horaria2)
                            .reduce(
                              (previousValue, currentValue) =>
                                previousValue + currentValue,
                              0
                            )}
                        </td>
                      </tr>

                      {f?.dados
                        ?.filter((j) => j?.evento !== "HORAS NORMAIS")
                        ?.map((d, a) =>
                          d.justificativa?.split("|#|").map((e, i) => (
                            <tr key={a} style={{}}>
                              <td>{d?.evento}</td>
                              <td
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "2px",
                                }}
                                colSpan={4}
                              >
                                Just.: {e}
                              </td>
                            </tr>
                          ))
                        )}
                    </tbody>
                  </Table>

                  {f?.deslocamentos.length > 0 && (
                    <>
                      <p style={{ textAlign: "center", fontSize: "14px" }}>
                        Deslocamentos
                      </p>
                      <Table>
                        <thead>
                          <tr>
                            <th>EVENTO</th>
                            <th>ORIGEM</th>
                            <th>DESTINO</th>
                            <th>QTDE </th>
                            <th>VALOR</th>
                          </tr>
                        </thead>
                        <tbody>
                          {f?.deslocamentos?.map((des, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>{des?.evento}</td>
                                <td>{des?.cidade_origem}</td>
                                <td>{des?.cidade_destino}</td>
                                <td>{des?.qtde}</td>
                                <td> {des?.valor} </td>
                              </tr>
                            </React.Fragment>
                          ))}
                          <tr>
                            <td
                              style={{ borderRight: "none" }}
                              colSpan={3}
                            ></td>
                            <td
                              style={{
                                borderLeft: "none",
                                borderRight: "none",
                              }}
                            >
                              Total
                            </td>
                            <td style={{ borderLeft: "none" }}>
                              R$ &nbsp;
                              {f?.deslocamentos
                                ?.map((c, i) => c.valor)
                                ?.reduce(
                                  (previousValue, currentValue) =>
                                    previousValue + currentValue,
                                  0
                                )
                                ?.toLocaleString("pt-br", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </>
                  )}
                </React.Fragment>
              ))}
              <br />
              <br />
              <br />
              <br />
              <br />
              <Assinaturas>
                <div>
                  Coordenador
                  <br />
                  {coordResponsavel || ""}
                </div>
              </Assinaturas>
            </Content>
          </Breaker>
        </Subpage>
      </Page>
      {[...Array(RepeatPages)]?.map((e, i) => (
        <Page key={i} className="hidden">
          <Subpage></Subpage>
        </Page>
      ))}
    </Background>
  );
}
