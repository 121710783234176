import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Background,
  Container,
  Titulo,
  Hr,
  Button,
  Form,
  InputLabel,
  Input,
  BigButton,
} from "./style";
import api from "../../../../services/api";
import { Swal, Toast } from "../../../../services/SwalTemplates";
import TitlePagination from "../../../../components/TitlePagination";
import { BsDownload } from "react-icons/bs";
import Auth from "../../../../services/auth";
import config from "../../../../assets/json/texts.json";

const { STATIC_URL_DEV, STATIC_URL_PROD } = config;

export default function Fechar() {
  const navigate = useNavigate();
  const [auth] = useState(Auth.auth());
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [fechamentosTemp, setFechamentosTemp] = useState([]);
  const [page, setPage] = useState(1);
  const [titlePage, setTitlePage] = useState(1);
  const [totalTitlePages, setTotalTitlePages] = useState(1);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState(false);
  const [mes, setMes] = useState("");
  const [Coordenadores, setCoordenadores] = useState([]);
  const [regras, setRegras] = useState([]);

  useEffect(() => {
    setTitlePage(1);
    if (dataInicio && dataFim) {
      api
        .get(
          `/rh/fechamentotemp?colaborador=${search}&data_inicio=${dataInicio}&data_fim=${dataFim}`
        )
        .then((response) => {
          setFechamentosTemp(response.data.fechamentos);
        })
        .catch((error) => {
          Swal.fire({
            title: "Atenção!",
            text: "Não foi possível consultar os dados!",
            icon: "error",
          });
        });
    }
  }, [dataFim, dataInicio, search, searchData]);

  useEffect(() => {
    api
      .get(`/rh/coordfechamentotemp`)
      .then((response) => {
        setCoordenadores(response?.data?.dados);
        Swal.close();
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/regras?nome=${"CARGA HORARIA MINIMA MENSAL EFETIVO"}`)
      .then((response) => {
        if (response.data.regras) {
          setRegras(response.data.regras[0]);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [navigate]);

  useEffect(() => {
    setPage(1);
  }, [titlePage]);

  useEffect(() => {
    setTotalTitlePages(
      Math.ceil(fechamentosTemp?.length / 1) > 0
        ? Math.ceil(fechamentosTemp?.length / 1)
        : 1
    );
  }, [fechamentosTemp]);

  function carga(data) {
    let counter = 0;
    // eslint-disable-next-line array-callback-return
    data?.map((f, i) => {
      //console.log(f)
      //if(f.evento!='FALTA/ATRASO H/A')
      counter += f.carga_horaria;
    });
    return counter;
  }

  async function handleExport() {
    if (!mes) {
      return Swal.fire({
        title: "Atenção!",
        text: "Selecione um mês/ano abaixo!",
        icon: "warning",
        confirmButtonColor: "var(--blue-color)",
      });
    }
    api
      .get(`/rh/gerarhoras?mesAno=${mes}`)
      .then((response) => {
        if (response.data.error) {
          return Swal.fire("Atenção!", response.data.error, "warning");
        }
        Swal.fire({
          title: "Sucesso!",
          text: response.data.success,
          icon: "success",
        });
        Toast.fire({
          icon: "success",
          title: "Exportado com sucesso!",
        });
        window.open(
          window.location.href.includes("localhost")
            ? `${STATIC_URL_DEV}/horasfechamento?auth=${auth}`
            : `${STATIC_URL_PROD}/horasfechamento?auth=${auth}`
        );
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }

  async function handlePDF() {
    if (mes) {
      Swal.fire({
        title: "Aguarde!",
        text: "O relatório está sendo gerado!",
        icon: "info",
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      api
        .post(`/rh/gerarpdfrh?MesAno=${mes}`)
        .then(() => {
          Swal.close();
          if (mes > "2025-01") {
            navigate("/fechamentopdfRH2");
          } else {
            navigate("/fechamentopdfRH");
          }
        })
        .catch((err) => {
          console.error(err);
          Swal.close();
          Swal.fire({
            title: "Atenção!",
            text: "Não foi possível gerar!",
            icon: "error",
          });
        });
    } else {
      Swal.fire({
        title: "Atenção!",
        text: "Escolha o mês desejado!",
        icon: "warning",
        confirmButtonColor: "var(--blue-color)",
      });
    }
  }
  async function handleExcel() {
    if (!mes || !dataInicio || !dataFim) {
      return Swal.fire({
        title: "Atenção!",
        text: "Selecione um mês e uma data de Inicio e fim",
        icon: "warning",
        confirmButtonColor: "var(--blue-color)",
      });
    }
     if (mes < "2025-02" && dataInicio < "2025-02-01" && dataFim < "2025-02-01") {
      return Swal.fire({
        title: "Atenção!",
        text: "Selecione uma data a partir de fevereiro de 2025",
        icon: "warning",
        confirmButtonColor: "var(--blue-color)",
      });
    }
    Swal.fire({
      title: "Aguarde!",
      text: "O relatório está sendo gerado!",
      icon: "info",
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    api
      .post(`/rh/gerarexcelrh?MesAno=${mes}&data_inicio=${dataInicio}&data_fim=${dataFim}`)
      .then((response) => {
        Swal.close();
        Toast.fire({
          icon: "success",
          title: response.data.success || "Gerado com sucesso!",
        });
        return window.open(
          window.location.href.includes("localhost")
            ? `${STATIC_URL_DEV}/excelrh?auth=${auth}`
            : `${STATIC_URL_PROD}/excelrh?auth=${auth}`
        );
      })
      .catch((err) => {
        console.error(err);
        Swal.close();
        Swal.fire({
          title: "Atenção!",
          text: err.response.data.error || "Não foi possível gerar!",
          icon: "error",
        });
      });
  }

  async function HandleClearTemp() {
    let { value: coord } = await Swal.fire({
      title: "Selecione um coodenador",
      text: "Os dados temporários do coordenador serão apagados!",
      input: "select",
      inputOptions: Coordenadores.map((c) => c.id).reduce(
        (accumulator, element, index) => {
          return {
            ...accumulator,
            [element]: Coordenadores.map((c) => c.nome)[index],
          };
        },
        {}
      ),
      inputPlaceholder: "Coordenador",
      confirmButtonColor: "var(--blue-color)",
      confirmButtonText: "Limpar",
      showCancelButton: false,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            resolve();
          } else {
            resolve("Selecione um coordenador!");
          }
        });
      },
    });

    if (coord) {
      api
        .delete(`/rh/limparfechamentotemp?IdCoord=${coord}`)
        .then((response) => {
          Swal.fire({
            title: "Sucesso!",
            text: response.data.success,
            icon: "success",
            confirmButtonColor: "var(--blue-color)",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Atenção!",
            text: "Não foi possível consultar os dados!",
            icon: "error",
          });
        });
    }
  }

  async function HandlePlanilha() {
    if (!dataInicio || !dataFim) {
      return Swal.fire({
        title: "Atenção!",
        text: "Selecione a Data de inicio e fim!",
        icon: "warning",
      });
    }
    Swal.fire({
      title: "Aguarde!",
      text: "O relatório está sendo gerado!",
      icon: "info",
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    api
      .post(
        `/rh/gerarexcelrateio?data_inicio=${dataInicio}&data_fim=${dataFim}`
      )
      .then((response) => {
        Swal.close();
        Toast.fire({
          icon: "success",
          title: "Gerado com sucesso!",
        });
        window.open(
          window.location.href.includes("localhost")
            ? `${STATIC_URL_DEV}/excelrateio?auth=${auth}`
            : `${STATIC_URL_PROD}/excelrateio?auth=${auth}`
        );
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }

  return (
    <Background>
      <Container>
        <Titulo>
          <h1>
            Fechamento
            <BsDownload
              size={25}
              title="EXPORTAR"
              color={"var(--blue-color)"}
              style={{ marginLeft: "15px", cursor: "pointer" }}
              onClick={() => {
                handleExport();
              }}
            />
          </h1>
          <div>
            <BigButton onClick={() => HandlePlanilha()}>Rateio</BigButton>
            <BigButton onClick={() => HandleClearTemp()}>Limpar</BigButton>
          </div>
        </Titulo>
        <Hr />
        <Form>
          <form>
            <InputLabel style={{ gridArea: "p" }}>
              <label>Mês</label>
              <input
                type="month"
                name="date"
                id=""
                value={mes}
                onChange={(e) => {
                  setMes(e.target.value);
                  setDataInicio(`${e.target.value}-01`);
                  setDataFim(
                    `${e.target.value}-${new Date(
                      e.target.value.split("-")[0],
                      e.target.value.split("-")[1],
                      0
                    ).getDate()}`
                  );
                }}
              />
            </InputLabel>
            <InputLabel style={{ gridArea: "s" }}>
              <label>Início</label>
              <input
                type="date"
                value={dataInicio}
                onChange={(e) => {
                  setDataInicio(e.target.value);
                  setDataFim(e.target.value);
                }}
                required
              />
            </InputLabel>
            <InputLabel style={{ gridArea: "t" }}>
              <label>Fim</label>
              <input
                type="date"
                value={dataFim}
                onChange={(e) => {
                  setDataFim(e.target.value);
                }}
                required
              />
            </InputLabel>
            <Button
              style={{ gridArea: "q" }}
              type="button"
              onClick={() => {
                if (dataInicio || dataFim) {
                  setSearchData(!searchData);
                } else {
                  Swal.fire({
                    title: "Atenção!",
                    text: "Preencha pelo menos uma data!",
                    icon: "warning",
                    confirmButtonColor: "var(--blue-color)",
                  });
                }
              }}
            >
              Filtrar
            </Button>
            <div className="buttons">
              <Button
                style={{ gridArea: "r" }}
                type="button"
                onClick={() => {
                  handlePDF();
                }}
              >
                Gerar Relatorio
              </Button>
              <Button
                style={{ gridArea: "b" }}
                type="button"
                onClick={() => {
                  handleExcel();
                }}
              >
                Gerar Excel Relatorio
              </Button>
            </div>
          </form>
          <Input
            value={search}
            onChange={(e) => {
              setSearch(e.target.value.toUpperCase());
              setPage(1);
            }}
            type="search"
            placeholder="Pesquisar coordenador..."
          />
          {fechamentosTemp
            ?.slice((titlePage - 1) * 1, titlePage * 1)
            ?.map((f, i) => (
              <React.Fragment key={i}>
                <TitlePagination
                  page={titlePage}
                  totalPages={totalTitlePages}
                  setPage={setTitlePage}
                  title={f.colaborador}
                />
                <h4
                  style={{
                    textAlign: "center",
                    marginBottom: "5px",
                    marginTop: "20px",
                  }}
                >
                  Carga Horária no Período
                </h4>
                <table>
                  <thead>
                    <tr>
                      <th>Unidade</th>
                      <th>Curso</th>
                      <th>Evento</th>
                      <th>Carga Horária</th>
                    </tr>
                  </thead>
                  <tbody>
                    {f.dados ? (
                      f.dados.map((f2, i) => (
                        <tr key={i}>
                          <td title={f2.unidade} data-title="Unidade">
                            {f2?.unidade.slice(0, 10)}
                            {f2?.unidade?.length > 20 && "..."}
                          </td>
                          <td data-title="Curso">{f2.curso}</td>
                          <td data-title="Evento">{f2.evento.toUpperCase()}</td>
                          <td data-title="Carga Horária">{f2.carga_horaria}</td>
                        </tr>
                      ))
                    ) : (
                      <tr key={i}>
                        <td
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "100px",
                            paddingLeft: "5px",
                          }}
                          title={f.unidade}
                          data-title="Unidade"
                        >
                          {f.unidade}
                        </td>
                        <td data-title="Curso">{f.curso}</td>
                        <td data-title="Evento">{f.evento.toUpperCase()}</td>
                        <td data-title="Carga Horária">{f.carga_horaria}</td>
                      </tr>
                    )}
                    {f.dados && page === Math.ceil(f.dados.length / 10) ? (
                      <tr>
                        <th
                          colSpan="3"
                          style={{
                            color:
                              carga(f.dados) < regras.valor &&
                              f.vinculo === "ESTATUTARIO"
                                ? "var(--red-color)"
                                : "",
                          }}
                        >
                          Total:
                        </th>
                        {/* <td data-title="Total">{cargaTotal[i]}</td> */}
                        <td
                          data-title="Total"
                          style={{
                            color:
                              carga(f.dados) < regras.valor &&
                              f.vinculo === "ESTATUTARIO"
                                ? "var(--red-color)"
                                : "",
                          }}
                        >
                          {carga(f.dados)}
                        </td>
                      </tr>
                    ) : !f.dados ? (
                      <tr>
                        <th
                          colSpan="3"
                          style={{
                            color:
                              f.carga_horaria < regras.valor &&
                              f.vinculo === "ESTATUTARIO"
                                ? "var(--red-color)"
                                : "",
                          }}
                        >
                          Total:
                        </th>
                        <td
                          data-title="Total"
                          style={{
                            color:
                              f.carga_horaria < regras.valor &&
                              f.vinculo === "ESTATUTARIO"
                                ? "var(--red-color)"
                                : "",
                          }}
                        >
                          {f.carga_horaria}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
                {f.deslocamentos.length ? (
                  <>
                    <h4
                      style={{
                        textAlign: "center",
                        marginBottom: "5px",
                        marginTop: "20px",
                      }}
                    >
                      Deslocamentos
                    </h4>
                    <table>
                      <thead>
                        <tr>
                          <th>Unidade</th>
                          <th>Curso</th>
                          <th>Evento</th>
                          <th>Origem</th>
                          <th>Destino</th>
                          <th>Qtde</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {f.deslocamentos.map((f2, i) => (
                          <tr key={i}>
                            <td
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                maxWidth: "100px",
                                paddingLeft: "5px",
                              }}
                              title={f2.unidade}
                              data-title="Unidade"
                            >
                              {f2.unidade}
                            </td>
                            <td data-title="Curso">{f2.curso}</td>
                            <td data-title="Evento">
                              {f2.evento.toUpperCase()}
                            </td>
                            <td data-title="Origem">{f2.cidade_origem}</td>
                            <td data-title="Destino">{f2.cidade_destino}</td>
                            <td data-title="Qtde">{f2.qtde}</td>
                            <td data-title="Carga Horária">
                              R${" "}
                              {f2.valor_deslocamento.toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ) : null}
              </React.Fragment>
            ))}
        </Form>
      </Container>
    </Background>
  );
}
