import { jwtDecode } from "jwt-decode";

const Auth = {
  auth: function () {
    try {
      const token = localStorage.getItem("token");
      if (!token) return false;

      const decoded = jwtDecode(token);

      return decoded.auth || false;
    } catch (error) {
      return false;
    }
  },
  id: function () {
    try {
      const token = localStorage.getItem("token");
      if (!token) return false;

      const decoded = jwtDecode(token);

      return decoded.id || false;
    } catch (error) {
      return false;
    }
  },
  nome: function () {
    try {
      const token = localStorage.getItem("token");
      if (!token) return false;

      const decoded = jwtDecode(token);

      return decoded.nome || false;
    } catch (error) {
      return false;
    }
  },
  accessUser: function () {
    try {
      const token = localStorage.getItem("token");
      if (!token) return false;

      const decoded = jwtDecode(token);

      return decoded.accessUser || false;
    } catch (error) {
      return false;
    }
  },
  perfil: function () {
    try {
      const token = localStorage.getItem("token");
      if (!token) return false;

      const decoded = jwtDecode(token);

      return decoded.perfil || false;
    } catch (error) {
      return false;
    }
  },
};

export default Auth;
