import { useNavigate } from "react-router-dom";
import { Button } from "./style";
import { IoArrowBackOutline } from "react-icons/io5";

const GoBack = () => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => {
        navigate(-1);
      }}
    >
      <IoArrowBackOutline title="Voltar" style={{ fontSize: "40px" }} />
    </Button>
  );
};

export default GoBack;
