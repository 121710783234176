import React, { useState, useEffect } from "react";
import {
  NavContainer,
  NavLink,
  NavDropDown,
  DropDownButton,
  DropDownOption,
  DropDownList,
  NavButton,
  NavMain,
  Bars,
} from "./styles";
import "./style.css";
import {
  isAuthenticatedAdmin,
  isAuthenticatedCoordenador,
  isAuthenticatedDiretor,
  isAuthenticatedRh,
  isAuthenticatedSecretaria,
  isAuthenticatedDev,
} from "../../auth";
import fiec from "../../assets/Images/fiec.png";
import routesData from "../../assets/json/routes.json";
import { useLocation, useNavigate } from "react-router-dom";
import { IoCaretForwardOutline } from "react-icons/io5";
import Auth from "../../services/auth";

export default function Navbar() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useState(false);
  const location = useLocation();
  const [accessUser, setAccessUser] = useState(Auth.accessUser());

  useEffect(() => {
    setHidden(routesData.routes.includes(location.pathname));
    setAccessUser(Auth.accessUser());
  }, [location.pathname]);

  return (
    <>
      {isAuthenticatedAdmin() ? (
        <NavContainer open={open} hidden={hidden}>
          <img
            src={fiec}
            alt="Logo"
            width="120px"
            height="70px"
            className="logo-out"
          />
          <Bars open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
          </Bars>
          <NavMain open={open}>
            <img
              src={fiec}
              alt="Logo"
              width="120px"
              height="70px"
              className="logo-in"
            />
            <NavLink
              to="/admin"
              onClick={() => {
                setOpen(false);
              }}
            >
              Home
            </NavLink>
            <NavLink
              to="/services"
              onClick={() => {
                setOpen(false);
              }}
            >
              Importar
            </NavLink>
            <NavLink
              to="/contact-us"
              onClick={() => {
                setOpen(false);
              }}
            >
              Exportar
            </NavLink>
            <NavButton
              onClick={() => {
                localStorage.removeItem("token");
                navigate("/");
              }}
            >
              Sair
            </NavButton>
          </NavMain>
        </NavContainer>
      ) : isAuthenticatedCoordenador() ||
        (isAuthenticatedDev() && accessUser) ? (
        <NavContainer open={open} hidden={hidden}>
          <img
            src={fiec}
            alt="Logo"
            width="120px"
            height="70px"
            className="logo-out"
          />
          <Bars open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
          </Bars>
          <NavMain open={open}>
            <img
              src={fiec}
              alt="Logo"
              width="120px"
              height="70px"
              className="logo-in"
            />
            <NavLink
              to="/coord"
              onClick={() => {
                setOpen(false);
              }}
            >
              Home
            </NavLink>
            <NavDropDown>
              <DropDownButton>
                Cadastros
                <IoCaretForwardOutline className="drop-icon" />
              </DropDownButton>
              <DropDownList>
                <DropDownOption
                  onClick={() => {
                    navigate("/disciplinas");
                    setOpen(false);
                  }}
                >
                  Disciplinas
                </DropDownOption>
                <DropDownOption
                  onClick={() => {
                    navigate("/gradescurriculares");
                    setOpen(false);
                  }}
                >
                  Matrizes
                </DropDownOption>
                <DropDownOption
                  onClick={() => {
                    navigate("/turmas");
                    setOpen(false);
                  }}
                >
                  Turmas
                </DropDownOption>
              </DropDownList>
            </NavDropDown>

            <NavDropDown>
              <DropDownButton>
                Consultas
                <IoCaretForwardOutline className="drop-icon" />
              </DropDownButton>
              <DropDownList>
                <DropDownOption
                  onClick={() => {
                    navigate("/consultacoord");
                    setOpen(false);
                  }}
                >
                  Consultas de horários
                </DropDownOption>
              </DropDownList>
            </NavDropDown>

            <NavDropDown>
              <DropDownButton>
                Lançamentos
                <IoCaretForwardOutline className="drop-icon" />
              </DropDownButton>
              <DropDownList>
                <DropDownOption
                  onClick={() => {
                    navigate("/eventuais");
                    setOpen(false);
                  }}
                >
                  Eventuais
                </DropDownOption>
                <DropDownOption
                  onClick={() => {
                    navigate("/lancamentoturmas");
                    setOpen(false);
                  }}
                >
                  Horários
                </DropDownOption>
              </DropDownList>
            </NavDropDown>
            <NavLink
              to="/fechamentocoord"
              onClick={() => {
                setOpen(false);
              }}
            >
              Fechamento
            </NavLink>
            <NavButton
              onClick={() => {
                localStorage.removeItem("token");
                navigate("/");
              }}
            >
              Sair
            </NavButton>
          </NavMain>
        </NavContainer>
      ) : isAuthenticatedRh() ? (
        <NavContainer open={open} hidden={hidden}>
          <img
            src={fiec}
            alt="Logo"
            width="120px"
            height="70px"
            className="logo-out"
          />
          <Bars open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
          </Bars>
          <NavMain open={open}>
            <img
              src={fiec}
              alt="Logo"
              width="120px"
              height="70px"
              className="logo-in"
            />
            <NavLink
              to="/rh"
              onClick={() => {
                setOpen(false);
              }}
            >
              Home
            </NavLink>
            <NavDropDown>
              <DropDownButton>
                Cadastros
                <IoCaretForwardOutline className="drop-icon" />
              </DropDownButton>
              <DropDownList>
                <DropDownOption
                  onClick={() => {
                    navigate("/registercusto");
                    setOpen(false);
                  }}
                >
                  Centros de Custo
                </DropDownOption>
                <DropDownOption
                  onClick={() => {
                    navigate("/colaboradores");
                    setOpen(false);
                  }}
                >
                  Colaboradores
                </DropDownOption>
                <DropDownOption
                  onClick={() => {
                    navigate("/coordenadores");
                    setOpen(false);
                  }}
                >
                  Coordenações
                </DropDownOption>
                <DropDownOption
                  onClick={() => {
                    navigate("/definicaocoord");
                    setOpen(false);
                  }}
                >
                  Coord. p/ curso
                </DropDownOption>
                <DropDownOption
                  onClick={() => {
                    navigate("/deslocamentos");
                    setOpen(false);
                  }}
                >
                  Deslocamentos
                </DropDownOption>
                <DropDownOption
                  onClick={() => {
                    navigate("/eventos");
                    setOpen(false);
                  }}
                >
                  Eventos
                </DropDownOption>
                <DropDownOption
                  onClick={() => {
                    navigate("/lancamentos");
                    setOpen(false);
                  }}
                >
                  Tipos de Lançamento
                </DropDownOption>
                <DropDownOption
                  onClick={() => {
                    navigate("/rhturmas");
                    setOpen(false);
                  }}
                >
                  Turmas
                </DropDownOption>
                <DropDownOption
                  onClick={() => {
                    navigate("/usuarios");
                    setOpen(false);
                  }}
                >
                  Usuários
                </DropDownOption>
              </DropDownList>
            </NavDropDown>
            {/* <NavLink
                            to="/consulta"
                            onClick={() => {
                                setOpen(false);
                            }}
                            style={{marginRight: 15}}
                        >
                            Consulta
                        </NavLink> */}

            <NavDropDown>
              <DropDownButton>
                Consultas
                <IoCaretForwardOutline className="drop-icon" />
              </DropDownButton>
              <DropDownList>
                <DropDownOption
                  onClick={() => {
                    navigate("/consulta");
                    setOpen(false);
                  }}
                >
                  Consultas de horários
                </DropDownOption>
                <DropDownOption
                  onClick={() => {
                    navigate("/aulaspordia");
                    setOpen(false);
                  }}
                >
                  Aulas por dia
                </DropDownOption>
              </DropDownList>
            </NavDropDown>

            <NavDropDown>
              <DropDownButton>
                Fechamento
                <IoCaretForwardOutline className="drop-icon" />
              </DropDownButton>
              <DropDownList>
                <DropDownOption
                  onClick={() => {
                    navigate("/fechamentorhprof");
                    setOpen(false);
                  }}
                >
                  Professores
                </DropDownOption>
              </DropDownList>
            </NavDropDown>
            <NavLink
              to="/regrasrh"
              onClick={() => {
                setOpen(false);
              }}
            >
              Regras
            </NavLink>
            <NavButton
              onClick={() => {
                localStorage.removeItem("token");
                navigate("/");
              }}
            >
              Sair
            </NavButton>
          </NavMain>
        </NavContainer>
      ) : isAuthenticatedSecretaria() ? (
        <NavContainer open={open} hidden={hidden}>
          <img
            src={fiec}
            alt="Logo"
            width="120px"
            height="70px"
            className="logo-out"
          />
          <Bars open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
          </Bars>
          <NavMain open={open}>
            <img
              src={fiec}
              alt="Logo"
              width="120px"
              height="70px"
              className="logo-in"
            />
            <NavLink
              to="/sec"
              onClick={() => {
                setOpen(false);
              }}
            >
              Home
            </NavLink>
            <NavDropDown>
              <DropDownButton>
                Cadastros
                <IoCaretForwardOutline className="drop-icon" />
              </DropDownButton>
              <DropDownList>
                <DropDownOption
                  onClick={() => {
                    navigate("/cursos");
                    setOpen(false);
                  }}
                >
                  Cursos
                </DropDownOption>
                <DropDownOption
                  onClick={() => {
                    navigate("/disciplinas");
                    setOpen(false);
                  }}
                >
                  Disciplinas
                </DropDownOption>
                <DropDownOption
                  onClick={() => {
                    navigate("/turnos");
                    setOpen(false);
                  }}
                >
                  Turnos
                </DropDownOption>
                <DropDownOption
                  onClick={() => {
                    navigate("/unidades");
                    setOpen(false);
                  }}
                >
                  Unidades
                </DropDownOption>
              </DropDownList>
            </NavDropDown>
            <NavDropDown>
              <DropDownButton>
                Consultas
                <IoCaretForwardOutline className="drop-icon" />
              </DropDownButton>
              <DropDownList>
                <DropDownOption
                  onClick={() => {
                    navigate("/consultasec");
                    setOpen(false);
                  }}
                >
                  Consultas de horários
                </DropDownOption>
              </DropDownList>
            </NavDropDown>
            <NavButton
              onClick={() => {
                localStorage.removeItem("token");
                navigate("/");
              }}
            >
              Sair
            </NavButton>
          </NavMain>
        </NavContainer>
      ) : isAuthenticatedDiretor() ? (
        <NavContainer open={open} hidden={hidden}>
          <img
            src={fiec}
            alt="Logo"
            width="120px"
            height="70px"
            className="logo-out"
          />
          <Bars open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
          </Bars>
          <NavMain open={open}>
            <img
              src={fiec}
              alt="Logo"
              width="120px"
              height="70px"
              className="logo-in"
            />
            <NavLink
              to="/dir"
              onClick={() => {
                setOpen(false);
              }}
            >
              Home
            </NavLink>
            <NavButton
              onClick={() => {
                localStorage.removeItem("token");
                navigate("/");
              }}
            >
              Sair
            </NavButton>
          </NavMain>
        </NavContainer>
      ) : isAuthenticatedDev() ? (
        <NavContainer open={open} hidden={hidden}>
          <img
            src={fiec}
            alt="Logo"
            width="120px"
            height="70px"
            className="logo-out"
          />
          <Bars open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
          </Bars>
          <NavMain open={open}>
            <img
              src={fiec}
              alt="Logo"
              width="120px"
              height="70px"
              className="logo-in"
            />
            <NavLink
              to="/homedev"
              onClick={() => {
                setOpen(false);
              }}
            >
              Home
            </NavLink>
            <NavLink
              to="/logs"
              onClick={() => {
                setOpen(false);
              }}
            >
              Logs
            </NavLink>
            <NavLink
              to="/usuarios"
              onClick={() => {
                setOpen(false);
              }}
            >
              Usuários
            </NavLink>
            <NavLink
              to="/regrasdev"
              onClick={() => {
                setOpen(false);
              }}
            >
              Regras
            </NavLink>
            <NavButton
              onClick={() => {
                localStorage.removeItem("token");
                navigate("/");
              }}
            >
              Sair
            </NavButton>
          </NavMain>
        </NavContainer>
      ) : (
        <></>
      )}
    </>
  );
}
