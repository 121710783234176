import React from "react";
import Routes from "../src/routes";
import GlobalStyle from "./styles/global";

export default function App() {
  return (
    <>
      <GlobalStyle />
      <Routes />
    </>
  );
}
