import React from "react";
import { Container, Button } from "./style";
import Logo from "../../assets/Images/fiec.png";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();

  return (
    <Container>
      <img src={Logo} alt="Erro" />
      <h1>Página não encontrada :/</h1>
      <Button
        onClick={() => {
          localStorage.removeItem("token");
          navigate("/");
        }}
      >
        Voltar
      </Button>
    </Container>
  );
}
